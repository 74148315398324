
import StudentDetailsTable from '@/components/Report/StudentDetailsTable.vue';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import NoDataView from '@/components/Report/NoDataView.vue';
import FeedbackSideSheet from '@/components/Report/FeedbackSideSheet.vue';
import ProblemSideSheetForReport from '@/components/Report/ProblemSideSheetForReport.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { User } from '@/domain/User';
import {
  getAssignmentAssignees,
  updateStudentProblemLog,
} from '@/api/core/assignment.api';
import { AssignmentDefinition } from '@/domain/Assignment';
import { ProblemDefinition, ProblemTypeSDK3 } from '@/domain/Problem';
import sortBySortableName from '@/utils/sortBySortableName.util';
import {
  AssignmentReportType,
  EventType,
  trackMixpanel,
} from '@/plugins/mixpanel';
import {
  StudentData,
  ProblemLog,
  StudentLog,
  StudentStats,
  PartLogData,
} from '@/domain/ReportData/AssignmentData';
import {
  getAssignmentDefinition,
  getAssignmentReportData,
} from '@/api/core/assignment.api';
import {
  getQuickComments,
  QuickCommentsRequestParams,
} from '@/api/quickcomments.api';
import { QuickComment, QuickCommentFeedback } from '@/domain/QuickComment';
import { isEmpty, meanBy } from 'lodash';
import { QCStatus } from './EssayScoringPage.vue';
import { isSkillBuilder } from '@/utils/problemSet.util';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import {
  ProblemForReport,
  ProblemStatsForReport,
  formatPercentage,
  getProblemStatsForReport,
  getProblemsForReport,
} from '@/utils/report.util';

dayjs.extend(duration);

enum Direction {
  PREVIOUS = -1,
  NEXT = 1,
}

@Component({
  components: {
    StudentDetailsTable,
    NoDataView,
    FeedbackSideSheet,
    ProblemSideSheetForReport,
  },
})
export default class StudentDetailsPage extends Vue {
  reportReady = false;
  updating = false;

  Direction = Direction;

  selectedLog: ProblemLog | null = null;
  selectedCeri: string | null = null;

  assignment: AssignmentDefinition | null = null;
  reportData: StudentData | null = null;
  assignees: User[] = [];

  quickCommentsDownloaded: QuickComment[] = [];
  quickCommentsStatus: QCStatus = QCStatus.NONE;
  numQuickComments = 3;

  feedbackSideSheet = false;
  problemSideSheet = false;

  controller: AbortController | undefined = undefined;

  get problemSetAssigned(): ProblemSetDefinition | undefined {
    return this.assignment
      ? this.problemSetMap[this.assignment.problemSetCeri]
      : undefined;
  }

  get isSkillBuilder(): boolean {
    return this.problemSetAssigned
      ? isSkillBuilder(this.problemSetAssigned)
      : false;
  }

  get assignee(): User | undefined {
    const xref = this.$route.params.studentXref;
    return this.assignees.find((student) => student.xref === xref);
  }

  set assignee(student: User) {
    this.$router.replace({
      name: 'studentDetailsPage',
      params: {
        ...this.$route.params,
        studentXref: student.xref,
      },
      query: this.$route.query,
    });
  }

  get index(): number {
    return this.assignees.findIndex(
      (student) => student.xref === this.assignee?.xref
    );
  }

  set index(index: number) {
    const student = this.assignees[index];
    this.assignee = student;
  }

  get studentLog(): StudentLog | undefined {
    return this.reportData?.studentLogs.find(
      (log) => log.studentXref === this.assignee?.xref
    );
  }

  get studentStats(): StudentStats | undefined {
    return this.reportData?.summaryStatsAll?.studentStats.find(
      (log) => log.studentXref === this.assignee?.xref
    );
  }

  // Default to N/A
  get studentScore(): string {
    const score = this.studentStats?.score;
    if (typeof score === 'number') {
      return formatPercentage(score);
    }
    return 'N/A';
  }

  // Default 00:00:00
  get timeSpent(): string | undefined {
    const timeSpent = this.studentStats?.timeSpent ?? 0;
    // Format 00:00:00
    return dayjs.duration(timeSpent).format('HH:mm:ss');
  }

  get hasRedo(): boolean {
    return this.assignment?.settings?.useRedo === true;
  }

  get problemSetMap(): Record<string, ProblemSetDefinition> {
    return this.$store.state.content.problemSetMap;
  }

  get redoMap(): Record<string, string[]> {
    return this.$store.state.content.redoMap;
  }

  // FIXME: Based on the fact that a single Problem will ever appear ONCE in the Problem Set assigned,
  // including redo?
  get problemStatsMap(): Record<string, ProblemStatsForReport> {
    return this.reportData ? getProblemStatsForReport(this.reportData) : {};
  }

  get problems(): ProblemForReport[] {
    let prs: ProblemForReport[];
    if (this.problemSetAssigned) {
      prs = getProblemsForReport(
        this.problemSetAssigned.xref,
        this.problemSetMap,
        this.$store.state.content.problemMap,
        this.problemStatsMap,
        this.redoMap
      );
    } else {
      prs = [];
    }
    return prs;
  }

  get problemMap(): Record<string, ProblemForReport> {
    const xrefToProblemMap: Record<string, ProblemForReport> = {};
    for (const pr of this.problems) {
      xrefToProblemMap[pr.xref] = pr;
    }
    return xrefToProblemMap;
  }

  get problem(): ProblemDefinition | undefined {
    if (this.selectedCeri) {
      return this.problemMap[this.selectedCeri];
    }
    return undefined;
  }

  get isQCTeacher(): boolean {
    return this.getCurrentUser.settings?.quickCommentsTeacher ?? false;
  }

  get quickCommentParams(): QuickCommentsRequestParams[] {
    const params: QuickCommentsRequestParams[] = [];
    if (this.assignment && this.assignee) {
      const logActions = this.studentLog?.problemLogAndActions ?? [];
      for (const logAction of logActions) {
        const prLog = logAction.prLog;
        const pr = this.problemMap[prLog.prCeri];
        if (pr?.problemTypeSDK3 == ProblemTypeSDK3.OPEN_RESPONSE) {
          // FIXME: Figure out if/when we want to support multiple Open Response Parts?
          // Will the response list always be of size 1?
          const answerText = prLog.partLogData?.[1].response[0];
          params.push({
            problemId: pr.xref,
            teacherId: this.getCurrentUser.xref,
            assignmentId: this.assignment.xref,
            answers: [
              {
                userId: this.assignee.xref,
                problemLogId: prLog.id,
                answerText: answerText,
                numOfComments: this.numQuickComments,
              },
            ],
          });
        }
      }
    }

    return params;
  }

  get quickComments(): QuickComment | undefined {
    return this.quickCommentsDownloaded.find(
      (qc) => qc.problemId === this.selectedCeri
    );
  }

  updateIndex(direction: Direction): void {
    this.index = this.index + direction;
  }

  updateProblemPartLog(
    modifiedFields: Record<
      number,
      Partial<Pick<PartLogData, 'continuousScore' | 'teacherComment'>>
    >
  ): void {
    const logActions = this.studentLog?.problemLogAndActions ?? [];
    const found = logActions.findIndex(
      (logData) => logData.prLog.id == this.selectedLog?.id
    );
    if (this.assignment && this.assignee && found != -1) {
      this.updating = true;
      const modifiedLog = { ...logActions[found].prLog };

      for (const marker in modifiedFields) {
        modifiedLog.partLogData[marker] = {
          ...modifiedLog.partLogData[marker],
          ...modifiedFields[marker],
        };
      }
      // Update overall Problem Score.
      const partScores = [];
      for (const marker in modifiedLog.partLogData) {
        const score = modifiedLog.partLogData[marker].continuousScore;
        if (typeof score === 'number') {
          partScores.push(score);
        }
      }
      if (partScores.length) {
        modifiedLog.continuousScore = meanBy(partScores);
      }
      updateStudentProblemLog(
        this.assignment.xref,
        this.assignee.xref,
        modifiedLog.id,
        {
          continuousScore: modifiedLog.continuousScore,
          partLogData: modifiedFields as Record<number, PartLogData>,
        }
      )
        .then(() => {
          logActions[found].prLog = modifiedLog;
          this.$notify(`Score feedback updated!`);
        })
        .catch(() => {
          this.$notify('Something went wrong. Please try again.');
        })
        .finally(() => {
          this.updating = false;
        });
      this.trackStudentDetailsQuickCommentUpdate(
        modifiedFields,
        logActions[found].prLog
      );
    }
  }

  openFeedbackSideSheet(prLog: ProblemLog): void {
    this.selectedLog = prLog;
    this.selectedCeri = prLog.prCeri;
    this.feedbackSideSheet = true;
    this.trackSDOpenFeedbackSideSheet(prLog.id, prLog.prCeri);
  }

  openProblemSideSheet(prCeri: string): void {
    this.selectedCeri = prCeri;
    this.problemSideSheet = true;
    this.trackSDOpenProblemSideSheet(prCeri);
  }

  async created(): Promise<void> {
    const assignmentXref = this.$route.params.xref;
    this.reportReady = false;

    // Download the Assignment.
    const assignmentPromise = getAssignmentDefinition(assignmentXref, {
      details: true,
    }).then((assignment) => {
      this.assignment = assignment;
      // Download the Problem Set.
      const problemSetPromise = this.$store
        .dispatch('content/getProblemSetTree', {
          xref: assignment.problemSetCeri,
        })
        .then((ps) => {
          if (this.hasRedo) {
            return this.$store.dispatch('content/getProblemSetRedos', {
              xref: ps.xref,
            });
          }
        });
      const assigneePromise = getAssignmentAssignees(assignment.xref)
        .then((assignees: User[]) => {
          // Sort alphabetically.
          assignees.sort((a, b) => {
            return sortBySortableName(a, b);
          });
          this.assignees = assignees;
        })
        .catch(() => {
          // No assignees found.
          this.assignees = [];
        });
      return Promise.all([problemSetPromise, assigneePromise]);
    });

    // Download assignment report data.
    const dataPromise = getAssignmentReportData(assignmentXref)
      .then((reportData) => {
        if (reportData && !isEmpty(reportData)) {
          this.reportData = reportData;
        } else {
          this.reportData = null;
        }
      })
      .catch(() => {
        this.reportData = null;
      });

    try {
      await Promise.all([assignmentPromise, dataPromise]);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      error.handleGlobally && error.handleGlobally();
    }

    // Donwload Quick Comments for Assignee if any.
    this.downloadQuickComments();
    this.reportReady = true;
    this.trackStudentDetailsReportLoaded();
  }

  // The new Quick Comments API supports a single Problem.
  @Watch('quickCommentParams')
  async downloadQuickComments(): Promise<void> {
    // Cancel previous request.
    if (this.controller) {
      this.controller.abort();
    }
    this.controller = new AbortController();
    // Reset previously downloaded QCs.
    this.quickCommentsDownloaded = [];
    if (this.isQCTeacher && this.quickCommentParams.length) {
      this.quickCommentsStatus = QCStatus.LOADING;
      const promises = this.quickCommentParams.map((param) => {
        return getQuickComments(param, this.controller);
      });
      try {
        const qcs = await Promise.all(promises);
        this.quickCommentsDownloaded = qcs.flat();
        this.quickCommentsStatus = QCStatus.SUCCESS;
      } catch (e) {
        this.quickCommentsStatus = QCStatus.FAILURE;
      }
    } else {
      // Reset state.
      this.quickCommentsStatus = QCStatus.NONE;
    }
  }

  @Watch('feedbackSideSheet')
  trackSDCloseFeedbackSideSheet(): void {
    if (!this.feedbackSideSheet) {
      trackMixpanel(EventType.studentDetailsCloseFeedbackSideSheet, {
        assignmentXref: this.assignment?.xref,
        studentXref: this.assignee?.xref,
      });
    }
  }

  //////////////
  // Mixpanel //
  //////////////

  trackBackToAssignmentReport(): void {
    trackMixpanel(EventType.assignmentReportBackToAssignmentReport, {
      assignmentXref: this.assignment?.xref,
    });
  }

  @Watch('$route.params.studentXref')
  trackStudentDetailsReportLoaded(): void {
    trackMixpanel(EventType.studentDetailsViewed, {
      assignmentXref: this.assignment?.xref,
      studentXref: this.assignee?.xref,
    });
  }

  @Watch('problemSideSheet')
  trackSDCloseProblemSideSheet(): void {
    if (!this.problemSideSheet) {
      let reportType = '';
      if (this.isSkillBuilder) {
        reportType = AssignmentReportType.masteryReport;
      } else {
        reportType = AssignmentReportType.assignmentReport;
      }
      trackMixpanel(EventType.studentDetailsCloseProblemSideSheet, {
        assignmentXref: this.assignment?.xref,
        studentXref: this.assignee?.xref,
        reportType: reportType,
      });
    }
  }

  trackSDOpenProblemSideSheet(problemXref: string): void {
    let reportType = '';
    if (this.isSkillBuilder) {
      reportType = AssignmentReportType.masteryReport;
    } else {
      reportType = AssignmentReportType.assignmentReport;
    }
    trackMixpanel(EventType.studentDetailsOpenProblemSideSheet, {
      assignmentXref: this.assignment?.xref,
      studentXref: this.assignee?.xref,
      problemCode: problemXref,
      reportType: reportType,
    });
  }

  trackStudentDetailsQuickCommentUpdate(
    modifiedFields: Partial<ProblemLog>,
    pLog: ProblemLog
  ) {
    let teacherComment = pLog.partLogData[1]?.teacherComment;
    // Find the relevant quick comments for the selected problem
    let quickCommentEntry = this.quickCommentsDownloaded.find(
      (qc) => qc.problemId === this.selectedCeri
    );

    // if (modifiedFields.partLogData) {
    // FIXME: Figure out if/when we want to support multiple Open Response Parts?
    // Will the response list always be of size 1?
    //   teacherComment = modifiedFields[1]?.teacherComment;
    // }

    // Calculate commentIndex based on the continuousScore
    let commentIndex = quickCommentEntry
      ? Math.round(pLog.continuousScore * 4)
      : null;
    let teacherScore = null;
    if (typeof modifiedFields[1].continuousScore === 'number') {
      teacherScore = modifiedFields[1].continuousScore * 4;
    } else {
      teacherScore =
        modifiedFields[1]?.continuousScore ?? pLog.continuousScore * 4;
    }
    let selectedComments = quickCommentEntry?.comments || [];
    let responseHasComment = quickCommentEntry?.commentIsAccurate || false;
    let responseHasGrade = quickCommentEntry?.scoreIsAccurate || false;
    let teacherCommentIndex = selectedComments?.findIndex(
      (comment: QuickCommentFeedback) => comment.text === teacherComment
    );
    const trackingData = {
      assignmentXref: this.assignment?.xref,
      studentXref: this.assignee?.xref,
      problemCode: this.selectedCeri,
      problemLogId: pLog.id,
      continuousScore:
        modifiedFields[1]?.continuousScore ?? pLog.continuousScore,
      selectedCommentIndex:
        teacherCommentIndex === -1 ? null : teacherCommentIndex,
      teacherComment: teacherComment,
      quickComments: selectedComments,
      selectedComment:
        selectedComments && teacherCommentIndex
          ? selectedComments[teacherCommentIndex]
          : null,
      teacherScore: teacherScore,
      qcCommentShown: responseHasComment,
      qcGradeShown: responseHasGrade,
      updateScore: typeof modifiedFields[1]?.continuousScore === 'number',
      updateComment:
        typeof modifiedFields[1]?.teacherComment === 'string' ||
        modifiedFields[1]?.teacherComment === '',
    };
    trackMixpanel(EventType.studentDetailsQuickCommentUpdate, trackingData);
  }

  trackSDOpenFeedbackSideSheet(
    problemLogId: number,
    problemXref: string
  ): void {
    trackMixpanel(EventType.studentDetailsOpenFeedbackSideSheet, {
      assignmentXref: this.assignment?.xref,
      studentXref: this.assignee?.xref,
      problemCode: problemXref,
      problemLogId: problemLogId,
    });
  }
}
