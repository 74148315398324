import { TutorStrategyType } from './Tutoring';

export enum ActionType {
  // Assignment Actions
  ASSIGNMENT_STARTED_ACTION = 'ASSIGNMENT_STARTED_ACTION',
  ASSIGNMENT_RESUMED_ACTION = 'ASSIGNMENT_RESUMED_ACTION',
  ASSIGNMENT_FINISHED_ACTION = 'ASSIGNMENT_FINISHED_ACTION',
  // USER_SELECTED_CONTINUE_ACTION = 'USER_SELECTED_CONTINUE_ACTION',
  // Problem Set Actions
  PROBLEM_SET_STARTED_ACTION = 'PROBLEM_SET_STARTED_ACTION',
  PROBLEM_SET_RESUMED_ACTION = 'PROBLEM_SET_RESUMED_ACTION',
  PROBLEM_SET_FINISHED_ACTION = 'PROBLEM_SET_FINISHED_ACTION',
  PROBLEM_SET_MASTERED_ACTION = 'PROBLEM_SET_MASTERED_ACTION',
  PROBLEM_SET_EXHAUSTED_ACTION = 'PROBLEM_SET_EXHAUSTED_ACTION',
  PROBLEM_SET_LIMIT_EXCEEDED_ACTION = 'PROBLEM_SET_LIMIT_EXCEEDED_ACTION',
  // Problem Actions
  PROBLEM_STARTED_ACTION = 'PROBLEM_STARTED_ACTION',
  PROBLEM_RESUMED_ACTION = 'PROBLEM_RESUMED_ACTION',
  PROBLEM_FINISHED_ACTION = 'PROBLEM_FINISHED_ACTION',
  VIEWED_AVAILABLE_SUPPORTS_ACTION = 'VIEWED_AVAILABLE_SUPPORTS_ACTION',
  CAIT_MESSAGE_RESPONSE_ACTION = 'CAIT_MESSAGE_RESPONSE_ACTION',
  // Tutoring Request Actions
  HINT_REQUESTED_ACTION = 'HINT_REQUESTED_ACTION',
  SCAFFOLDING_REQUESTED_ACTION = 'SCAFFOLDING_REQUESTED_ACTION',
  URL_LINK_REQUESTED_ACTION = 'URL_LINK_REQUESTED_ACTION',
  EXPLANATION_REQUESTED_ACTION = 'EXPLANATION_REQUESTED_ACTION',
  ANSWER_REQUESTED_ACTION = 'ANSWER_REQUESTED_ACTION',
  REPORTED_BROKEN_VIDEO_ACTION = 'REPORTED_BROKEN_VIDEO_ACTION',
  CAIT_MESSAGE_REQUESTED_ACTION = 'CAIT_MESSAGE_REQUESTED_ACTION',
  // Response Actions
  STUDENT_RESPONSE_ACTION = 'STUDENT_RESPONSE_ACTION',
  STUDENT_SUBMISSION_ACTION = 'STUDENT_SUBMISSION_ACTION',
  WORK_SUBMITTED_ACTION = 'WORK_SUBMITTED_ACTION',
  IM_STUCK_REQUEST_ACTION = 'IM_STUCK_REQUEST_ACTION',
  LIVE_TUTORING_REQUESTED_ACTION = 'LIVE_TUTORING_REQUESTED_ACTION',
  // Timer actions
  TIMER_STARTED_ACTION = 'TIMER_STARTED_ACTION',
  TIMER_RESUMED_ACTION = 'TIMER_RESUMED_ACTION',
  TIMER_PAUSED_ACTION = 'TIMER_PAUSED_ACTION',
  TIMER_FINISHED_ACTION = 'TIMER_FINISHED_ACTION',
}

export interface IAction {
  readonly timestamp: number;
  readonly actionType: ActionType;
  readonly targetXref: string;
  readonly path: string;
  readonly userXref: string;
  readonly assignmentXref: string;
  readonly tutorUid: string;
  readonly tutorUidSequence: number;
  readonly redoParentXref?: string;
}

export interface Score {
  continuousScore: number;
  discreteScore: 0 | 1;
}

export interface IProblemAction extends IAction {
  readonly actionType:
    | ActionType.PROBLEM_STARTED_ACTION
    | ActionType.PROBLEM_RESUMED_ACTION
    | ActionType.PROBLEM_FINISHED_ACTION
    | ActionType.VIEWED_AVAILABLE_SUPPORTS_ACTION
    | ActionType.CAIT_MESSAGE_RESPONSE_ACTION;
}

export interface ProblemStartedAction extends IProblemAction {
  //Attempting having no extra data on the start action
  readonly actionType: ActionType.PROBLEM_STARTED_ACTION;
}

export interface ProblemResumedAction extends IProblemAction {
  readonly actionType: ActionType.PROBLEM_RESUMED_ACTION;
}

export interface ProblemFinishedAction extends IProblemAction {
  readonly actionType: ActionType.PROBLEM_FINISHED_ACTION;
  readonly problemScore?: Score;
  readonly partScore?: Record<number, Score>;
}

export interface ViewedAvailableSupportsAction extends IProblemAction {
  readonly actionType: ActionType.VIEWED_AVAILABLE_SUPPORTS_ACTION;
  readonly availableSupports: string[];
}

export interface CaitMessageResponseAction extends IProblemAction {
  readonly actionType: ActionType.CAIT_MESSAGE_RESPONSE_ACTION;
  readonly response: string;
}

export interface IAssignmentAction extends IAction {
  actionType:
    | ActionType.ASSIGNMENT_STARTED_ACTION
    | ActionType.ASSIGNMENT_RESUMED_ACTION
    | ActionType.ASSIGNMENT_FINISHED_ACTION;
}

export type AssignmentAction =
  | AssignmentStartedAction
  | AssignmentResumedAction
  | AssignmentFinishedAction;

export interface AssignmentStartedAction extends IAssignmentAction {
  actionType: ActionType.ASSIGNMENT_STARTED_ACTION;
}

export interface AssignmentResumedAction extends IAssignmentAction {
  actionType: ActionType.ASSIGNMENT_RESUMED_ACTION;
}

export interface AssignmentFinishedAction extends IAssignmentAction {
  actionType: ActionType.ASSIGNMENT_FINISHED_ACTION;
}

export interface IProblemSetAction extends IAction {
  readonly actionType:
    | ActionType.PROBLEM_SET_STARTED_ACTION
    | ActionType.PROBLEM_SET_RESUMED_ACTION
    | ActionType.PROBLEM_SET_FINISHED_ACTION
    | ActionType.PROBLEM_SET_MASTERED_ACTION
    | ActionType.PROBLEM_SET_EXHAUSTED_ACTION
    | ActionType.PROBLEM_SET_LIMIT_EXCEEDED_ACTION;
}

export interface ProblemSetStartedAction extends IProblemSetAction {
  readonly actionType: ActionType.PROBLEM_SET_STARTED_ACTION;
}

export interface ProblemSetResumedAction extends IProblemSetAction {
  readonly actionType: ActionType.PROBLEM_SET_RESUMED_ACTION;
}

export interface ProblemSetFinishedAction extends IProblemSetAction {
  readonly actionType: ActionType.PROBLEM_SET_FINISHED_ACTION;
}

export interface ProblemSetMasteredAction extends IProblemSetAction {
  readonly actionType: ActionType.PROBLEM_SET_MASTERED_ACTION;
}

export interface ProblemSetExhaustedAction extends IProblemSetAction {
  readonly actionType: ActionType.PROBLEM_SET_EXHAUSTED_ACTION;
}

export interface ProblemSetLimitExceededAction extends IProblemSetAction {
  readonly actionType: ActionType.PROBLEM_SET_LIMIT_EXCEEDED_ACTION;
}

export interface ITimerAction extends IAction {
  readonly actionType:
    | ActionType.TIMER_FINISHED_ACTION
    | ActionType.TIMER_PAUSED_ACTION
    | ActionType.TIMER_RESUMED_ACTION
    | ActionType.TIMER_STARTED_ACTION;

  readonly timeLeft: number;
}

export type TimerAction =
  | TimerFinishedAction
  | TimerPausedAction
  | TimerResumedAction
  | TimerStartedAction;

export interface TimerFinishedAction extends ITimerAction {
  readonly actionType: ActionType.TIMER_FINISHED_ACTION;
}

export interface TimerPausedAction extends ITimerAction {
  readonly actionType: ActionType.TIMER_PAUSED_ACTION;
}

export interface TimerResumedAction extends ITimerAction {
  readonly actionType: ActionType.TIMER_RESUMED_ACTION;
}

export interface TimerStartedAction extends ITimerAction {
  readonly actionType: ActionType.TIMER_STARTED_ACTION;
}

export interface ITutoringRequestedAction extends IResponseAction {
  readonly actionType:
    | ActionType.ANSWER_REQUESTED_ACTION
    | ActionType.EXPLANATION_REQUESTED_ACTION
    | ActionType.HINT_REQUESTED_ACTION
    | ActionType.SCAFFOLDING_REQUESTED_ACTION
    | ActionType.URL_LINK_REQUESTED_ACTION
    | ActionType.REPORTED_BROKEN_VIDEO_ACTION
    | ActionType.CAIT_MESSAGE_REQUESTED_ACTION;

  readonly tutoringCeri: string;
  readonly htmlMarker?: number;

  readonly problemScore?: Score;
  readonly partScore?: Record<number, Score>;
}

export type TutoringRequestedAction =
  | AnswerRequestedAction
  | ExplanationRequestedAction
  | HintRequestedAction
  | ScaffoldingRequestedAction
  | URLLinkRequestedAction
  | ReportedBrokenVideoAction
  | CaitMessageRequestedAction;

export interface AnswerRequestedAction
  extends Omit<ITutoringRequestedAction, 'tutoringCeri'> {
  readonly actionType: ActionType.ANSWER_REQUESTED_ACTION;
}

export interface ExplanationRequestedAction extends ITutoringRequestedAction {
  readonly actionType: ActionType.EXPLANATION_REQUESTED_ACTION;
  readonly tutoringCeri: string;
}

export interface HintRequestedAction extends ITutoringRequestedAction {
  readonly actionType: ActionType.HINT_REQUESTED_ACTION;
  readonly tutoringCeri: string;
  readonly index: number;
}

export interface ScaffoldingRequestedAction extends ITutoringRequestedAction {
  readonly actionType: ActionType.SCAFFOLDING_REQUESTED_ACTION;
  readonly tutoringCeri: string;
}

export interface URLLinkRequestedAction extends ITutoringRequestedAction {
  readonly actionType: ActionType.URL_LINK_REQUESTED_ACTION;
  readonly tutoringCeri: string;
}

export interface ReportedBrokenVideoAction extends ITutoringRequestedAction {
  readonly actionType: ActionType.REPORTED_BROKEN_VIDEO_ACTION;
  readonly tutoringCeri: string;
  readonly tsType: TutorStrategyType;
  readonly hintCount: number;
}

export interface CaitMessageRequestedAction extends ITutoringRequestedAction {
  readonly actionType: ActionType.CAIT_MESSAGE_REQUESTED_ACTION;
  readonly message: string;
}

export interface IResponseAction extends IAction {
  readonly isFirstResponse: boolean;
}

export type ResponseAction =
  | HelpImStuckAction
  | LiveTutoringRequestedAction
  | StudentResponseAction
  | StudentSubmissionAction
  | AnswerRequestedAction
  | ExplanationRequestedAction
  | HintRequestedAction
  | ScaffoldingRequestedAction
  | URLLinkRequestedAction;

export interface HelpImStuckAction extends IResponseAction {
  readonly actionType: ActionType.IM_STUCK_REQUEST_ACTION;
}

export interface LiveTutoringRequestedAction extends IResponseAction {
  readonly actionType: ActionType.LIVE_TUTORING_REQUESTED_ACTION;
}

//StudentResponse is for an answerable problem. Any response that a student
// gives for a problem that has correct answers
export interface StudentResponseAction extends IResponseAction {
  readonly actionType: ActionType.STUDENT_RESPONSE_ACTION;

  readonly partData: Record<number, ResponsePartData>;
  readonly problemScore: Score;

  //TODO: Does this need to be an array?
  readonly feedbackCeri?: string;

  readonly studentWork?: StudentWork;
}

export interface ResponsePartData {
  response: string[];
  correct: boolean;
  responseCorrectness: boolean[];
  score: Score;
}

export enum WorkType {
  TEACHLEY = 'TEACHLEY',
}

export interface StudentWork {
  type: WorkType;
  value: string;
}

//StudentSubmission is for open response problems. They do not have correct answers.
export interface StudentSubmissionAction extends IResponseAction {
  readonly actionType: ActionType.STUDENT_SUBMISSION_ACTION;
  readonly htmlMarker: number;
  readonly response: string;
}
