
import { Component, Vue, Watch } from 'vue-property-decorator';
import { updateMyUserAttribute } from '@/api/core/user.api';
import { AttributeNames } from '@/domain/Attributes';
import userflow from 'userflow.js';
import { getGradeLevelList, GradeLevel } from '@/domain/Grade';
import { getCurriculumDefinitions } from '@/api/core/curricula.api';
import { CurriculumDefinition } from '@/domain/Curriculum';
import {
  contactHubspot,
  HubspotProperty,
  SelfIdentifyRole,
} from '@/api/core/hubspot.api';

@Component
export default class SelectGC extends Vue {
  selectedRole: SelfIdentifyRole | null = null;
  selectedGrades: GradeLevel[] = [];
  selectedCurricula: CurriculumDefinition[] = [];
  //TODO: need to change this to something else that's a real curriculum object when the actual object is fixed everywhere else
  curricula: CurriculumDefinition[] = [];
  submitting = false;
  studentDialog = false;

  SelfIdentifyRole = SelfIdentifyRole;

  get roles(): SelfIdentifyRole[] {
    return Object.values(SelfIdentifyRole);
  }

  get grades(): { display: string; value: string }[] {
    return getGradeLevelList();
  }

  // FIXME: Figure out if we need to notify Hubspot of role selection here.
  skip(): void {
    userflow.track(
      'grade_and_curriculum_skipped',
      // No attributes
      {},
      // Options
      {
        userOnly: true,
      }
    );
    if (
      this.selectedRole == SelfIdentifyRole.TEACHER ||
      this.selectedRole == SelfIdentifyRole.ADMIN
    ) {
      this.submit([this.getHubspotPromise()]).then(() => {
        this.goToSelectSchoolPage();
      });
    } else {
      this.studentDialog = true;
    }
  }

  proceed(): void {
    if (
      this.selectedRole == SelfIdentifyRole.TEACHER ||
      this.selectedRole == SelfIdentifyRole.ADMIN
    ) {
      const promises = [
        ...this.getGradeCurriculumPromises(),
        this.getHubspotPromise(),
      ];
      this.submit(promises).then(() => {
        this.goToSelectSchoolPage();
      });
    } else {
      this.studentDialog = true;
    }
  }

  getGradeCurriculumPromises(): Promise<unknown>[] {
    const promises = [];
    if (this.selectedGrades.length) {
      promises.push(
        updateMyUserAttribute(AttributeNames.GRADE_LEVEL, this.selectedGrades)
      );
    }
    if (this.selectedCurricula.length) {
      promises.push(
        updateMyUserAttribute(
          AttributeNames.CURRICULUM,
          this.selectedCurricula.map((c) => c.xref)
        )
      );
    }
    return promises;
  }

  getHubspotPromise(): Promise<unknown> {
    return contactHubspot({
      [HubspotProperty.SELF_IDENTIFY_ROLE]: this.selectedRole ?? undefined,
      [HubspotProperty.GRADES_TAUGHT]: this.selectedGrades,
      [HubspotProperty.CURRICULA_TAUGHT]: this.selectedCurricula,
    });
  }

  submit(promises: Promise<unknown>[]): Promise<unknown> {
    this.submitting = true;
    return Promise.all(promises)
      .catch(() => {
        //Something bad happened.
        this.$notify(
          'An error occurred while saving. Please try again or move on.'
        );
      })
      .finally(() => {
        this.submitting = false;
      });
  }

  goToSelectSchoolPage(): void {
    this.$router.push({
      name: 'select-school',
      query: this.$route.query,
    });
  }

  created(): void {
    getCurriculumDefinitions().then((response) => {
      this.curricula = response;
    });
  }

  @Watch('selectedRole')
  onRoleChange(): void {
    this.selectedGrades = [];
    this.selectedCurricula = [];
  }
}
