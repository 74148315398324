export enum GradeLevel {
  GRADE_1 = 'GRADE_1',
  GRADE_2 = 'GRADE_2',
  GRADE_3 = 'GRADE_3',
  GRADE_4 = 'GRADE_4',
  GRADE_5 = 'GRADE_5',
  GRADE_6 = 'GRADE_6',
  GRADE_6_ACCELERATED = 'GRADE_6_ACCELERATED',
  GRADE_7 = 'GRADE_7',
  GRADE_7_ACCELERATED = 'GRADE_7_ACCELERATED',
  GRADE_8 = 'GRADE_8',
  MATH_1 = 'MATH_1',
  MATH_2 = 'MATH_2',
  MATH_3 = 'MATH_3',
  GEOMETRY = 'GEOMETRY',
  ALGEBRA_1 = 'ALGEBRA_1',
  ALGEBRA_2 = 'ALGEBRA_2',
  PRE_CALCULUS = 'PRE_CALCULUS',
  CALCULUS = 'CALCULUS',
  STATISTICS = 'STATISTICS',
  OTHER = 'OTHER',
}

const displayNames: { [key in GradeLevel]: string } = {
  GRADE_1: 'Grade 1',
  GRADE_2: 'Grade 2',
  GRADE_3: 'Grade 3',
  GRADE_4: 'Grade 4',
  GRADE_5: 'Grade 5',
  GRADE_6: 'Grade 6',
  GRADE_6_ACCELERATED: '6 Accelerated',
  GRADE_7: 'Grade 7',
  GRADE_7_ACCELERATED: '7 Accelerated',
  GRADE_8: 'Grade 8',
  MATH_1: 'Math 1',
  MATH_2: 'Math 2',
  MATH_3: 'Math 3',
  GEOMETRY: 'Geometry',
  ALGEBRA_1: 'Algebra 1',
  ALGEBRA_2: 'Algebra 2',
  PRE_CALCULUS: 'Pre-Calculus',
  CALCULUS: 'Calculus',
  STATISTICS: 'Statistics',
  OTHER: 'Other',
};

export function getGradeLevelDisplayNames(gradeLevels: GradeLevel[]) {
  // Tried a function that just replaced caps and underscores but doesn't work in all cases
  return gradeLevels.map((gradeLevel) => {
    return displayNames[gradeLevel];
  });
}

export function getGradeLevelList() {
  const items = [];
  for (const [value, display] of Object.entries(displayNames)) {
    items.push({ value, display });
  }
  return items;
}
