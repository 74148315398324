import { AttributeNames, AttributesMap, IHasAttributes } from './Attributes';
import { ContentType, IBuilderContent } from './Content';
import { IProblemSetMember } from './ProblemSet';

export interface ProblemDefinition
  extends IProblemSetMember,
    IBuilderContent,
    IHasAttributes {
  question: string;
  contentType: ContentType.PROBLEM;
  problemTypeSDK3: ProblemTypeSDK3;
  properties?: ProblemProperties;
  attributes?: ProblemAttributes;
  answersSDK3?: AnswerSet | null;
  // Internal processing only. Will not included in DTO if not set manually.
  partLetter?: string;
}

export type ProblemAttributes = Pick<
  AttributesMap,
  | AttributeNames.CURRICULUM
  | AttributeNames.ATTRIBUTION
  | AttributeNames.SKILL
  | AttributeNames.IS_TEXTBOOK
  | AttributeNames.IS_RESEARCH
  | AttributeNames.IS_CERTIFIED
>;

// For now, default values will be hard-coded. Default properties will NOT be included in the definitions!
export interface ProblemProperties {
  // Defaults to true. Can be defined in both the PS and PR
  SHOW_CORRECTNESS?: boolean;
  // Defaults to true. Can be defined in both the PS and PR
  TUTORING_AVAILABLE?: boolean;
  // Default is null as not all Problem Types has answers and not all that have answers can be scrambled.
  // The null default in theory forces the builder to know the value must be set when it can be set or will fail in validation.
  SCRAMBLE_ANSWERS?: boolean | null;
}

export enum ProblemTypeSDK3 {
  DRAG_DROP = 'DRAG_DROP',
  FILL_IN = 'FILL_IN',
  CHOOSE_ONE = 'CHOOSE_ONE',
  CHOOSE_N = 'CHOOSE_N',
  OPEN_RESPONSE = 'OPEN_RESPONSE',
  SORT = 'SORT',
  CLOZE = 'CLOZE',
  IFRAME = 'IFRAME',
}

export interface IAnswerSetMember {
  memberType: 'ANSWER_SET' | 'ANSWER_PART';
}

export interface AnswerSet extends IAnswerSetMember {
  memberType: 'ANSWER_SET';
  // Will include DEFAULT properties.
  properties?: AnswerSetProperties;
  members?: (AnswerSet | AnswerPart)[];
  answerValues?: AnswerValue[];
  messageFormat?: string;
}

export enum AnswerSetProperty {
  LINKED_PARTS = 'LINKED_PARTS',
  INTERCHANGEABLE = 'INTERCHANGEABLE',
  SHOW_CORRECTNESS = 'SHOW_CORRECTNESS',
  GRADING = 'GRADING',
  STACKED = 'STACKED',
  ANSWER_POOL = 'ANSWER_POOL',
  ANSWER_POOL_REUSE = 'ANSWER_POOL_REUSE',
}

export enum ShowCorrectnessType {
  PARTIAL = 'PARTIAL',
  FULL = 'FULL',
}

// Frontend do NOT need to know default values. The SDK is the keeper of the defaults.
// The caller just deals with what is sent in the response.
export type AnswerSetProperties = {
  // Defaults to false.
  [AnswerSetProperty.LINKED_PARTS]?: boolean;
  // Defaults to false.
  [AnswerSetProperty.INTERCHANGEABLE]?: boolean;
  // Defaults to PARTIAL.
  [AnswerSetProperty.SHOW_CORRECTNESS]?: ShowCorrectnessType;
  // Properties below MAY be included ONLY IF Drag and Drop or Sort (Rank).
  // Defaults to false for Drag and Drop.
  // Defaults to true for Sort(Rank).
  [AnswerSetProperty.STACKED]?: boolean;
  // Properties below MAY be included ONLY IF Drag and Drop.
  [AnswerSetProperty.ANSWER_POOL]?: string[];
  [AnswerSetProperty.ANSWER_POOL_REUSE]?: boolean;
};

export interface AnswerPart extends IAnswerSetMember {
  memberType: 'ANSWER_PART';
  answerType: AnswerType;
  // Will include DEFAULT properties.
  properties?: AnswerPartProperties;
  htmlMarker: number;
  answerValues?: AnswerValue[];
}

export enum AnswerType {
  CHOOSE_ONE = 'CHOOSE_ONE',
  CHOOSE_N = 'CHOOSE_N',
  DROP_DOWN = 'DROP_DOWN',
  SORT = 'SORT',
  DRAG_DROP = 'DRAG_DROP',
  EXACT_MATCH = 'EXACT_MATCH',
  SYMBOLIC_EQUIVALENCE = 'SYMBOLIC_EQUIVALENCE',
  PURE_EQUIVALENCE = 'PURE_EQUIVALENCE',
  OPEN_RESPONSE = 'OPEN_RESPONSE',
  IFRAME = 'IFRAME',
  // FIXME: Keep and migrate the following old types until we know how we want the new types (above) to behave.
  ALGEBRA = 'ALGEBRA',
  EXACT_FRACTION = 'EXACT_FRACTION',
  NUMERIC = 'NUMERIC',
  NUMERIC_EXPRESSION = 'NUMERIC_EXPRESSION',
}

export enum AnswerPartProperty {
  DROP_COUNT = 'DROP_COUNT',
  DROP_CORRECT = 'DROP_CORRECT',
  SHUFFLE = 'SHUFFLE',
  CASE_SENSITIVE = 'CASE_SENSITIVE',
  LABEL_START = 'LABEL_START',
}

// Frontend do NOT need to know default values. The SDK is the keeper of the defaults.
// The caller just deals with what is sent in the response.
export type AnswerPartProperties = {
  // Properties below MAY be included ONLY IF Drop Down, Choose N, or Choose One.
  // Defaults to true.
  [AnswerPartProperty.SHUFFLE]?: boolean | null;
  // Properties below MAY be included ONLY IF Drag and Drop.
  [AnswerPartProperty.DROP_COUNT]?: number;
  [AnswerPartProperty.DROP_CORRECT]?: Record<string, number>;
  [AnswerPartProperty.CASE_SENSITIVE]?: boolean;
  [AnswerPartProperty.LABEL_START]?: number | string | null;
};

export interface AnswerValue {
  value: string;
  isCorrect: boolean;
}
