import { coreAxios } from '@/plugins/axios';

export interface URLConversionRequestParams {
  problemSetIds?: number[];
  folderIds?: number[];
}

export interface URLConversionResponseObject {
  resourceType: URLConversionResponseObjectType;
  originalId: number;
  newXref: string;
}

export enum URLConversionResponseObjectType {
  FOLDER = 'FOLDER',
  LEGACY_PLAIN_OLD_PROBLEM_SET = 'LEGACY_PLAIN_OLD_PROBLEM_SET',
}

export interface ConversionContainer {
  [URLConversionResponseObjectType.FOLDER]?: Record<number, string>;
  [URLConversionResponseObjectType.LEGACY_PLAIN_OLD_PROBLEM_SET]?: Record<
    number,
    string
  >;
}

const END_POINT = '/compatibility';
const CONVERSION_END_POINT = `${END_POINT}/legacyIdToSDK3`;

const getSDK3CeriForIds = (
  params: URLConversionRequestParams
): Promise<ConversionContainer> => {
  return coreAxios.get(`${CONVERSION_END_POINT}`, { params }).then((res) => {
    const container: ConversionContainer = {};
    const objs: URLConversionResponseObject[] = res.data;
    for (const obj of objs) {
      let map = container[obj.resourceType];
      if (!map) {
        map = {};
        container[obj.resourceType] = map;
      }
      map[obj.originalId] = obj.newXref;
    }
    return container;
  });
};

export { getSDK3CeriForIds };
