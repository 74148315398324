
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ProblemSetDefinition, ProblemSetType } from '@/domain/ProblemSet';
import { ProblemDefinition } from '@/domain/Problem';
import RemoveFromProblemSetDialog, {
  Membership,
} from './RemoveFromProblemSetDialog.vue';
import { ContentType } from '@/domain/Content';
import { getContentType, getTestModeStatus } from '@/utils/builder.util';
import { AclPermissionType } from '@/domain/Acls';

enum RemoveDialogMode {
  PARENT = 'PARENT',
  MEMBER = 'MEMBER',
}

@Component({
  components: { RemoveFromProblemSetDialog },
})
export default class ProblemSetBuilderMenu extends Vue {
  @Prop({ required: true }) contentPath: string;

  ProblemSetType = ProblemSetType;
  RemoveDialogMode = RemoveDialogMode;
  ContentType = ContentType;
  AclPermissionType = AclPermissionType;

  removeDialog = false;
  removeMode = RemoveDialogMode.MEMBER;

  // Removes membership ONLY. Published item and its WIP version will remain.
  get membership(): Membership | undefined {
    let parent = undefined;
    if (this.removeMode == RemoveDialogMode.MEMBER) {
      parent = this.parentProblemSet;
    } else {
      parent = this.grandparentProblemSet;
    }
    const childIndex = parent?.children.findIndex(
      (child) => child == this.member?.xref || this.member?.mappedCeri
    );
    if (parent && childIndex != undefined && childIndex >= 0) {
      return {
        parent: parent.xref,
        member: parent.children[childIndex],
        index: childIndex,
      };
    }
    return undefined;
  }

  get pathParts(): string[] {
    return this.contentPath.split(',');
  }

  // Can be a Problem or Problem Set.
  get member(): ProblemSetDefinition | ProblemDefinition | undefined {
    const target = this.pathParts[this.pathParts.length - 1];
    return getContentType(target) == ContentType.PROBLEM
      ? this.problemMap[target]
      : this.problemSetMap[target];
  }

  get parent(): string | undefined {
    return this.pathParts[this.pathParts.length - 2];
  }

  get parentProblemSet(): ProblemSetDefinition | undefined {
    return this.parent ? this.problemSetMap[this.parent] : undefined;
  }

  get grandparent(): string | undefined {
    return this.pathParts[this.pathParts.length - 3];
  }

  get grandparentProblemSet(): ProblemSetDefinition | undefined {
    return this.grandparent ? this.problemSetMap[this.grandparent] : undefined;
  }

  get problemSetMap(): Record<string, ProblemSetDefinition> {
    return this.$store.state.content.problemSetMap;
  }

  get problemMap(): Record<string, ProblemDefinition> {
    return this.$store.state.content.problemMap;
  }

  get memberLabel(): string {
    if (
      this.parentProblemSet?.problemSetType ==
      ProblemSetType.MULTI_PART_PROBLEM_SET
    ) {
      return 'part ' + (this.member?.xref || '');
    } else if (this.member?.contentType == ContentType.PROBLEM) {
      return 'Problem';
    } else if (this.member?.contentType == ContentType.PROBLEM_SET) {
      return 'Problem Set';
    } else {
      return '';
    }
  }

  get memberTestMode(): boolean {
    return (
      this.member?.contentType == ContentType.PROBLEM &&
      getTestModeStatus(this.member)
    );
  }

  // FIXME: Figure out how we handle deeply-nested Problem Sets?
  // Multi-part Problem Set test mode is determined by its part Problems.
  get parentTestMode(): boolean {
    const children = this.parentProblemSet?.children ?? [];
    return children.every((child) => getTestModeStatus(this.problemMap[child]));
  }

  editStandards(): void {
    this.$emit('open-edit-standards');
  }

  notifyToBeImplementedSoon(): void {
    this.$notify('To be implemented soon! Stay tuned.');
  }

  openRemoveDialog(mode: RemoveDialogMode): void {
    this.removeMode = mode;
    this.removeDialog = true;
  }

  navigateToSibling(data: Membership): void {
    const parent = this.problemSetMap[data.parent];
    // Excluding removed member.
    const pathParts = this.pathParts.splice(0, data.index);
    let siblingIndex = data.index;
    // Sibling member replacing removed member (in its position).
    if (siblingIndex == parent.children.length) {
      siblingIndex -= 1;
    }
    const sibling = parent.children[siblingIndex];
    if (sibling) {
      pathParts.push(sibling);
    }
    if (pathParts.length > 0) {
      this.$emit('selected', pathParts.join(','));
    } else {
      this.$emit('selected', null);
    }
  }

  addNewPart() {
    this.$emit('add-new-part');
  }
}
