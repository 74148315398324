import { CurriculumDefinition } from '@/domain/Curriculum';
import { getGradeLevelDisplayNames, GradeLevel } from '@/domain/Grade';
import { coreAxios } from '@/plugins/axios';

const END_POINT = '/hubspot';

export enum SelfIdentifyRole {
  ADMIN = 'Administrator',
  STUDENT = 'Student',
  TEACHER = 'Teacher',
}

export enum HubspotProperty {
  SELF_IDENTIFY_ROLE = 'self_identified_role',
  GRADES_TAUGHT = 'grades_taught_new',
  CURRICULA_TAUGHT = 'curriculums_taught_new',
}

export interface HubspotProperties {
  [HubspotProperty.SELF_IDENTIFY_ROLE]?: SelfIdentifyRole;
  [HubspotProperty.GRADES_TAUGHT]?: GradeLevel[];
  [HubspotProperty.CURRICULA_TAUGHT]?: CurriculumDefinition[];
}

export const contactHubspot = (
  properties: HubspotProperties
): Promise<void> => {
  const payload = transformProperties(properties);
  return coreAxios.post(`${END_POINT}/contact`, payload).then((res) => {
    return res.data;
  });
};

function transformProperties(
  properties: HubspotProperties
): Record<string, string[]> {
  const payload: Record<string, string[]> = {};
  // Should contain values IF included in map.
  for (const property in properties) {
    // eslint-disable-next-line
    // @ts-ignore
    const values = properties[property];
    switch (property) {
      case HubspotProperty.CURRICULA_TAUGHT:
        if (values?.length) {
          payload[property] = (values as CurriculumDefinition[]).map(
            (c) => c.name
          );
        }
        break;
      case HubspotProperty.GRADES_TAUGHT:
        if (values?.length) {
          payload[property] = getGradeLevelDisplayNames(values);
        }
        break;
      case HubspotProperty.SELF_IDENTIFY_ROLE:
        if (values) {
          payload[property] = [values];
        }
        break;
    }
  }
  return payload;
}
