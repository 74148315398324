
import { ContentType } from '@/domain/Content';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getContentType } from '@/utils/builder.util';

export interface CopyData {
  xref?: string;
  parent?: string;
  replace?: boolean;
}

@Component({})
export default class CopyProblemDialog extends Vue {
  @Prop({ required: true }) value: boolean;
  @Prop({ required: true }) copyData: CopyData;

  get showDialog(): boolean {
    return this.value;
  }

  set showDialog(value: boolean) {
    this.$emit('input', value);
  }

  get contentType(): ContentType | undefined {
    return this.copyData.xref ? getContentType(this.copyData.xref) : undefined;
  }

  copy(): void {
    if (this.contentType == ContentType.PROBLEM_SET) {
      this.$emit('copy-problem-set', this.copyData);
    } else if (this.contentType == ContentType.PROBLEM) {
      this.$emit('copy-problem', this.copyData);
    }
    this.showDialog = false;
  }
}
