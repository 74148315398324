
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { AssignmentDefinition, AssignmentStats } from '@/domain/Assignment';
import { Class } from '@/domain/Class';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import AssignmentStatsView from '@/components/MyAssignments/AssignmentStatsView.vue';
import AssignDialog, { Mode } from '@/components/FindProblems/AssignDialog.vue';
import DeleteAssignmentDialog from '@/components/MyAssignments/DeleteAssignmentDialog.vue';
import dayjs from 'dayjs';
import { ProblemDefinition } from '@/domain/Problem';
import { MessageType } from '@/domain/LTI';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';
import { RETURN_TEXT, RETURN_URL } from '@/domain/PageParams';
import {
  EditableAssignmentFields,
  getAssignmentStats,
} from '@/api/core/assignment.api';

@Component({
  components: {
    AssignmentStatsView,
    AssignDialog,
    DeleteAssignmentDialog,
  },
})
export default class AssignmentView extends Vue {
  @Prop({ required: true }) assignment: AssignmentDefinition;
  @Prop({ default: 'releaseDate' }) dateToShow: 'releaseDate' | 'dueDate';

  RETURN_URL = RETURN_URL;
  RETURN_TEXT = RETURN_TEXT;

  // To be downloaded asynchronously
  stats: AssignmentStats | null = null;

  // Loading states
  isDownloadingPS = false;
  hasDownloadedPS = false;
  isDownloadingProblems = false;
  hasDownloadedProblems = false;
  isDownloadingDetails = false;
  hasDownloadedDetails = false;
  isPrintingAssignment = false;
  isEditingAssignment = false;
  hasPrintedAssignment = false;

  showMenu = false;

  // Formatting
  placeholder = '-----';
  dateTimeFormat = 'dddd, MMM DD, YYYY h:mm A';

  // Allows us access to the enum in the template.
  Mode = Mode;

  // TODO/FIXME: We can also treat our custom dialog components
  // as any other components where the parent component (here)
  // controls the opening/closing of them based on the events
  // emitted regarding the operations that took place? But for now,
  // custom dialog components emit their new input value upon opening/closing.
  editDialog = false;
  assignDialog = false;
  deleteDialog = false;

  //////////////
  // Computed //
  //////////////

  get psXref(): string {
    return this.assignment.problemSetCeri;
  }

  get problemSet(): ProblemSetDefinition | null {
    return this.problemSetMap[this.psXref] ?? null;
  }

  get hasRedo(): boolean {
    return this.assignment.settings?.useRedo === true;
  }

  /**
   * Store
   */
  get classes(): Array<Class> {
    return this.$store.state.classList.classes;
  }

  get problemSetMap(): Record<string, ProblemSetDefinition> {
    return this.$store.state.content.problemSetMap;
  }

  get problemMap(): Record<string, ProblemDefinition> {
    return this.$store.state.content.problemMap;
  }
  /**
   * Display & Rendering
   */
  get targetClass(): Class | null {
    const classXref = this.assignment.groupContextXref;

    const classFound = this.classes.find(({ id }) => id === classXref);

    if (classFound) {
      return classFound;
    }

    return null;
  }
  get className(): string {
    if (this.targetClass) {
      return this.targetClass.name;
    }

    return this.placeholder;
  }
  get status(): string {
    const dateOfInterest = this.assignment[this.dateToShow];

    switch (this.dateToShow) {
      case 'releaseDate':
        if (dateOfInterest) {
          const date = dayjs(dateOfInterest);
          const now = dayjs();

          if (date.isBefore(now)) {
            return 'Released';
          } else if (date.isAfter(now)) {
            return 'Release Scheduled for';
          }
        } else {
          return 'No Release Date';
        }

        break;
      case 'dueDate':
        if (dateOfInterest) {
          return 'Due';
        } else {
          return 'No Due Date';
        }
      default:
        return this.placeholder;
    }

    return this.placeholder;
  }
  get date(): string {
    const dateOfInterest = this.assignment[this.dateToShow];

    if (dateOfInterest) {
      let date = dayjs(dateOfInterest);

      return date.format(this.dateTimeFormat);
    }

    return '';
  }
  get enableAssign(): boolean {
    return this.isLtiUser
      ? // IF content selection
        this.$store.state.lti.launch?.messageType === MessageType.DEEP_LINK
      : true;
  }
  /**
   * Problem set properties
   */
  get isSkillBuilder(): boolean {
    // FIXME: Migration - Skillbuilder fix
    return false;
    // return this.problemSet ? this.problemSet.isSkillBuilder : false;
  }
  /**
   * Problems to reassign if new PS needs to be created
   */
  get assistmentIds(): Array<number> {
    // FIXME: Migration - Problem set reassign fix
    // Select all problems (cannot choose a subset of problems to reassign)
    return [];
  }
  /**
   * Navigations
   */
  get previewLink(): string {
    return `${process.env.VUE_APP_STUDENT_WORKBENCH_URL}/preview/assignment/${this.assignment.xref}`;
  }
  get skillBuilderReportUrl(): string {
    return `${process.env.VUE_APP_TNG_URL}/masteryreport/${this.assignment.xref}?hideNav=true`;
  }

  /////////////
  // Methods //
  /////////////
  getProblemSetTreePromise(): Promise<void> {
    if (this.hasDownloadedProblems) {
      return Promise.resolve();
    }
    // Get problems if not downloaded already
    this.isDownloadingProblems = true;
    return this.$store
      .dispatch('content/getProblemSetTree', {
        xref: this.psXref,
      })
      .finally(() => {
        this.hasDownloadedProblems = true;
        this.isDownloadingProblems = false;
      });
  }

  // Must close menu on open of a dialog
  // Otherwise there is an unexpected closing
  // of dialog on mouseup outside of the dialog
  // https://github.com/vuetifyjs/vuetify/issues/11521
  openEditDialog(): void {
    this.isEditingAssignment = true;

    const psTreePromise = this.getProblemSetTreePromise();

    psTreePromise
      .then(() => {
        this.editDialog = true;
        this.showMenu = false;

        trackMixpanel(EventType.trackEditAssignmentClicked, {
          assignmentXref: this.assignment.xref,
        });
      })
      .finally(() => {
        this.isEditingAssignment = false;
      });
  }

  openAssignDialog(): void {
    this.isDownloadingProblems = true;
    const psTreePromise = this.getProblemSetTreePromise();

    psTreePromise
      .then(() => {
        this.assignDialog = true;
        this.showMenu = false;
        this.hasDownloadedProblems = true;

        trackMixpanel(EventType.trackDuplicateAssignmentClicked, {
          assignmentXref: this.assignment.xref,
        });
      })
      .finally(() => {
        this.isDownloadingProblems = false;
      });
  }

  openDeleteDialog(): void {
    this.deleteDialog = true;
    this.showMenu = false;
    trackMixpanel(EventType.trackDeleteAssignmentClicked, {
      assignmentXref: this.assignment.xref,
    });
  }
  downloadDetails(): void {
    // Download assignment detail if not already
    this.isDownloadingDetails = true;
    this.hasDownloadedDetails = false;
    getAssignmentStats(this.assignment.xref)
      .then((stats) => {
        this.stats = stats;
        this.hasDownloadedDetails = true;
      })
      .finally(() => {
        this.isDownloadingDetails = false;
      });
  }

  updateAssignment(modifiedFields: EditableAssignmentFields): void {
    // Modified assignment
    if (modifiedFields.name) {
      this.assignment.name = modifiedFields.name;
    }

    if (modifiedFields.releaseDate) {
      this.assignment.releaseDate = modifiedFields.releaseDate;
    } else if (modifiedFields.releaseDate === null) {
      // Removed? Set to now.
      this.assignment.releaseDate = dayjs().valueOf();
    } else {
      // Not modified if not included. So nothing to update.
    }

    if (modifiedFields.dueDate) {
      this.assignment.dueDate = modifiedFields.dueDate;
    } else if (modifiedFields.dueDate === null) {
      // We do have a due date set to null
      // Remove/delete due date?
      this.assignment.dueDate = null;
    } else {
      // Not modified if not included. So nothing to update.
    }
  }

  printAssignment(): void {
    if (this.hasPrintedAssignment) {
      this.emitPrint();
      return;
    }
    this.isPrintingAssignment = true;

    const psTreePromise = this.getProblemSetTreePromise();

    psTreePromise
      .then(() => {
        this.hasPrintedAssignment = true;
        this.emitPrint();
      })
      .finally(() => (this.isPrintingAssignment = false));

    trackMixpanel(EventType.trackPrintAssignment, {
      assignmentXref: this.assignment.xref,
    });

    // To load a new page instead of the printing window for development,
    // uncomment the statement below and comment out the 'this.emitPrint()' statement above
    // this.$router.push({
    //   name: 'printAssignment',
    //   params: {
    //     xref: `${this.problemSet.xref}`,
    //   },
    // });
  }

  emitPrint(): void {
    this.showMenu = false;
    this.$emit('print', {
      problemSet: this.problemSet,
      name: this.assignment.name,
      className: this.className,
      status: this.status,
      date: this.date,
      contentType: 'ASSIGNMENT',
    });
  }

  created(): void {
    // Download classes if not already
    // if (this.$store.state.classList.classes.length === 0) {}
    // if (!this.$store.state.classList.hasDownloaded && !this.$store.state.classList.isDownloading) {}
    // If a list of all classes is missing from store, be the first instance to request it
    // Will be prevented to download again in store if already
    this.$store.dispatch('classList/requestClasses');
  }

  @Watch('hasDownloadedDetails')
  trackMyAssignmentsDetails(newVal: boolean, oldVal: boolean): void {
    if (newVal !== oldVal) {
      trackMixpanel(EventType.myAssignmentsDetails, {
        assignmentXref: this.assignment.xref,
        preview: newVal,
        reDoStatus: this.hasRedo,
      });
    }
  }

  @Watch('showMenu')
  trackMyAssignmentsShowMenu(newVal: boolean, oldVal: boolean): void {
    if (newVal !== oldVal) {
      trackMixpanel(EventType.myAssignmentsShowMenu, {
        assignmentXref: this.assignment.xref,
        showMenu: newVal,
        reDoStatus: this.hasRedo,
      });
    }
  }

  trackAssignmentPreviewAsStudent(): void {
    trackMixpanel(EventType.trackAssignmentPreviewAsStudent, {
      assignmentXref: this.assignment.xref,
      reDoStatus: this.hasRedo,
    });
  }
}
