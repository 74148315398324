import { render, staticRenderFns } from "./ProblemSetBuilderMenu.vue?vue&type=template&id=766f0787&scoped=true&"
import script from "./ProblemSetBuilderMenu.vue?vue&type=script&lang=ts&"
export * from "./ProblemSetBuilderMenu.vue?vue&type=script&lang=ts&"
import style0 from "./ProblemSetBuilderMenu.vue?vue&type=style&index=0&id=766f0787&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "766f0787",
  null
  
)

export default component.exports