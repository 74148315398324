
import { Component, Prop, Vue } from 'vue-property-decorator';
import AssignDialog from './AssignDialog.vue';
import SaveToMyProblemSetsDialog from '@/components/MyProblemSets/SaveToMyProblemSetsDialog.vue';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import {
  ProblemSetNode,
  getProblemCountFromStructure,
  getProblemSetStructure,
  isSkillBuilder,
} from '@/utils/problemSet.util';
import { isWip } from '@/utils/builder.util';
import { MessageType } from '@/domain/LTI';

@Component({
  components: {
    AssignDialog,
    SaveToMyProblemSetsDialog,
  },
})
export default class AssignFab extends Vue {
  @Prop({ required: false, default: 0 }) margin: number;
  @Prop({ default: () => [] }) selectedTree: ProblemSetNode[];
  // Mutually exclusive
  @Prop({ required: false }) psCeri?: string;
  @Prop({ required: false }) totalProblems?: number;

  fab = false;
  assignDialog = false;
  saveToMyProblemSets = false;

  get validLaunch(): boolean {
    return this.isLtiUser
      ? this.$store.state.lti.launch?.messageType === MessageType.DEEP_LINK
      : true;
  }

  get showFab(): boolean {
    return (
      this.isAuthenticated &&
      this.validLaunch &&
      !this.isWip &&
      this.numProblemsSelected > 0
    );
  }

  get problemSetMap(): Record<string, ProblemSetDefinition> {
    return this.$store.state.content.problemSetMap;
  }

  get problemSet(): ProblemSetDefinition | undefined {
    return this.psCeri ? this.problemSetMap[this.psCeri] : undefined;
  }

  get isWip(): boolean {
    return this.problemSet ? isWip(this.problemSet.xref) : false;
  }

  get isSkillBuilder(): boolean {
    return this.problemSet ? isSkillBuilder(this.problemSet) : false;
  }

  get problemSetTree(): ProblemSetNode[] {
    if (this.problemSet && this.problemSetMap) {
      return getProblemSetStructure(this.problemSet, this.problemSetMap);
    } else {
      return [];
    }
  }

  get numProblemsTotal(): number {
    if (this.problemSet) {
      return getProblemCountFromStructure(this.problemSetTree);
    } else {
      return this.totalProblems ?? 0;
    }
  }

  get numProblemsSelected(): number {
    return getProblemCountFromStructure(this.selectedTree);
  }

  get assignmentName(): string {
    if (this.problemSet) {
      const header = this.problemSet?.properties?.HEADER;
      return `${header ? `${header} --- ` : ''}${this.problemSet.name}`;
    } else {
      return '';
    }
  }
}
