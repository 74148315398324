var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","data-cy":"folder-menu-btn"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{staticClass:"text-body-2"},[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$emit('open', _vm.folder.xref)}}},[_vm._v(" Open contents ")]),(_vm.folder.xref != _vm.EXPLORE_CONTENT)?[_c('EditFolderDialog',{attrs:{"mode":_vm.EditMode.UPDATE,"path":_vm.path}}),(_vm.parentPage == _vm.PageView.MAIN_HIERARCHY)?_c('CopyFolderDialog',{attrs:{"path":_vm.path}}):_vm._e(),(
            !_vm.isRoot && _vm.parent?.permissions.includes(_vm.AclPermissionType.UPDATE)
          )?_c('MoveToFolderDialog',{attrs:{"path":_vm.path}}):_vm._e(),(_vm.parentPage == _vm.PageView.MAIN_HIERARCHY)?_c('CopyTextDialog',{attrs:{"title":"Obtain ID","description":"Folder ID","content":_vm.folder.xref}}):_vm._e(),(_vm.parentPage == _vm.PageView.MAIN_HIERARCHY)?_c('CopyTextDialog',{attrs:{"title":"Generate Link","description":"Folder URL","content":_vm.url}}):_vm._e(),(
            _vm.parentPage == _vm.PageView.MAIN_HIERARCHY &&
            (_vm.isServiceAdminUser ||
              _vm.isContentAdminUser ||
              _vm.getCurrentUser.xref == _vm.folder.ownerXref)
          )?_c('PermissionsDialog',{attrs:{"path":_vm.path}}):_vm._e()]:_vm._e(),(_vm.folder.permissions.includes(_vm.AclPermissionType.UPDATE))?[_c('v-divider',{staticClass:"my-2"}),(!_vm.folder.properties.OPEN_IN_LESSON_PAGE)?_c('EditFolderDialog',{attrs:{"mode":_vm.EditMode.CREATE,"path":_vm.path}}):_vm._e(),(_vm.parentPage == _vm.PageView.MAIN_HIERARCHY)?_c('v-list-item',{attrs:{"link":"","data-cy":"add-new-problem-set-btn"},on:{"click":function($event){_vm.createPSDialog = true}}},[_vm._v(" Add new problem set ")]):_vm._e()]:_vm._e(),(
          !_vm.isRoot && _vm.folder.permissions.includes(_vm.AclPermissionType.DELETE)
        )?[_c('v-divider',{staticClass:"my-2"}),_c('DeleteFolderDialog',{attrs:{"path":_vm.path}})]:_vm._e()],2)],1),_c('CreateProblemSetDialog',{on:{"created":_vm.addProblemSet},model:{value:(_vm.createPSDialog),callback:function ($$v) {_vm.createPSDialog=$$v},expression:"createPSDialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }