import {
  addMembersToFolder,
  getFolder,
  getFolderMembers,
  searchForFolders,
} from '@/api/core/folders.api';
import { FolderDefinition, FolderMemberType } from '@/domain/Folder';
import { RootState } from '@/store/types';
import { ActionTree } from 'vuex';
import { FolderState } from './types';
import { cloneDeep } from 'lodash';
import { DefinitionInclude } from '@/api/core/base.api';

export const actions: ActionTree<FolderState, RootState> = {
  getFolders(context, xrefs: string[]): Promise<FolderDefinition[]> {
    if (xrefs?.length) {
      return searchForFolders({ xrefs }, undefined, {
        include: [DefinitionInclude.ATTRIBUTES],
      }).then((folderList) => {
        const folders = folderList.data;
        for (const folder of folders) {
          context.commit('setFolder', folder);
        }
        return folders;
      });
    } else {
      return Promise.resolve([]);
    }
  },
  getFolderMembers(
    context,
    { xref, limit, nextPageToken }
  ): Promise<string | undefined> {
    const folder = context.state.folderMap[xref];
    let promise: Promise<FolderDefinition>;
    if (folder) {
      promise = Promise.resolve(cloneDeep(folder));
    } else {
      promise = getFolder(xref, [DefinitionInclude.ATTRIBUTES]);
    }
    return promise.then((folder) => {
      return getFolderMembers(xref, [DefinitionInclude.ATTRIBUTES], {
        limit,
        nextPageToken,
      }).then((response) => {
        if (!nextPageToken) {
          folder.children = [];
        }
        for (const member of response.data) {
          folder.children.push(member.xref);
          switch (member.memberType) {
            case FolderMemberType.ASSIGNMENT:
              break;
            case FolderMemberType.FOLDER:
              context.commit('setFolder', member);
              break;
            case FolderMemberType.PUB_PS:
            case FolderMemberType.WIP_PS:
              context.commit('content/setProblemSet', member, { root: true });
              break;
          }
        }
        context.commit('setFolder', folder);
        return response.nextPageToken;
      });
    });
  },
  addMembersToFolder(context, { xref, xrefs }): Promise<void> {
    return addMembersToFolder(xref, xrefs).then(() => {
      context.commit('addMembersToFolder', {
        folderXref: xref,
        memeberXrefs: xrefs,
      });
    });
  },
};
