
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ProblemSetDefinition, ProblemSetType } from '@/domain/ProblemSet';
import PreviewInformationPopup from '../base/PreviewInformationPopup.vue';
import { ContentSaveOperationType } from '@/api/core/content.api';
import {
  EXPLORE_CONTENT,
  FolderDefinition,
  FolderMemberType,
} from '@/domain/Folder';
import MoveToFolderDialog from '../Builder/MoveToFolderDialog.vue';
import { PageView } from '@/utils/navigation.util';
import { BuilderMode } from './BuilderToolbar.vue';
import { AclPermissionType } from '@/domain/Acls';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';
import GoToParentProblemSetDialog from '../base/GoToParentProblemSetDialog.vue';
import PermissionsDialog from './PermissionsDialog.vue';

@Component({
  components: {
    GoToParentProblemSetDialog,
    MoveToFolderDialog,
    PermissionsDialog,
    PreviewInformationPopup,
  },
})
export default class ProblemSetFileMenu extends Vue {
  @Prop({ required: true }) path: (FolderDefinition | ProblemSetDefinition)[];
  @Prop({ default: false }) isFirst: boolean;
  @Prop({ default: false }) isLast: boolean;

  EXPLORE_CONTENT = EXPLORE_CONTENT;

  PageView = PageView;
  FolderMemberType = FolderMemberType;
  AclPermissionType = AclPermissionType;

  // For 'Go to Problem Set' dialog (when multiple options)
  navigateToProblemSet = false;

  get parentPage(): PageView {
    return this.$route?.meta?.page;
  }

  get problemSet(): ProblemSetDefinition {
    return this.path[this.path.length - 1] as ProblemSetDefinition;
  }

  get parent(): FolderDefinition | ProblemSetDefinition | undefined {
    return this.path[this.path.length - 2];
  }

  get isAlphaTeacher(): boolean {
    return (
      this.$store.state.auth.user?.settings.alphaTeacherBuilderAccess ?? false
    );
  }

  get isMultiPartProblemSet(): boolean {
    return (
      this.problemSet.problemSetType === ProblemSetType.MULTI_PART_PROBLEM_SET
    );
  }

  editDialog = false;
  deleteDialog = false;
  showMenu = false;

  loading = false;
  name = '';

  isDownloadingProblems = false;
  hasDownloadedProblems = false;

  printProblemSet(): void {
    if (this.hasDownloadedProblems) {
      this.emitPrint();
      return;
    }

    const psTreePromise = this.getProblemSetTreePromise();

    this.isDownloadingProblems = true;

    psTreePromise
      .then(() => {
        this.hasDownloadedProblems = true;
        this.isDownloadingProblems = false;
        this.emitPrint();
        trackMixpanel(EventType.printMyProblemSet, {
          psId: this.problemSet.xref,
          psName: this.problemSet.name,
        });
      })
      .catch((error) => {
        error.handleGlobally();
      });
  }

  emitPrint(): void {
    this.showMenu = false;
    this.$emit('print', {
      problemSet: this.problemSet,
      name: '',
      className: '',
      status: '',
      date: '',
      contentType: 'PROBLEM_SET',
    });
  }

  getProblemSetTreePromise(): Promise<void> {
    if (this.hasDownloadedProblems) {
      return Promise.resolve();
    }
    // Get problems if not downloaded already
    this.isDownloadingProblems = true;
    return this.$store.dispatch('content/getProblemSetTree', {
      xref: this.problemSet.xref,
    });
  }

  duplicateProblemSet(): void {
    const oldCeri = this.problemSet.xref;

    this.$store
      .dispatch('content/duplicateProblemSet', oldCeri)
      .then(({ ceri: newCeri, failMessages: error }) => {
        if (error?.length) {
          this.$notify(`Failed to copy problem set: ${error}`);
        } else {
          if (
            this.parent &&
            this.parent.memberType === FolderMemberType.FOLDER
          ) {
            this.$store
              .dispatch('folder/addMembersToFolder', {
                xref: this.parent.xref,
                xrefs: [newCeri],
              })
              .then(() => {
                this.$notify(`Created ${newCeri} as Copy of ${oldCeri}.`);
              })
              .catch(() => {
                this.$notify(`Failed to copy ${oldCeri}.`);
              });
          } else {
            this.$emit('copyProblemSet', newCeri);
          }
        }
      })
      .catch(() => {
        this.$notify('Something went wrong. Failed to copy Problem Set.');
      })
      .finally(() => {
        this.loading = false;
      });
  }

  get previewLink(): string {
    return `${process.env.VUE_APP_STUDENT_WORKBENCH_URL}/preview/problemSet/${this.problemSet.xref}`;
  }

  get modified(): boolean {
    return this.problemSet.name != this.name && this.name.length > 0;
  }

  removeProblemSet(): void {
    this.deleteDialog = false;
    this.$emit('remove', this.problemSet.xref);
    trackMixpanel(EventType.deleteMyProblemSet, {
      psId: this.problemSet.xref,
      psName: this.problemSet.name,
    });
  }

  updateName(): void {
    this.showMenu = false;
    // Only update if new name is different
    if (this.modified) {
      this.loading = true;
      this.$store
        .dispatch('content/saveProblemSet', {
          xref: this.problemSet.xref,
          modifiedFields: { name: this.name },
          opType: ContentSaveOperationType.PUBLISH,
        })
        .then(({ ceri: xref, failMessages: error }) => {
          if (error) {
            this.$notify(`Failed to update problem set: ${error}`);
          } else if (xref) {
            this.$notify(`Saved changes to Problem Set ${xref}.`);
            this.editDialog = false;
            trackMixpanel(EventType.renameMyProblemSet, {
              psId: this.problemSet.xref,
              psName: this.name,
            });
          }
        })
        .catch(() => {
          this.$notify('Something went wrong. Failed to update Problem Set.');
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  cancelEdit(): void {
    this.editDialog = false;
    this.name = this.problemSet.name || '';
  }

  goToEditProblemSet(): void {
    this.$router.push({
      name: 'alphaTeacherBuilder',
      params: {
        psXref: this.problemSet.xref,
      },
      query: {
        contentPath: `${this.problemSet.xref}`,
        mode: BuilderMode.READ,
      },
    });
  }

  created(): void {
    this.name = this.problemSet.name || '';
  }

  // Close overflow menu when opening/closing edit ps dialog
  @Watch('editDialog')
  openEditPsDialog(): void {
    this.showMenu = false;
    this.name = this.problemSet.name || '';
  }

  @Watch('showMenu')
  trackProblemSetShowMenu(newVal: boolean, oldVal: boolean): void {
    if (newVal !== oldVal) {
      trackMixpanel(EventType.trackProblemSetShowMenu, {
        showMenu: newVal,
        psId: this.problemSet.xref,
        psName: this.problemSet.name,
      });
    }
  }

  trackPSPreviewAsStudent(): void {
    trackMixpanel(EventType.trackProblemSetPreviewAsStudent, {
      psId: this.problemSet.xref,
      psName: this.problemSet.name,
    });
  }
}
