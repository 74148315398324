
import { Component, Vue } from 'vue-property-decorator';
import { updateMyUserAttribute } from '@/api/core/user.api';
import SchoolSelector from '@/components/Settings/SchoolSelector.vue';
import { AttributeNames } from '@/domain/Attributes';
import userflow from 'userflow.js';

@Component({
  components: {
    SchoolSelector,
  },
})
export default class SelectSchool extends Vue {
  selectedSchools: string[] = [];
  submitting = false;

  //These do nothing for now other than move you onto selecting a grade and curriculum. When someone decides what to do then we will do something
  noSchool(): void {
    userflow.track(
      'unaffiliated_with_a_school_clicked',
      // No attributes
      {},
      // Options
      {
        userOnly: true,
      }
    );

    this.goToRedirect();
  }

  missingSchool(): void {
    userflow.track(
      'school_not_listed_clicked',
      // No attributes
      {},
      // Options
      {
        userOnly: true,
      }
    );
    this.goToRedirect();
  }

  goToRedirect(): void {
    this.$router.push(this.$route.query.goTo as string);
  }

  submitSchool(): void {
    this.submitting = true;
    updateMyUserAttribute(AttributeNames.NCES_SCHOOL, this.selectedSchools)
      .then(() => {
        this.goToRedirect();
      })
      .catch(() => {
        //Something bad happened.
        this.$notify(
          'An error occurred while saving school info. Please try again or move on.'
        );
      })
      .finally(() => {
        this.submitting = false;
      });
  }
}
