import { IHasPermissions } from './Acls';
import { IFolderMember } from './Folder';
import { LmsProviderType } from './LmsProviderType';

export interface AssignmentStats {
  numCompleted: number;
  numAssigned: number;
  numProblems: number;
}

export interface AssignmentDefinition extends IFolderMember, IHasPermissions {
  scope: AssignmentScope;
  assigneeType: AssigneeType;
  xref: string;
  assigneeXref: string;
  ownerXref: string;
  groupContextXref: string;
  problemSetCeri: string;
  lmsProviderType?: LmsProviderType;
  name: string;
  assignDate: number;
  releaseDate: number;
  dueDate: number | null;
  active: boolean;
  settings?: AssignmentSettings;
  skills: string[];
}

export interface AssignmentSort {
  attribute: keyof AssignmentDefinition;
  sortBy: AssignmentSortByField;
}

export enum AssignmentType {
  PROBLEM_SET = 'PROBLEM SET',
  EXIT_TICKET = 'EXIT TICKET',
  HOMEWORK = 'HOMEWORK',
  // etc. TBD
}

export enum AssignmentScope {
  USER = 'USER',
  PRINCIPAL_GROUP = 'PRINCIPAL_GROUP',
  NOT_A_PRINCIPAL = 'NOT_A_PRINCIPAL',
}

export enum AssigneeType {
  INDIVIDUAL = 'INDIVIDUAL',
  INDIVIDUAL_IN_GROUP = 'INDIVIDUAL_IN_GROUP',
  GROUP = 'GROUP',
}

export enum AssignmentStatus {
  UPCOMING = 'UPCOMING',
  RELEASED = 'RELEASED',
  PAST_DUE = 'PAST_DUE',
}

export type AssignmentSortByField =
  | '+DUE_DATE'
  | '-DUE_DATE'
  | '+RELEASE_DATE'
  | '-RELEASE_DATE';

/**
 * These enum values are used on the back-end,
 * but the server sends the keys rather than values.
 * The above fix can be rolled back if the server
 * is switched to send enum values, rather than keys
 */
// export enum ActionType {
// PROBLEM_SET = 1,
// EXIT_TICKET = 2,
// HOMEWORK = 3,
// }

export interface AssignmentSettings {
  [key: string]: boolean | string | number;

  // ONLY include if you want to override settings
  correctnessFeedback: boolean;
  tutoringAccessible: boolean;
  liveTutoringInsteadOfTutoring: boolean;
  liveTutoringEnabled: boolean;
  personalizedTutoringEnabled: boolean;
  starredTeacherEnabled: boolean;
  teacherAssistEnabled: boolean;
  partialCredit: boolean;
  hintDelay: number;
  partialCreditMaxAttempts: number;
  timeLimit: number;
  deliveryOfStudentScore: boolean;
  useRedo: boolean;
  assignedByTutor: boolean;
  useTeachley: boolean;
  scoringSchema: ScoringSchema;
  automaticScoringOnCompletion: boolean;
  useCait: boolean;
}

export enum ScoringSchema {
  POINT = 'POINT',
  COMPLETION = 'COMPLETION',
}
