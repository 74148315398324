import { render, staticRenderFns } from "./ProblemViewForBuilder.vue?vue&type=template&id=cfa877ca&scoped=true&"
import script from "./ProblemViewForBuilder.vue?vue&type=script&lang=ts&"
export * from "./ProblemViewForBuilder.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfa877ca",
  null
  
)

export default component.exports