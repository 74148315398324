/* eslint-disable @typescript-eslint/ban-types */
import { Server } from 'miragejs';
import dayjs from 'dayjs';
import { AssigneeType, AssignmentScope } from '@/domain/Assignment';
import { faker } from '@faker-js/faker';

faker.seed(0);

export default function (server: Server): void {
  server.create('assignmentDefinition', {
    scope: AssignmentScope.PRINCIPAL_GROUP,
    assigneeType: AssigneeType.GROUP,
    xref: 'wpiG6PSFf0aguy2P0bw9',
    assigneeXref: '1',
    ownerXref: 'hLVmvz6zgr6nmyEMW9qt',
    groupContextXref: '1',
    problemSetCeri: 'PSABHT54',
    lmsProviderType: 'GOOGLE_CLASSROOM',
    name: '6.1 Lesson 1 Student Facing Task - Tiling the Plane (6.G.A.1)',
    assignDate: dayjs().subtract(2, 'week').valueOf(), // assigned two weeks ago
    releaseDate: dayjs().subtract(2, 'week').valueOf(),
    dueDate: dayjs().subtract(3, 'days').valueOf(), // due three days ago
    skills: [
      'f9d87e9e-01e5-4ba7-b7db-476f6223047c',
      'f1654496-4a4c-4a55-9b6e-a45444153439',
    ],
    active: true,
    properties: [
      {
        categoryName: 'TIME_LIMIT',
        categoryDescription: 'Time Limit',
        propertyKey: 'timeLimit',
        propertyDescription: 'Time Limit',
        propertyValue: 180,
      },
      {
        categoryName: 'USE_REDO',
        categoryDescription: 'Use Redo',
        propertyKey: 'useRedo',
        propertyDescription:
          'When true, enables the student experience to deliver similar problems',
        propertyValue: false,
      },
    ],
  } as object);
  server.create('assignmentDefinition', {
    scope: AssignmentScope.PRINCIPAL_GROUP,
    assigneeType: AssigneeType.GROUP,
    xref: '9JnFP7A4K853S3d9HsF2',
    assigneeXref: '2',
    ownerXref: 'hLVmvz6zgr6nmyEMW9qt',
    groupContextXref: '2',
    problemSetCeri: 'PSABS7K4',
    lmsProviderType: 'GOOGLE_CLASSROOM',
    name: '6.3 Lesson 2: Anchoring Units of Measurement (6.RP.A.3.d)',
    assignDate: dayjs().valueOf(),
    releaseDate: dayjs().subtract(1, 'day').valueOf(),
    dueDate: dayjs().add(1, 'week').valueOf(),
    skills: ['80bd6b58-bae2-49e2-a88c-735edc0ebcd4'],
    active: false,
    properties: [
      {
        categoryName: 'TIME_LIMIT',
        categoryDescription: 'Time Limit',
        propertyKey: 'timeLimit',
        propertyDescription: 'Time Limit',
        propertyValue: 180,
      },
      {
        categoryName: 'USE_REDO',
        categoryDescription: 'Use Redo',
        propertyKey: 'useRedo',
        propertyDescription:
          'When true, enables the student experience to deliver similar problems',
        propertyValue: 'true',
      },
    ],
  } as object);
  server.create('assignmentDefinition', {
    scope: AssignmentScope.PRINCIPAL_GROUP,
    assigneeType: AssigneeType.GROUP,
    xref: 'Or1tJNwlmCkwGjnUcbvX',
    assigneeXref: '3',
    ownerXref: 'hLVmvz6zgr6nmyEMW9qt',
    groupContextXref: '3',
    problemSetCeri: 'PSABHT5A',
    lmsProviderType: 'CANVAS',
    name: 'TEST MODE: 6.1 Lesson 2: Finding Area by Decomposing and Rearranging (6.G.A.1)',
    assignDate: dayjs().subtract(1, 'day').valueOf(),
    releaseDate: dayjs().add(2, 'week').valueOf(),
    dueDate: dayjs().add(1, 'month').valueOf(),
    skills: [
      '3ecfa8b5-f44f-46d2-b280-adc2bf596d08',
      'f1654496-4a4c-4a55-9b6e-a45444153439',
    ],
    active: true,
    properties: [
      {
        categoryName: 'TEST_MODE',
        categoryDescription: 'Correctness Feedback',
        propertyKey: 'correctnessFeedback',
        propertyDescription: 'Correctness Feedback',
        propertyValue: 'false',
      },
      {
        categoryName: 'TEST_MODE',
        categoryDescription: 'Tutoring Accessible',
        propertyKey: 'tutoringAccessible',
        propertyDescription: 'Tutoring Accessible',
        propertyValue: 'false',
      },
    ],
    // properties: {
    //   correctnessFeedback: false,
    //   tutoringAccessible: false,
    // },
  } as object);
  server.create('assignmentDefinition', {
    scope: AssignmentScope.PRINCIPAL_GROUP,
    assigneeType: AssigneeType.GROUP,
    xref: 'G0MJJi02j3zSo9tOH2lC',
    assigneeXref: '2',
    ownerXref: 'hLVmvz6zgr6nmyEMW9qt',
    groupContextXref: '2',
    problemSetCeri: 'PSA6N9K',
    lmsProviderType: 'CANVAS',
    name: '6.1 Lesson 2: Finding Area by Decomposing and Rearranging (6.G.A.1)',
    assignDate: dayjs().subtract(3, 'week').valueOf(),
    releaseDate: dayjs().subtract(2, 'week').valueOf(),
    dueDate: dayjs().subtract(1, 'week').valueOf(),
    skills: [
      '3ecfa8b5-f44f-46d2-b280-adc2bf596d08',
      'f1654496-4a4c-4a55-9b6e-a45444153439',
    ],
    active: false,
    properties: [
      {
        categoryName: 'TIME_LIMIT',
        categoryDescription: 'Time Limit',
        propertyKey: 'timeLimit',
        propertyDescription: 'Time Limit',
        propertyValue: '0',
      },
    ],
  } as object);
  server.create('assignmentDefinition', {
    scope: AssignmentScope.PRINCIPAL_GROUP,
    assigneeType: AssigneeType.GROUP,
    xref: 'TEST_XREF_1234',
    assigneeXref: '1',
    ownerXref: 'hLVmvz6zgr6nmyEMW9qt',
    groupContextXref: '1',
    problemSetCeri: 'PSABHT54', // FIXME: ProblemXref?
    lmsProviderType: 'GOOGLE_CLASSROOM',
    name: 'TEST: Student Details report',
    assignDate: dayjs().valueOf(),
    releaseDate: dayjs().valueOf(),
    dueDate: null,
    skills: [],
    active: true,
  } as object);
  server.create('assignmentDefinition', {
    scope: AssignmentScope.PRINCIPAL_GROUP,
    assigneeType: AssigneeType.GROUP,
    xref: 'GnhVZMxlBpkoRViC9KgL',
    assigneeXref: '3',
    ownerXref: 'hLVmvz6zgr6nmyEMW9qt',
    groupContextXref: '3',
    problemSetCeri: 'PSABHT5A',
    lmsProviderType: 'CANVAS',
    name: '(LEGACY) TEST MODE: 6.1 Lesson 2: Finding Area by Decomposing and Rearranging (6.G.A.1)',
    assignDate: dayjs().subtract(1, 'day').valueOf(),
    releaseDate: dayjs().add(2, 'week').valueOf(),
    dueDate: dayjs().add(1, 'month').valueOf(),
    skills: [
      '3ecfa8b5-f44f-46d2-b280-adc2bf596d08',
      'f1654496-4a4c-4a55-9b6e-a45444153439',
    ],
    active: true,
    properties: [
      {
        categoryName: 'TEST_MODE',
        categoryDescription: 'Correctness Feedback',
        propertyKey: 'correctnessFeedback',
        propertyDescription: 'Correctness Feedback',
        propertyValue: 'false',
      },
      {
        categoryName: 'TEST_MODE',
        categoryDescription: 'Tutoring Accessible',
        propertyKey: 'tutoringAccessible',
        propertyDescription: 'Tutoring Accessible',
        propertyValue: 'false',
      },
    ],
  } as object);
  server.create('assignmentDefinition', {
    scope: AssignmentScope.PRINCIPAL_GROUP,
    assigneeType: AssigneeType.GROUP,
    xref: 'kbpqEqpkycnPaAsRhlJ0',
    assigneeXref: '1',
    ownerXref: 'hLVmvz6zgr6nmyEMW9qt',
    groupContextXref: '1',
    problemSetCeri: 'PSABSY9D.8551476',
    name: "Cathryn's Redo",
    assignDate: 1679679102552,
    releaseDate: 1679679102552,
    skills: [
      '50146782-7d89-40ae-993f-504ab244e506',
      'b625b84c-481b-4bf7-ba07-1bce392ba8f5',
    ],
    isActive: true,
    properties: [
      {
        propertyKey: 'timeLimit',
        propertyDescription: 'Sets assignment time limit in seconds',
        propertyValue: '180',
      },
      {
        propertyKey: 'useRedo',
        propertyDescription:
          'When true, enables the student experience to deliver similar problems',
        propertyValue: 'true',
      },
      {
        propertyKey: 'deliveryOfStudentScore',
        propertyDescription: 'Defining score delivery',
        propertyValue: 'true',
      },
      {
        propertyKey: 'assignmentHasExperiment',
        propertyDescription: 'If an assignment has an experiment',
        propertyValue: 'false',
      },
      {
        propertyKey: 'liveTutoringExtPartner',
        propertyDescription:
          'Live Tutoring External Partner (used for url in the tutor)',
        propertyValue: 'Upchieve',
      },
      {
        propertyKey: 'liveTutoringInsteadOfTutoring',
        propertyDescription:
          'Live Tutoring Button replaces the Original Tutoring Button',
        propertyValue: 'false',
      },
      {
        propertyKey: 'testModeStudentReport',
        propertyDescription: 'Enable Test Mode Student Reports',
        propertyValue: 'false',
      },
      {
        propertyKey: 'liveTutoringEnabled',
        propertyDescription:
          'Is the live tutoring button available to students',
        propertyValue: 'true',
      },
      {
        propertyKey: 'personalizedTutoringEnabled',
        propertyDescription: 'enable personalized tutoring',
        propertyValue: 'true',
      },
      {
        propertyKey: 'starredTeacherEnabled',
        propertyDescription: 'automatically subscribed to starred teachers',
        propertyValue: 'true',
      },
      {
        propertyKey: 'teacherAssistEnabled',
        propertyDescription:
          'enable an option to create their own teacherASSIST',
        propertyValue: 'false',
      },
      {
        propertyKey: 'partialCredit',
        propertyDescription: 'Is partial credit enabled',
        propertyValue: 'true',
      },
      {
        propertyKey: 'hintDelay',
        propertyDescription: 'Number of seconds between each hint',
        propertyValue: '0',
      },
      {
        propertyKey: 'partialCreditMaxAttempts',
        propertyDescription:
          'Max attempts and hints used before partial credit score goes to 0',
        propertyValue: '3',
      },
      {
        propertyKey: 'useCait',
        propertyDescription: 'When true, the student has requested CAIT',
        propertyValue: 'true',
      },
    ],
  } as object);
  server.create('assignmentDefinition', {
    scope: 'GROUP',
    assigneeType: 'GROUP',
    xref: 'REDO_ASSIGNMENT',
    assigneeXref: '1',
    ownerXref: 'hLVmvz6zgr6nmyEMW9qt',
    groupContextXref: '1',
    problemSetCeri: 'PSABXACC',
    lmsProviderType: 'GOOGLE_CLASSROOM',
    name: 'Redo Assignment (e6121fbd-c9b5-4c2f-8ba6-83bf80de87da)',
    assignDate: 1695776506172,
    releaseDate: 1695776506172,
    isActive: true,
    properties: [
      {
        propertyKey: 'timeLimit',
        propertyDescription: 'Sets assignment time limit in seconds',
        propertyValue: '0',
      },
      {
        propertyKey: 'useRedo',
        propertyDescription:
          'When true, enables the student experience to deliver similar problems',
        propertyValue: 'true',
      },
      {
        propertyKey: 'deliveryOfStudentScore',
        propertyDescription: 'Defining score delivery',
        propertyValue: 'true',
      },
      {
        propertyKey: 'assignmentHasExperiment',
        propertyDescription: 'If an assignment has an experiment',
        propertyValue: 'false',
      },
      {
        propertyKey: 'liveTutoringExtPartner',
        propertyDescription:
          'Live Tutoring External Partner (used for url in the tutor)',
        propertyValue: 'Upchieve',
      },
      {
        propertyKey: 'liveTutoringInsteadOfTutoring',
        propertyDescription:
          'Live Tutoring Button replaces the Original Tutoring Button',
        propertyValue: 'false',
      },
      {
        propertyKey: 'testModeStudentReport',
        propertyDescription: 'Enable Test Mode Student Reports',
        propertyValue: 'false',
      },
      {
        propertyKey: 'liveTutoringEnabled',
        propertyDescription:
          'Is the live tutoring button available to students',
        propertyValue: 'true',
      },
      {
        propertyKey: 'personalizedTutoringEnabled',
        propertyDescription: 'enable personalized tutoring',
        propertyValue: 'true',
      },
      {
        propertyKey: 'starredTeacherEnabled',
        propertyDescription: 'automatically subscribed to starred teachers',
        propertyValue: 'true',
      },
      {
        propertyKey: 'teacherAssistEnabled',
        propertyDescription:
          'enable an option to create their own teacherASSIST',
        propertyValue: 'false',
      },
      {
        propertyKey: 'partialCredit',
        propertyDescription: 'Is partial credit enabled',
        propertyValue: 'true',
      },
      {
        propertyKey: 'hintDelay',
        propertyDescription: 'Number of seconds between each hint',
        propertyValue: '0',
      },
      {
        propertyKey: 'partialCreditMaxAttempts',
        propertyDescription:
          'Max attempts and hints used before partial credit score goes to 0',
        propertyValue: '3',
      },
    ],
    skills: [
      '5cda5966-42f1-4cbb-930b-415e6dcc9835',
      '86bf2335-0a2f-4a44-bd6e-a33e8048526f',
    ],
  } as object);
  server.create('assignmentDefinition', {
    scope: 'GROUP',
    assigneeType: 'GROUP',
    xref: 'ESSAY_SCORING_ASSIGNMENT',
    assigneeXref: '1',
    ownerXref: 'hLVmvz6zgr6nmyEMW9qt',
    groupContextXref: '1',
    problemSetCeri: 'PSABSVSE',
    lmsProviderType: 'GOOGLE_CLASSROOM',
    name: 'Open Response Assignment (708ceb49-1459-4616-9530-31b3ab3efa50)',
    assignDate: 1674662004091,
    releaseDate: 1674662004091,
    isActive: true,
    properties: [
      {
        propertyKey: 'useRedo',
        propertyDescription:
          'When true, enables the student experience to deliver similar problems',
        propertyValue: 'false',
      },
      {
        propertyKey: 'deliveryOfStudentScore',
        propertyDescription: 'Defining score delivery',
        propertyValue: 'false',
      },
      {
        propertyKey: 'assignmentHasExperiment',
        propertyDescription: 'If an assignment has an experiment',
        propertyValue: 'false',
      },
      {
        propertyKey: 'liveTutoringExtPartner',
        propertyDescription:
          'Live Tutoring External Partner (used for url in the tutor)',
        propertyValue: 'Upchieve',
      },
      {
        propertyKey: 'liveTutoringInsteadOfTutoring',
        propertyDescription:
          'Live Tutoring Button replaces the Original Tutoring Button',
        propertyValue: 'false',
      },
      {
        propertyKey: 'testModeStudentReport',
        propertyDescription: 'Enable Test Mode Student Reports',
        propertyValue: 'false',
      },
      {
        propertyKey: 'liveTutoringEnabled',
        propertyDescription:
          'Is the live tutoring button available to students',
        propertyValue: 'true',
      },
      {
        propertyKey: 'personalizedTutoringEnabled',
        propertyDescription: 'enable personalized tutoring',
        propertyValue: 'true',
      },
      {
        propertyKey: 'starredTeacherEnabled',
        propertyDescription: 'automatically subscribed to starred teachers',
        propertyValue: 'true',
      },
      {
        propertyKey: 'teacherAssistEnabled',
        propertyDescription:
          'enable an option to create their own teacherASSIST',
        propertyValue: 'false',
      },
      {
        propertyKey: 'partialCredit',
        propertyDescription: 'Is partial credit enabled',
        propertyValue: 'true',
      },
      {
        propertyKey: 'hintDelay',
        propertyDescription: 'Number of seconds between each hint',
        propertyValue: '0',
      },
      {
        propertyKey: 'partialCreditMaxAttempts',
        propertyDescription:
          'Max attempts and hints used before partial credit score goes to 0',
        propertyValue: '3',
      },
    ],
    skills: [
      'e7eb0025-ebec-49af-8809-45bcf5155871',
      'a4054be3-9228-410f-b345-e934cb056c0e',
      '439e2afb-96e0-465c-9fba-c7ac016f7891',
    ],
  } as object);

  const skillList = server.schema.db.skills;
  const psCeris = ['PSA6N9K', 'PSABSY9D.8551476', 'PSABHT5A', 'PSA1389966'];

  for (let i = 1; i <= 30; i++) {
    server.create('assignmentDefinition', {
      scope: AssignmentScope.PRINCIPAL_GROUP,
      assigneeType: AssigneeType.GROUP,
      xref: `GeneratedAssignment-${i}`,
      assigneeXref: '1',
      ownerXref: 'hLVmvz6zgr6nmyEMW9qt',
      groupContextXref: '1',
      problemSetCeri: psCeris[i % 4],
      lmsProviderType: 'GOOGLE_CLASSROOM',
      name: `
      ${i % 4 == 0 ? 'Exit Ticket --- ' : ''}
      ${i % 5 == 0 ? 'Cool Down --- ' : ''}
      ${i % 6 == 0 ? 'Diagnostic --- ' : ''}
      Lesson ${i}: ${faker.word.adjective() + ' ' + faker.word.noun()}`,
      assignDate: dayjs().subtract(i, 'day').valueOf(),
      releaseDate: dayjs().subtract(i, 'day').valueOf(),
      dueDate: dayjs()
        .subtract(i + 1, 'day')
        .valueOf(),
      skills:
        i < 29
          ? [skillList[i].xref, skillList[i + 1].xref]
          : [skillList[i].xref],
      active: true,
      properties: [
        {
          propertyKey: 'timeLimit',
          propertyDescription: 'Sets assignment time limit in seconds',
          propertyValue: '180',
        },
        {
          propertyKey: 'useRedo',
          propertyDescription:
            'When true, enables the student experience to deliver similar problems',
          propertyValue: 'true',
        },
        {
          propertyKey: 'deliveryOfStudentScore',
          propertyDescription: 'Defining score delivery',
          propertyValue: 'true',
        },
        {
          propertyKey: 'assignmentHasExperiment',
          propertyDescription: 'If an assignment has an experiment',
          propertyValue: 'false',
        },
        {
          propertyKey: 'liveTutoringExtPartner',
          propertyDescription:
            'Live Tutoring External Partner (used for url in the tutor)',
          propertyValue: 'Upchieve',
        },
        {
          propertyKey: 'liveTutoringInsteadOfTutoring',
          propertyDescription:
            'Live Tutoring Button replaces the Original Tutoring Button',
          propertyValue: 'false',
        },
        {
          propertyKey: 'testModeStudentReport',
          propertyDescription: 'Enable Test Mode Student Reports',
          propertyValue: 'false',
        },
        {
          propertyKey: 'liveTutoringEnabled',
          propertyDescription:
            'Is the live tutoring button available to students',
          propertyValue: 'true',
        },
        {
          propertyKey: 'personalizedTutoringEnabled',
          propertyDescription: 'enable personalized tutoring',
          propertyValue: 'true',
        },
        {
          propertyKey: 'starredTeacherEnabled',
          propertyDescription: 'automatically subscribed to starred teachers',
          propertyValue: 'true',
        },
        {
          propertyKey: 'teacherAssistEnabled',
          propertyDescription:
            'enable an option to create their own teacherASSIST',
          propertyValue: 'false',
        },
        {
          propertyKey: 'partialCredit',
          propertyDescription: 'Is partial credit enabled',
          propertyValue: 'true',
        },
        {
          propertyKey: 'hintDelay',
          propertyDescription: 'Number of seconds between each hint',
          propertyValue: '0',
        },
        {
          propertyKey: 'partialCreditMaxAttempts',
          propertyDescription:
            'Max attempts and hints used before partial credit score goes to 0',
          propertyValue: '3',
        },
      ]
        .sort(() => 0.5 - Math.random())
        .slice(0, 2),
    } as object);
  }
}
