
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  getAssignmentDefinition,
  getAssignmentReportData,
  deleteAssignmentProgress,
  getAssignmentAssignees,
} from '@/api/core/assignment.api';
import AssignmentBarChartViewForReport from '@/components/Report/AssignmentBarChartViewForReport.vue';
import AssignmentProgressRow from '@/components/Report/AssignmentProgressRow.vue';
import ProblemSideSheetForReport from '@/components/Report/ProblemSideSheetForReport.vue';
import AssignmentReportTable from '@/components/Report/AssignmentReportTable.vue';
import StandardsReportTable from '@/components/Report/StandardsReportTable.vue';
import {
  CommonWrongAnswer,
  StudentData,
} from '@/domain/ReportData/AssignmentData';
import { AssignmentDefinition } from '@/domain/Assignment';
import { ProblemDefinition } from '@/domain/Problem';
import { User } from '@/domain/User';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import SkillBuilderTable from '@/components/Report/SkillBuilderTable.vue';
import {
  AssignmentReportType,
  EventType,
  trackMixpanel,
} from '@/plugins/mixpanel';
import { isEmpty, isEqual } from 'lodash';
import {
  ITutorStrategy,
  InstructionalRecommendation,
  StudentResponseFeedback,
  TutorStrategyType,
} from '@/domain/Tutoring';
import { isSkillBuilder } from '@/utils/problemSet.util';
import {
  ProblemForReport,
  ProblemStatsForReport,
  getProblemStatsForReport,
  getProblemsForReport,
} from '@/utils/report.util';
import { RETURN_TEXT, RETURN_URL } from '@/domain/PageParams';

enum ReportTab {
  PROBLEMS = 'PROBLEMS',
  STANDARDS = 'STANDARDS',
}

export interface CWAF extends StudentResponseFeedback {
  percent: number;
}

@Component({
  components: {
    AssignmentBarChartViewForReport,
    AssignmentProgressRow,
    AssignmentReportTable,
    ProblemSideSheetForReport,
    StandardsReportTable,
    SkillBuilderTable,
  },
})
export default class ReportLandingPage extends Vue {
  assignment: AssignmentDefinition | null = null;
  assignees: User[] = [];
  reportData: StudentData | null = null;

  tabs = [ReportTab.PROBLEMS, ReportTab.STANDARDS];
  tsTypes = [
    TutorStrategyType.INSTRUCTIONAL_RECOMMENDATIONS,
    TutorStrategyType.STUDENT_RESPONSE_FEEDBACK,
  ];

  ReportTab = ReportTab;
  tab = ReportTab.PROBLEMS;

  problemSideSheet = false;
  problemSideSheetTab = 0;
  selectedIndex: number | null = null;

  reportReady = false;

  get returnUrl(): string | undefined {
    return this.$route.query[RETURN_URL] as string;
  }

  get returnText(): string | undefined {
    return this.$route.query[RETURN_TEXT] as string;
  }

  get problemSetMap(): Record<string, ProblemSetDefinition> {
    return this.$store.state.content.problemSetMap;
  }

  get problemMap(): Record<string, ProblemDefinition> {
    return this.$store.state.content.problemMap;
  }

  get targetTutoringMap(): Record<string, ITutorStrategy[]> {
    return this.$store.getters['content/targetToTutorStrategiesMap'];
  }

  get redoMap(): Record<string, string[]> {
    return this.$store.state.content.redoMap;
  }

  get problemSetAssigned(): ProblemSetDefinition | undefined {
    return this.assignment
      ? this.problemSetMap[this.assignment.problemSetCeri]
      : undefined;
  }

  get timeLimit(): number | undefined {
    return this.assignment?.settings?.timeLimit;
  }

  get timeLimitInMinutes(): number | undefined {
    return this.timeLimit ? this.timeLimit / 60 : undefined;
  }

  get hasRedo(): boolean {
    return this.assignment?.settings?.useRedo === true;
  }

  get isSkillBuilder(): boolean {
    return this.problemSetAssigned
      ? isSkillBuilder(this.problemSetAssigned)
      : false;
  }

  get previewLink(): string {
    return `${process.env.VUE_APP_STUDENT_WORKBENCH_URL}/preview/assignment/${this.assignment?.xref}`;
  }

  get IRsMap(): Record<string, InstructionalRecommendation[]> {
    const xrefToIRsMap: Record<string, InstructionalRecommendation[]> = {};
    for (const xref in this.targetTutoringMap) {
      const tses = this.targetTutoringMap[xref] ?? [];
      const irs = tses.filter(
        (ts) =>
          ts.tutorStrategyType ==
          TutorStrategyType.INSTRUCTIONAL_RECOMMENDATIONS
      ) as InstructionalRecommendation[];
      xrefToIRsMap[xref] = irs;
    }
    return xrefToIRsMap;
  }

  get CWAFsMap(): Record<string, CWAF[]> {
    const xrefToCWAFsMap: Record<string, CWAF[]> = {};
    for (const xref in this.targetTutoringMap) {
      const tses = this.targetTutoringMap[xref] ?? [];
      const srfs = tses.filter(
        (ts) =>
          ts.tutorStrategyType == TutorStrategyType.STUDENT_RESPONSE_FEEDBACK
      ) as StudentResponseFeedback[];
      //  Only include if CWA is applicable, found in one or more student responses.
      const cwas: CommonWrongAnswer[] = this.problemStatsMap[xref]?.cwas ?? [];
      const cwafs: CWAF[] = [];
      for (const srf of srfs) {
        const response = srf.answerPartsToResponses;
        const stats = cwas.find((cwa) => isEqual(cwa.answer, response));
        if (stats) {
          cwafs.push({ ...srf, percent: stats.percent });
        }
      }
      xrefToCWAFsMap[xref] = cwafs;
    }
    return xrefToCWAFsMap;
  }

  // FIXME: Based on the fact that a single Problem will ever appear ONCE in the Problem Set assigned,
  // including redo?
  get problemStatsMap(): Record<string, ProblemStatsForReport> {
    return this.reportData ? getProblemStatsForReport(this.reportData) : {};
  }

  // Ordered Problems, including redos if applicable.
  get problems(): ProblemForReport[] {
    if (this.problemSetAssigned) {
      return getProblemsForReport(
        this.problemSetAssigned.xref,
        this.problemSetMap,
        this.problemMap,
        this.problemStatsMap,
        this.redoMap
      );
    } else {
      return [];
    }
  }

  // FIXME: CANNOT generate header labels for BOTH chart and table. For some reason, we have
  // different labels on each.

  /////////////
  // Methods //
  /////////////

  openProblemSideSheet(xref: string, ir = false): void {
    // Set selected index.
    const found = this.problems.findIndex((problem) => problem.xref === xref);
    if (found != -1) {
      this.selectedIndex = found;
      // Open problem side sheet.
      this.problemSideSheet = true;
      if (ir) {
        this.problemSideSheetTab = 1;
        this.trackOpenProblemSideSheetIR(xref);
      } else {
        this.problemSideSheetTab = 0;
        this.trackOpenProblemSideSheet(xref);
      }
    }
  }

  setPreviousProblem(): void {
    // Decrement selected index by 1.
    if (this.selectedIndex != null && this.selectedIndex > 0) {
      this.selectedIndex -= 1;
    }
  }

  setNextProblem(): void {
    // Increment selected index by 1.
    if (
      this.selectedIndex != null &&
      this.selectedIndex < this.problems.length - 1
    ) {
      this.selectedIndex += 1;
    }
  }

  async deleteStudentProgress(xref: string): Promise<void> {
    if (this.assignment) {
      await deleteAssignmentProgress(this.assignment.xref, xref);
      this.reportReady = false;
      const reportData = await getAssignmentReportData(this.assignment.xref);
      if (!isEmpty(reportData)) {
        this.reportData = reportData;
      } else {
        this.reportData = null;
      }
      this.reportReady = true;
      this.trackDeleteStudentProgress(xref);
    }
  }

  async created(): Promise<void> {
    const assignmentXref = this.$route.params.xref;
    this.reportReady = false;
    // Download the Assignment.
    const assignmentPromise = getAssignmentDefinition(assignmentXref, {
      details: true,
    }).then((assignment) => {
      this.assignment = assignment;
      // Download the Problem Set.
      const problemSetPromise = this.$store
        .dispatch('content/getProblemSetTree', {
          xref: assignment.problemSetCeri,
        })
        .then((ps) => {
          if (this.hasRedo) {
            this.$store.dispatch('content/getProblemSetRedos', {
              xref: ps.xref,
              tsParams: { types: this.tsTypes },
            });
          }
          this.$store.dispatch('content/getProblemSetTutorStrategies', {
            xref: ps.xref,
            filterParams: { types: this.tsTypes },
          });
        });
      const assigneePromise = getAssignmentAssignees(assignment.xref)
        .then((assignees: User[]) => {
          this.assignees = assignees;
        })
        .catch(() => {
          // No assignees found.
          this.assignees = [];
        });
      return Promise.all([problemSetPromise, assigneePromise]);
    });
    // Download assignment report data.
    const dataPromise = getAssignmentReportData(assignmentXref)
      .then((reportData) => {
        if (reportData && !isEmpty(reportData)) {
          this.reportData = reportData;
        } else {
          this.reportData = null;
        }
      })
      .catch(() => {
        this.reportData = null;
      });
    try {
      await Promise.all([assignmentPromise, dataPromise]);
      this.reportReady = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      error.handleGlobally && error.handleGlobally();
    }
    this.reportLoaded(this.hasRedo);
  }

  selectTab(tab: ReportTab): void {
    this.tab = tab;
    this.trackTabClick(tab);
  }

  //////////////
  // Mixpanel //
  //////////////

  @Watch('problemSideSheet')
  trackCloseProblemSideSheet(): void {
    if (!this.problemSideSheet) {
      let reportType = '';
      if (this.isSkillBuilder) {
        reportType = AssignmentReportType.masteryReport;
      } else {
        reportType = AssignmentReportType.assignmentReport;
      }
      trackMixpanel(EventType.assignmentReportCloseProblemSideSheet, {
        assignmentXref: this.assignment?.xref,
        reportType: reportType,
      });
    }
  }

  reportLoaded(redoStatus: boolean): void {
    let reportType = '';
    if (this.isSkillBuilder) {
      reportType = AssignmentReportType.masteryReport;
    } else {
      reportType = AssignmentReportType.assignmentReport;
    }
    trackMixpanel(EventType.reportViewed, {
      assignmentXref: this.assignment?.xref,
      reportType: reportType,
      redoStatus: redoStatus,
    });
  }

  trackOpenProblemSideSheet(problemXref: string): void {
    let reportType = '';
    if (this.isSkillBuilder) {
      reportType = AssignmentReportType.masteryReport;
    } else {
      reportType = AssignmentReportType.assignmentReport;
    }
    trackMixpanel(EventType.assignmentReportOpenProblemSideSheet, {
      assignmentXref: this.assignment?.xref,
      problemCode: problemXref,
      reportType: reportType,
    });
  }

  trackOpenProblemSideSheetIR(problemXref: string): void {
    let reportType = '';
    if (this.isSkillBuilder) {
      reportType = AssignmentReportType.masteryReport;
    } else {
      reportType = AssignmentReportType.assignmentReport;
    }
    trackMixpanel(EventType.assignmentReportOpenProblemSideSheetIR, {
      assignmentXref: this.assignment?.xref,
      problemCode: problemXref,
      reportType: reportType,
    });
  }

  trackDeleteStudentProgress(deletedStudentXref: string): void {
    let reportType = '';
    if (this.isSkillBuilder) {
      reportType = AssignmentReportType.masteryReport;
    } else {
      reportType = AssignmentReportType.assignmentReport;
    }
    trackMixpanel(EventType.assignmentReportDeleteStudentProgress, {
      assignmentXref: this.assignment?.xref,
      studentXref: deletedStudentXref,
      reportType: reportType,
    });
  }

  trackPreviewAsStudent(): void {
    let reportType = '';
    if (this.isSkillBuilder) {
      reportType = AssignmentReportType.masteryReport;
    } else {
      reportType = AssignmentReportType.assignmentReport;
    }
    trackMixpanel(EventType.assignmentReportPreviewAsStudent, {
      assignmentXref: this.assignment?.xref,
      reportType: reportType,
    });
  }

  trackBackToProblemsView(): void {
    let reportType = '';
    if (this.isSkillBuilder) {
      reportType = AssignmentReportType.masteryReport;
    } else {
      reportType = AssignmentReportType.assignmentReport;
    }
    trackMixpanel(EventType.assignmentReportSwitchProblemsView, {
      assignmentXref: this.assignment?.xref,
      reportType: reportType,
    });
  }

  trackTabClick(tab): void {
    if (tab === 'PROBLEMS') {
      trackMixpanel(EventType.assignmentReportSwitchProblemsView, {
        assignmentXref: this.assignment?.xref,
      });
    }
  }
}
