
import {
  ProblemLog,
  StudentData,
  StudentLog,
} from '@/domain/ReportData/AssignmentData';
import { User } from '@/domain/User';
import { appendGlobalHeaderOptions } from '@/utils/dataTables.utils';
import { sortBySortableUserName } from '@/utils/table.utils';
import dayjs from 'dayjs';
import { orderBy, shuffle } from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import DeleteStudentProgressDialog from './DeleteStudentProgressDialog.vue';
import { LmsProviderType } from '@/domain/LmsProviderType';
import SkillBuilderDialog from './SkillBuilderDialog.vue';
import { calculatePausedTime } from '@/utils/calculatePausedTime.util';
import { AssignmentDefinition } from '@/domain/Assignment';
import { IProblemSetAction } from '@/domain/Action';
import { uploadAssignmentScores } from '@/api/core/assignment.api';
import { downloadCsvData } from '@/utils/csv.util';
import { getProblemAnswer } from '@/utils/report.util';
import { ProblemDefinition } from '@/domain/Problem';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';

interface LocalStudentData extends StudentData {
  timePaused: number;
}

interface SkillBuilderTableRow {
  student: User;
  studentLog?: StudentLog;
  studentStats?: LocalStudentData;
  sortedProblemLogs?: ProblemLog[][];
  sortedProblemSetActions?: IProblemSetAction[][];
  problemSetLimitExceededActions?: boolean[];
  completed: boolean;
}

interface Totals {
  correct: number;
  incorrect: number;
}

@Component({
  components: { SkillBuilderDialog, DeleteStudentProgressDialog },
})
export default class SkillBuilderTable extends Vue {
  @Prop({ required: true }) assignment: AssignmentDefinition;
  @Prop({ default: null }) reportData: StudentData | null;
  @Prop({ default: () => [] }) assignees: User[];

  hideNames = false;
  dailyLimitReached = false;
  hideTimes = false;

  uploadDialog = false;
  uploadingScores = false;

  showScoringDialog = false;

  studentToDeleteProgress: User | null = null;
  showDeleteProgressDialog = false;

  get lmsProviderType(): LmsProviderType | undefined {
    return this.assignment.lmsProviderType;
  }
  get timeLimit(): number | undefined {
    return this.assignment?.settings?.timeLimit;
  }

  get timeLimitInMinutes(): number | undefined {
    return this.timeLimit ? this.timeLimit / 60 : undefined;
  }

  get assigneeXrefToStudentLogMap(): Map<string, StudentLog> {
    const res = new Map();
    if (this.reportData) {
      for (const studentLog of this.reportData.studentLogs) {
        res.set(studentLog.studentXref, studentLog);
      }
    }
    return res;
  }

  get assigneeXrefToStudentStatsMap(): Map<string, LocalStudentData> {
    const res = new Map();
    if (this.reportData && this.reportData.summaryStatsAll) {
      for (const studentStats of this.reportData.summaryStatsAll.studentStats) {
        res.set(studentStats.studentXref, { ...studentStats });
      }
    }
    return res;
  }

  get prependStaticHeaders(): Array<DataTableHeader> {
    return [
      {
        text: 'Student Name/Problem',
        value: 'student',
        align: 'start',
        class: [
          'text-no-wrap',
          'sticky-row',
          'sticky-row-1',
          'text-subtitle-2',
        ],
        sortable: true,
        sort: sortBySortableUserName,
      },
      {
        text: 'Skill Builder Completion',
        value: 'skillBuilderCompletion',
        align: 'start',
        class: [
          'text-no-wrap',
          'sticky-row',
          'sticky-row-1',
          'text-subtitle-2',
        ],
        sortable: false,
      },
      {
        text: 'Paused Time',
        value: 'pausedTime',
        align: 'start',
        class: [
          'text-no-wrap',
          'sticky-row',
          'sticky-row-1',
          'text-subtitle-2',
        ],
        sortable: false,
      },
      {
        text: 'Total Time',
        value: 'totalTime',
        align: 'start',
        class: [
          'text-no-wrap',
          'sticky-row',
          'sticky-row-1',
          'text-subtitle-2',
        ],
        sortable: false,
      },
    ];
  }

  filterHeaders(header: DataTableHeader): boolean {
    switch (header.value) {
      case 'pausedTime':
        return !this.hideTimes && this.timeLimitInMinutes > 0;
      case 'totalTime':
        return !this.hideTimes;
      default:
        return true;
    }
  }

  get headers(): Array<DataTableHeader> {
    return [
      ...this.prependStaticHeaders
        .filter(this.filterHeaders)
        .map(appendGlobalHeaderOptions),
    ];
  }

  get rows(): Array<SkillBuilderTableRow> {
    const rows = this.generateRows();

    this.getProblemSetLimitExceededActionsForRows(rows);

    // Shuffle student rows if anonymizing
    if (this.hideNames) {
      return shuffle(rows);
      // Otherwise sort alphabetically by lastname, firstname
    } else {
      return orderBy(
        rows,
        [
          (row) => {
            return row.student.lastName;
          },
          (row) => {
            return row.student.firstName;
          },
        ],
        ['asc', 'asc']
      );
    }
  }

  getTimeSpent(ms: number): string {
    return dayjs.duration(ms).format('HH:mm:ss');
  }

  generateRows(): Array<SkillBuilderTableRow> {
    const rows: Array<SkillBuilderTableRow> = [];

    this.assignees.forEach((assignee) => {
      const studentLog = this.assigneeXrefToStudentLogMap.get(assignee.xref);
      let completed = false;

      if (studentLog) {
        completed = !!studentLog.asEndTime;
      }

      rows.push({
        completed,
        studentLog,
        student: assignee,
        studentStats: this.assigneeXrefToStudentStatsMap.get(assignee.xref),
      });
    });

    return rows;
  }

  uploadScoresToLms(): void {
    const assignmentXref = this.reportData?.contentInfo.xref;
    if (assignmentXref && this.lmsProviderType) {
      this.uploadingScores = true;
      uploadAssignmentScores(assignmentXref, this.lmsProviderType)
        .then(() => {
          this.uploadDialog = false;
          this.trackReportUpload();
          this.$notify(
            `Uploaded scores to ${this.lmsProviderType?.displayName} successfully.`
          );
        })
        .catch((error: any) => {
          if (error.statusCode === 417) {
            // Needs login
            error.handleGlobally &&
              error
                .handleGlobally()
                .then(() => {
                  this.$notify('Authorization updated. Please try again.');
                })
                .catch(() => {
                  // Something wrong going to the LP
                  this.$notify(
                    'Failed authentication and authorization in login portal.'
                  );
                });
          } else {
            // Cannot be handled by authentication
            this.$notify(
              `Failed to upload scores to ${this.lmsProviderType?.displayName}.` +
                ' This will occur if no students have completed the assignment.' +
                ' Please make sure at least one student has scores to upload and try again.'
            );
          }
        })
        .finally(() => {
          this.uploadingScores = false;
        });
    }
  }

  created(): void {
    this.hideNames =
      this.$store.state.auth.user.settings.anonymizeReportsByDefault || false;
  }

  sortProblemLogsPerDay(studentLog: StudentLog): Map<string, ProblemLog[]> {
    const sortAscending = (a: ProblemLog, b: ProblemLog): number =>
      a.startTime > b.startTime ? 1 : -1;

    const res = new Map<string, ProblemLog[]>();

    const logs = studentLog.problemLogAndActions
      ?.map((log) => log.prLog)
      .sort(sortAscending);

    if (logs) {
      logs.forEach((log) => {
        if (log.endTime) {
          const formattedStartTime = dayjs(log.startTime).format('YYYY-MM-DD');
          if (res.has(formattedStartTime)) {
            res.set(formattedStartTime, [
              ...(res.get(formattedStartTime) as ProblemLog[]),
              log,
            ]);
          } else {
            res.set(formattedStartTime, [log]);
          }
        }
      });
    }

    return res;
  }

  showStudentDeleteProgressDialog(studentXref: string): void {
    this.studentToDeleteProgress =
      this.assignees.find((assignee) => assignee.xref === studentXref) || null;

    if (this.studentToDeleteProgress) {
      this.showDeleteProgressDialog = true;
    }
  }

  deleteStudentProgress(): void {
    if (this.studentToDeleteProgress) {
      this.$emit('deleteStudentProgress', this.studentToDeleteProgress?.xref);
      this.studentToDeleteProgress = null;
    }
    //close dialog
    this.showDeleteProgressDialog = false;
  }

  sortProblemSetActionsPerDay(
    studentActions: IProblemSetAction[]
  ): Map<string, IProblemSetAction[]> {
    const res = new Map<string, IProblemSetAction[]>();

    studentActions.forEach((action) => {
      const formattedStartTime = dayjs(action.timestamp).format('YYYY-MM-DD');
      if (res.has(formattedStartTime)) {
        res.set(formattedStartTime, [
          ...(res.get(formattedStartTime) as IProblemSetAction[]),
          action,
        ]);
      } else {
        res.set(formattedStartTime, [action]);
      }
    });

    return res;
  }

  navigateToStudentDetailsReport(studentXref: string): void {
    this.$router.push({
      name: 'studentDetailsPage',
      params: {
        studentXref: studentXref,
      },
      query: this.$route.query,
    });
  }

  getProblemOrder(children: string[]): string[] {
    // Get the index of all the child ceris
    const indexMap: { [key: string]: number } = {};
    children.forEach((childXref: string, index: number) => {
      indexMap[childXref] = index;
    });

    // Make an array of the ceris we actually need and put them in order
    return (
      this.reportData?.prAllStats
        ?.map((prStats) => {
          return prStats.prCeri;
        })
        .sort((a: string, b: string) => indexMap[a] - indexMap[b]) || []
    );
  }

  getCsvDefaultRows() {
    return {
      headerRow: [
        '',
        'Score',
        'Total Questions Asked',
        'Total Correct',
        'Total Incorrect',
      ],
      classTotalCorrectRow: ['Class Total Correct', '', '', '', ''],
      classTotalIncorrectRow: ['Class Total Incorrect', '', '', '', ''],
      correctAnswersRow: ['Correct Answers', '', '', '', ''],
      studentNameRow: ['Student Name'],
    };
  }

  generateCsvData(): Array<string[]> {
    const csvRows: Array<string[]> = [];
    const classTotals: { [key: string]: Totals } = {};
    let studentRows: Array<string[]> = [];

    // Get the order of the columns and remove unneeded columns
    const problemSet =
      this.$store.state.content.problemSetMap[this.assignment.problemSetCeri];
    const problemCeris = problemSet
      ? this.getProblemOrder(problemSet.children)
      : [];

    // Get the problems from the store
    const problemMap = this.$store.state.content.problemMap;

    // Create the default rows with the static text
    const {
      headerRow,
      classTotalCorrectRow,
      classTotalIncorrectRow,
      correctAnswersRow,
      studentNameRow,
    } = this.getCsvDefaultRows();

    // Go through the problem ceri's that are in order and add data so it fits the column
    problemCeris.forEach((prCeri: string) => {
      // Add header for each problem
      const problem: ProblemDefinition = problemMap[prCeri];
      headerRow.push(prCeri);

      // Add array of problem answers for the problem
      const correctAnswers: string[] = [];
      problem.answersSDK3?.members?.forEach((member) => {
        member.answerValues?.forEach((answer) => {
          if (answer.isCorrect) {
            correctAnswers.push(getProblemAnswer(answer.value));
          }
        });
      });

      correctAnswersRow.push(correctAnswers?.join(','));
    });

    // Collect student data
    this.rows.forEach((row) => {
      const studentRow = [];
      const studentTotals = { correct: 0, incorrect: 0 };

      // First column is the student's name
      studentRow.push(row.student.displayName);

      // Second column is the total score the student got
      const studentStat = this.reportData?.summaryStatsAll?.studentStats.find(
        (stats) => {
          return stats.studentXref == row.student.xref;
        }
      );

      studentRow.push(
        studentStat?.score ? `${Math.round(studentStat.score * 100)}%` : ''
      );

      // Make an object of the log array
      const logs: { [key: string]: ProblemLog } = {};
      row.sortedProblemLogs?.forEach((sortedLogs) => {
        sortedLogs.forEach((log) => {
          logs[log.prCeri] = log;
        });
      });

      // Third through Fifth columns are question totals
      // After that are the problems and the scores on each problem
      problemCeris.forEach((ceri) => {
        const problemLog = logs[ceri];

        if (problemLog && problemLog.continuousScore) {
          studentRow.push(`${Math.round(problemLog.continuousScore * 100)}%`);
          if (!classTotals[ceri]) {
            classTotals[ceri] = { correct: 0, incorrect: 0 };
          }

          if (problemLog.continuousScore === 1) {
            classTotals[ceri]['correct']++;
            studentTotals['correct']++;
          } else {
            classTotals[ceri]['incorrect']++;
            studentTotals['incorrect']++;
          }
        } else {
          studentRow.push('');
        }
      });

      // Add the totals to the front of the row.
      // Insert at index 2 because 0 is the name and 1 is the total score
      const combined = studentTotals.incorrect + studentTotals.correct;
      const incorrect = studentTotals.incorrect;
      const correct = studentTotals.correct;

      studentRow.splice(2, 0, `${incorrect ? incorrect : 0}`);
      studentRow.splice(2, 0, `${correct ? correct : 0}`);
      studentRow.splice(2, 0, `${combined ? combined : 0}`);

      studentRows.push(studentRow);
    });

    // Add in the totals for each problem
    problemCeris.forEach((ceri) => {
      const totals = classTotals[ceri];
      if (totals) {
        classTotalCorrectRow.push(`${totals.correct}`);
        classTotalIncorrectRow.push(`${totals.incorrect}`);
      }
    });

    // Assemble all the data into array of arrays
    csvRows.push([this.assignment.name]);
    csvRows.push(headerRow);
    csvRows.push(classTotalCorrectRow);
    csvRows.push(classTotalIncorrectRow);
    csvRows.push(correctAnswersRow);
    csvRows.push(studentNameRow);

    // Sort the student rows by name so it's not all random
    studentRows.sort((a, b) => {
      const nameA = (a[0].split(' ')[0] || '').toLowerCase();
      const nameB = (b[0].split(' ')[0] || '').toLowerCase();
      return nameA.localeCompare(nameB);
    });

    return csvRows.concat(studentRows);
  }

  downloadCsv(): void {
    const csvData = this.generateCsvData();

    downloadCsvData(csvData, this.assignment.xref);
    trackMixpanel(EventType.masteryReportCSVDownload, {
      assignmentXref: this.assignment.xref,
    });
  }

  private getProblemSetLimitExceededActionsForRows(
    rows: Array<SkillBuilderTableRow>
  ) {
    rows.forEach((row) => {
      row.sortedProblemLogs = [];
      row.sortedProblemSetActions = [];
      row.problemSetLimitExceededActions = [];

      if (row.studentLog) {
        const sortedProblemLogs = this.sortProblemLogsPerDay(row.studentLog);
        sortedProblemLogs.forEach((log) => row.sortedProblemLogs?.push(log));

        const sortedProblemSetActions = this.sortProblemSetActionsPerDay(
          row.studentLog.psActions || []
        );
        sortedProblemSetActions.forEach((action) =>
          row.sortedProblemSetActions?.push(action)
        );

        // For each of the output row's timestamps, determine if a problem limit exceeded action exists
        const timestamps = Array.from(sortedProblemLogs.keys());
        timestamps.forEach((timestamp) => {
          let value = false;

          const actions = sortedProblemSetActions.get(timestamp);
          if (actions) {
            const problemSetLimitExceededAction = actions.find(
              (action) =>
                action.actionType === 'PROBLEM_SET_LIMIT_EXCEEDED_ACTION'
            );

            if (problemSetLimitExceededAction) {
              value = true;
            }
          }

          row.problemSetLimitExceededActions?.push(value);
        });

        if (row.studentStats) {
          row.studentStats.timePaused = calculatePausedTime(row.studentLog);
        }
      }
    });
  }

  @Watch('showScoringDialog')
  onShowScoringDialogChange(newVal: boolean, oldVal: boolean) {
    if (newVal !== oldVal) {
      trackMixpanel(EventType.masteryReportScoringDialog, {
        assignmentXref: this.assignment.xref,
        showDialog: newVal,
      });
    }
  }

  trackReportUpload(): void {
    trackMixpanel(EventType.masteryReportUpload, {
      assignmentXref: this.assignment.xref,
    });
  }
}
