
import { Vue, Component, Prop } from 'vue-property-decorator';

export enum SettingsGroup {
  TEST_MODE,
  TIME_LIMIT,
  REDO,
  SCORE_DELIVERY,
  CAIT,
}

@Component
export default class SettingsInformationDialog extends Vue {
  @Prop({ required: true }) value: boolean;
  @Prop() settingsGroup: SettingsGroup;

  SettingsGroup = SettingsGroup;

  get showDialog(): boolean {
    return this.value;
  }

  set showDialog(value: boolean) {
    this.$emit('input', value);
  }

  get dialogTitle(): string {
    return this.settingsGroup === SettingsGroup.REDO
      ? 'Redo Explanation'
      : 'Warning';
  }
}
