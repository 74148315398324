
import { Component, Watch, Vue } from 'vue-property-decorator';
import { ProblemDefinition, ProblemTypeSDK3 } from '@/domain/Problem';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import ProblemViewForFind from '@/components/base/ProblemViewForFind.vue';
import AssignFab from '@/components/FindProblems/AssignFab.vue';
import { CurriculumDefinition } from '@/domain/Curriculum';
import CurriculaFilter from './SearchFiltering/CurriculaFilter.vue';
import ProblemTypeFilter from './SearchFiltering/ProblemTypeFilter.vue';
import { getRedosMap } from '@/api/core/content.api';
import ScrollObserver from '@/components/base/ScrollObserver.vue';
import { ProblemSetNode } from '@/utils/problemSet.util';

import { EventType, trackMixpanel } from '@/plugins/mixpanel';
import ProblemSetViewForFind from '@/components/base/ProblemSetViewForFind.vue';
import { ContentType } from '@/domain/Content';
export interface SelectedFilter {
  curricula: string[];
  problemType: ProblemTypeSDK3[];
}

@Component({
  components: {
    ProblemViewForFind,
    ProblemSetViewForFind,
    AssignFab,
    CurriculaFilter,
    ProblemTypeFilter,
    ScrollObserver,
  },
})
export default class SearchResultsProblems extends Vue {
  selectedProblems: Record<string, boolean> = {};
  selectedProblemSets: Record<string, ProblemSetNode[]> = {};

  deselectAllProblems = false;
  redoEnabled = this.$store.state.auth.user?.settings?.redoEnabled ?? false;

  selectedFilter: SelectedFilter = { curricula: [], problemType: [] };
  redoMap: Record<string, (ProblemDefinition | ProblemSetDefinition)[]> = {};

  ContentType = ContentType;

  get nextPageToken(): string | null {
    return this.$store.state.search.nextPageToken;
  }

  /**
   * Converts the Map of Curricula in store into flat list of objects
   * Each entry is either a { header: string } shaped object, or Curriculum
   * (A header precedes each block of curricula of a given type e.g. State Test)
   */
  get curriculaFilters(): Array<CurriculumDefinition | { header: string }> {
    let res: Array<CurriculumDefinition | { header: string }> = [];
    const curriculaTypeMap =
      this.$store.getters['curricula/getTypeToCurriculaMap'];

    for (let [type, curricula] of curriculaTypeMap) {
      res.push({ header: type });
      res = [...res, ...curricula];
    }
    return res;
  }

  get searchResults(): (ProblemSetDefinition | ProblemDefinition)[] {
    return this.$store.state.search.combinedSearchResults;
  }

  get resultCeris() {
    const xrefs = new Set<string>();

    if (this.searchResults.length) {
      // Go through the problem sets and make a set of which problems are children
      this.searchResults.forEach((result) => {
        xrefs.add(result.xref);

        if (result.contentType === ContentType.PROBLEM_SET && result.children) {
          result.children.forEach((child) => {
            xrefs.add(child);
          });
        }
      });
    }

    return xrefs;
  }

  get numProblemsTotal(): number {
    let total = 0;

    this.searchResults.forEach((result) => {
      if (result.contentType === ContentType.PROBLEM_SET && result.children) {
        total += result.children.length;
      } else if (result.contentType === ContentType.PROBLEM) {
        total++;
      }
    });

    return total;
  }

  get selectedTree(): ProblemSetNode[] {
    const selected: ProblemSetNode[] = [];

    Object.entries(this.selectedProblems)
      .filter(([_xref, isSelected]) => isSelected)
      .forEach(([xref]) => {
        selected.push({
          xref,
        });
      });

    Object.entries(this.selectedProblemSets)
      .filter(([_xref, children]) => children.length)
      .forEach(([xref, children]) => {
        selected.push({
          xref,
          children,
        });
      });

    return selected;
  }

  get downloading(): boolean {
    return this.$store.state.search.isDownloadingProblems;
  }

  // ================================================================
  // NOTE: Code below was for a criteria in TXP-740 where search results would
  //       display filtered results. It was judged unnecessary for the time being,
  //       and likely to cause unwanted/unintended issues. Commented out for future
  //       updates were we may want to revisit the functionality per Cindy. 7/10/2024
  // ================================================================
  // get problemSets(): ProblemSetDefinition[] {
  // const problemSetMap = this.$store.state.content.problemSetMap;
  // const problemSetSearchResults = this.$store.state.search.problemSets;

  // const allResultProblemSets = problemSetSearchResults.map(
  //   (psXref: string) => {
  //     return problemSetMap[psXref];
  //   }
  // );

  // // Group problem sets by unique children combinations
  // let groupMap = new Map<string, ProblemSetDefinition[]>();

  // allResultProblemSets.forEach((problemSet: ProblemSetDefinition) => {
  //   if (problemSet && problemSet.children) {
  //     let sortedChildren = problemSet.children.slice().sort();
  //     let key = sortedChildren.join('-');

  //     if (!groupMap.has(key)) {
  //       groupMap.set(key, []);
  //     }

  //     groupMap.get(key)!.push(problemSet);
  //   }
  // });

  // // Remove the problem sets we don't want. keep ps with most children, if matching lengths, keep both
  // let problemSetsToKeep: ProblemSetDefinition[] = [];

  // groupMap.forEach((group) => {
  //   // Find the object(s) with the highest number of children in this group
  //   let maxChildrenLength = Math.max(
  //     ...group.map((obj) => obj.children.length)
  //   );

  //   // Filter objects with the highest children length or all if lengths are tied
  //   let problemSetsWithMaxChildren = group.filter(
  //     (ps) => ps.children.length === maxChildrenLength
  //   );

  //   problemSetsToKeep.push(...problemSetsWithMaxChildren);
  // });
  // ================================================================

  // When user selects different skill, clear selected problems
  @Watch('$store.state.skillList.selectedSkillXref')
  onSelectedSkillXrefChange(): void {
    this.deselectAllProblems = true;
  }

  @Watch('selectedFilter', { deep: true })
  onSelectedFilterChange(): void {
    this.$emit('selectedFilterChanged', this.selectedFilter);
  }

  selectedCurriculaChanged(selectedCurricula: string[]) {
    this.selectedFilter.curricula = selectedCurricula;
    // Track curricula filter change with Mixpanel
    trackMixpanel(EventType.searchBarSkillCurriculaFilterChanged, {
      selectedCurricula: selectedCurricula,
    });
  }

  selectedProblemTypeChanged(selectedProblemType: ProblemTypeSDK3[]): void {
    this.selectedFilter.problemType = selectedProblemType;
    // Track problem type filter change with Mixpanel
    trackMixpanel(EventType.searchBarSkillProblemTypeFilterChanged, {
      selectedProblemType: selectedProblemType,
    });
  }

  handleLoadMore(): void {
    this.$emit('downloadMoreProblems');
    // Download more problems
    trackMixpanel(EventType.searchBarSkillDownloadProblems);
  }

  checkRedo(): void {
    if (this.redoEnabled && this.searchResults.length) {
      getRedosMap(Array.from(this.resultCeris)).then((redoMap) => {
        this.redoMap = redoMap;
      });
    }
  }

  updateTree(tree: ProblemSetNode[], psXref: string) {
    this.$set(this.selectedProblemSets, psXref, tree);
  }

  @Watch('problems')
  onProblemsChange() {
    this.checkRedo();
  }
  mounted() {
    this.checkRedo();
  }
}
