
import { Component, Vue } from 'vue-property-decorator';
import ActionButtons from '@/components/Settings/ActionButtons.vue';
import TestModeInformationDialog from '@/components/Settings/TestModeInformationDialog.vue';
import { UpdateUserObject, UserSettings } from '@/domain/User';
import StudentScoreDeliveryDialog from '@/components/Settings/StudentScoreDeliveryDialog.vue';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';

@Component({
  components: {
    ActionButtons,
    TestModeInformationDialog,
    StudentScoreDeliveryDialog,
  },
})
export default class FeatureSettings extends Vue {
  dataChanged = false;

  tabs = [
    {
      tabName: 'Test Mode',
      section: 'testMode',
    },
    {
      tabName: 'Student Supports',
      section: 'studentSupports',
    },
    {
      tabName: 'Student Score Delivery',
      section: 'deliveryOfStudentScore',
    },
    // {
    //   tabName: 'JIT Video Support',
    //   section: 'jitvs',
    // },
  ];

  data: UpdateUserObject = {
    settings: {
      testModeStudentReport: this.settings.testModeStudentReport,
      starredTeacherEnabled: this.settings.starredTeacherEnabled,
      deliveryOfStudentScore: this.settings.deliveryOfStudentScore,
      // personalizedTutoringEnabled: this.settings.personalizedTutoringEnabled,
    },
  };

  get settings(): UserSettings {
    return this.$store.getters['auth/getCurrentUser'].settings;
  }

  scrollToSection(sectionId: string): void {
    const section = this.$el.querySelector(`#${sectionId}`);

    section?.scrollIntoView({ behavior: 'smooth' });
  }

  triggerDataChanged(): void {
    this.dataChanged = true;
  }

  discardChanges(): void {
    this.dataChanged = false;

    this.data = {
      settings: {
        testModeStudentReport: this.settings.testModeStudentReport,
        starredTeacherEnabled: this.settings.starredTeacherEnabled,
        deliveryOfStudentScore: this.settings.deliveryOfStudentScore,
        // personalizedTutoringEnabled: this.settings.personalizedTutoringEnabled,
      },
    };
  }

  trackFeatureSettingsSave(): void {
    trackMixpanel(EventType.accountSettingsFeatureSettingsUpdated, {
      testModeStudentReport: this.data.settings.testModeStudentReport,
      starredTeacherEnabled: this.data.settings.starredTeacherEnabled,
      deliveryOfStudentScore: this.data.settings.deliveryOfStudentScore,
    });
  }
}
