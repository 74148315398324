import { IHasPermissions } from './Acls';
import { AssignmentDefinition } from './Assignment';
import { AttributeNames, AttributesMap, IHasAttributes } from './Attributes';
import { GroupType, IGroupHasMembers } from './Group';
import { ProblemSetDefinition } from './ProblemSet';

// FIXME 06/01/2022: We might want to revisit this after content migration. This is probably the initial draft/thoughts of our new FolderDefinition.
export interface HybridFolderDefinition {
  // FIXME: WE do NOT want to be sending IDs across the wire!
  id: number;
  title: string;
  value: string;
  name: string;
  // owner: string;
  position: number;
  // FIXME: WE do NOT want to be sending IDs across the wire!
  parentFolderId: number;
  // Actually we might not want this since we want to separate the definition from its members/children
  // children: number[];
  folderMemberType: string;
  attributes?: FolderAttributes;
}

export interface IFolderMember {
  xref: string;
  memberType: FolderMemberType;
  name?: string;
  createdAt: number;
  updatedAt: number;
}

export type FolderMember =
  | FolderDefinition
  | ProblemSetDefinition
  | AssignmentDefinition;

export interface FolderDefinition
  extends IFolderMember,
    IGroupHasMembers,
    IHasAttributes,
    IHasPermissions {
  xref: string;
  name: string;
  groupType: GroupType.CONTENT_FOLDER | GroupType.ASSIGNMENTS_FOLDER;
  ownerXref: string;
  properties: FolderProperties;
  createdAt: number;
  updatedAt: number;
  attributes?: FolderAttributes;
}

export type FolderAttributes = Pick<
  AttributesMap,
  | AttributeNames.CURRICULUM
  | AttributeNames.ATTRIBUTION
  | AttributeNames.GRADE_LEVEL
  | AttributeNames.INSIGHTS_HUB_LEVEL
>;

// All properties will be returned, including defaults.
export interface FolderProperties {
  // Defaults to null
  HEADER: null | string;
  // Defaults to false
  OPEN_IN_LESSON_PAGE: boolean;
}

export interface FolderStats {
  numFolders: number;
  // Depends on GroupType of Folder.
  numProblemSets?: number;
  numAssignments?: number;
}

export enum FolderMemberType {
  FOLDER = 'FOLDER',
  PUB_PS = 'PUB_PS',
  WIP_PS = 'WIP_PS',
  ASSIGNMENT = 'ASSIGNMENT',
}

export enum UserFolderType {
  MY_PSES = 'MY_PSES',
  MY_PSES_ASSIGNED = 'MY_PSES_ASSIGNED ',
}

export const EXPLORE_CONTENT = '65ac547b-7cbc-436b-b63e-04640290107b';
export const WORKSPACE = '1ffb58f3-00b7-4379-bf64-bad7a0765028';
