
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ProblemSetFileMenu from '@/components/Builder/ProblemSetFileMenu.vue';
import {
  ProblemSetNode,
  getProblemSetStructure,
  isSkillBuilder,
} from '@/utils/problemSet.util';
import ProblemViewForFind from './ProblemViewForFind.vue';
import { isEqual } from 'lodash';
import { ProblemSetDefinition, ProblemSetType } from '@/domain/ProblemSet';
import { ProblemDefinition } from '@/domain/Problem';
import { ContentSaveOperationType } from '@/api/core/content.api';
import { ContentType } from '@/domain/Content';
import { getPartLetter } from '@/utils/problem.util';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';

@Component({
  components: {
    ProblemSetFileMenu,
    ProblemViewForFind,
    ProblemSetViewForFind: () => import('./ProblemSetViewForFind.vue'),
  },
})
export default class ProblemSetViewForFind extends Vue {
  @Prop({ required: false }) problemSet: ProblemSetDefinition;
  @Prop({ default: () => [] }) path: ProblemSetDefinition[];
  @Prop({ default: () => [] }) selectedTree: ProblemSetNode[];
  @Prop({ default: () => ({}) }) redoMap: Record<string, string[]>;
  @Prop({ default: () => ({}) }) teachleyMap: Record<string, boolean>;
  @Prop({ default: true }) enabled: boolean;
  @Prop({ default: false }) isFirst: boolean;
  @Prop({ default: false }) isLast: boolean;

  ContentType = ContentType;

  get pathList(): ProblemSetDefinition[] {
    return [...this.path, this.problemSet];
  }

  get problemSetTree(): ProblemSetNode[] {
    return getProblemSetStructure(this.problemSet, this.problemSetMap);
  }

  get problemSetMap(): Record<string, ProblemSetDefinition> {
    return this.$store.state.content.problemSetMap;
  }

  get problemMap(): Record<string, ProblemDefinition> {
    return this.$store.state.content.problemMap;
  }

  get members(): (ProblemDefinition | ProblemSetDefinition)[] {
    const res = [];
    const children = this.problemSet?.children ?? [];
    for (const [index, child] of children.entries()) {
      let found: ProblemSetDefinition | ProblemDefinition | undefined =
        this.problemSetMap[child] ?? this.problemMap[child];
      if (found) {
        if (
          this.problemSet.problemSetType ===
          ProblemSetType.MULTI_PART_PROBLEM_SET
        ) {
          found = {
            ...found,
            partLetter: getPartLetter(index + 1),
          } as unknown as ProblemDefinition;
        }
        res.push(found);
      }
    }
    return res;
  }

  get selectedChildren(): Record<string, ProblemSetNode> {
    const res: Record<string, ProblemSetNode> = {};
    for (const node of this.selectedTree) {
      res[node.xref] = node;
    }
    return res;
  }

  get isSkillBuilder(): boolean {
    return this.problemSet ? isSkillBuilder(this.problemSet) : false;
  }

  get selectAllProblems(): boolean {
    return isEqual(this.problemSetTree, this.selectedTree);
  }

  set selectAllProblems(val: boolean) {
    if (val) {
      const selectedTree = getProblemSetStructure(
        this.problemSet,
        this.problemSetMap
      );
      this.$emit('selected', selectedTree);
    } else {
      this.$emit('selected', []);
    }
  }

  updateTree(ceri: string, selectedTree?: ProblemSetNode[]): void {
    let updatedTree: ProblemSetNode[] = [];
    // Generate updated subtree, maintaining order.
    for (const member of this.members) {
      const selected = this.selectedChildren[member.xref];
      if (member.xref == ceri) {
        if (member.contentType == ContentType.PROBLEM_SET) {
          if (selectedTree?.length) {
            // Add to selected tree. Otherwise do NOT add (exclude) here.
            updatedTree.push({
              xref: member.xref,
              children: selectedTree,
            });
          }
        } else if (member.contentType == ContentType.PROBLEM) {
          if (!selected) {
            // Add to selected tree. Otherwise do NOT add (exclude) here.
            updatedTree.push({
              xref: member.xref,
            });
          }
        }
      } else if (selected) {
        // Keep current selections in the other subtrees.
        updatedTree.push(selected);
      }
    }
    this.$emit('selected', updatedTree);
  }

  updatePosition(oldIndex: number, newIndex: number): void {
    if (!isEqual(oldIndex, newIndex)) {
      const member = this.members[oldIndex];
      this.$store
        .dispatch('content/moveProblemSetMember', {
          xref: this.problemSet.xref,
          moved: {
            oldIndex,
            newIndex,
          },
          opType: ContentSaveOperationType.PUBLISH,
        })
        .then(({ ceri: xref, failMessages: error }) => {
          if (error) {
            this.$notify(
              `Failed to move member ${member.name} in ${this.problemSet.xref}: ${error}`
            );
          } else if (xref) {
            this.$notify(`Saved changes to ${xref}.`);
            this.$emit('published', xref);
            // Track the move event with Mixpanel
            trackMixpanel(EventType.trackProblemSetMoveProblem, {
              problemSetXref: this.problemSet.xref,
              problemCode: member.xref,
              oldIndex,
              newIndex,
            });
          }
        })
        .catch(() => {
          this.$notify(
            `Something went wrong. Failed to move member ${member.xref} in ${this.problemSet.xref}.`
          );
        });
    }
  }

  removeMember(index: number): void {
    // MUST NOT be an empty Problem Set
    if (this.members.length > 1) {
      const member = this.members[index];
      this.$store
        .dispatch('content/removeProblemSetMembers', {
          xref: this.problemSet.xref,
          members: [member.xref],
          opType: ContentSaveOperationType.PUBLISH,
        })
        .then(({ ceri: xref, failMessages: error }) => {
          if (error) {
            this.$notify(
              `Failed to remove ${member.xref} in ${this.problemSet.xref}: ${error}`
            );
          } else if (xref) {
            this.$notify(`Saved changes to ${xref}.`);
            this.$emit('published', xref);
            // Track the delete event with Mixpanel
            trackMixpanel(EventType.trackProblemSetDeleteProblem, {
              problemSetXref: this.problemSet.xref,
              problemXref: member.xref,
            });
          }
        })
        .catch(() => {
          this.$notify(
            `Something went wrong. Failed to remove ${member.xref} in ${this.problemSet.xref}`
          );
        });
    }
  }

  @Watch('members')
  onMembersChange(
    newValue: (ProblemDefinition | ProblemSetDefinition)[],
    oldValue: (ProblemDefinition | ProblemSetDefinition)[]
  ): void {
    if (this.problemSet && !isEqual(newValue, oldValue)) {
      this.updateTree(this.problemSet.xref, this.selectedTree);
    }
  }

  handleMenuVisibilityChange({
    problemXref,
    showDialog,
  }: {
    problemXref: string;
    showDialog: boolean;
    problemSetXref: string;
  }): void {
    trackMixpanel(EventType.trackProblemMenuVisibility, {
      problemSetXref: this.problemSet.xref,
      problemXref,
      showDialog,
    });
  }

  // Handle preview as student event
  handlePreviewAsStudent({
    problemXref,
  }: {
    problemXref: string;
    problemSetXref: string;
  }): void {
    trackMixpanel(EventType.trackProblemPreviewAsStudent, {
      problemSetXref: this.problemSet.xref,
      problemXref,
    });
  }
}
