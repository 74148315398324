
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { LmsProviderType } from '@/domain/LmsProviderType';
import dayjs from 'dayjs';
import { Class } from '@/domain/Class';
import { ProblemSetDefinition, ProblemSetType } from '@/domain/ProblemSet';
import { AssignmentDefinition } from '@/domain/Assignment';
import { AssignData, createCourseAssignment } from '@/api/core/course.api';
import { ComposedError } from '@/plugins/axios';
import PopupBlockedDialog from '@/components/base/PopupBlockedDialog.vue';
import AssigneeSelector, {
  AssigneeSummary,
} from '@/components/FindProblems/AssigneeSelector.vue';
import SettingsInformationDialog, {
  SettingsGroup,
} from './SettingsInformationDialog.vue';
import { getDeepLinkResponse } from '@/api/core/lti.api';
import ImportSyncDialog from './ImportSyncDialog.vue';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  ProblemSetNode,
  getProblemCountFromStructure,
  getProblemSetTypeDisplayName,
  isSkillBuilder,
} from '@/utils/problemSet.util';
import { isEqual } from 'lodash';
import { addMembersToFolder } from '@/api/core/folders.api';
import { ContentSaveOperationType } from '@/api/core/content.api';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';
import { updateAssignment } from '@/api/core/assignment.api';
import { isWip } from '@/utils/builder.util';
import { EditableAssignmentFields } from '@/api/core/assignment.api';

dayjs.extend(customParseFormat);

export enum Mode {
  ASSIGN = 1,
  EDIT = 2,
  REASSIGN = 3,
}

@Component({
  components: {
    ImportSyncDialog,
    AssigneeSelector,
    PopupBlockedDialog,
    SettingsInformationDialog,
  },
})
export default class AssignDialog extends Vue {
  @Prop() value: boolean;
  @Prop({ default: '' }) defaultName: string;

  @Prop({ default: 'Assign to Class' }) title: string;
  @Prop({ default: () => Mode.ASSIGN }) mode: Mode;

  // Prepopulate dialog
  @Prop() assignment: AssignmentDefinition;
  @Prop() problemSet: ProblemSetDefinition | null;

  @Prop({ default: null }) numTotalProblems: number | null;
  @Prop({ default: () => [] }) selectedTree: ProblemSetNode[];

  // Allows us access to the enum in the template.
  Mode = Mode;
  SettingsGroup = SettingsGroup;

  getProblemSetTypeDisplayName = getProblemSetTypeDisplayName;

  // Loading states
  assigning = false;
  updating = false;

  assignmentMade = false;

  problemOrder = [
    ProblemSetType.LINEAR_COMPLETE_ALL,
    ProblemSetType.RANDOM_COMPLETE_ALL,
    ProblemSetType.STUDENT_CHOICE,
  ];

  assignmentName = '';
  chosenLms = 0;
  chosenClasses: string[] = [];
  testMode = false;
  hasTimeLimit = false;
  timeLimit = 1;
  // Default to User setting. Can be toggled at assign time.
  deliveryOfStudentScore =
    this.$store.state.auth.user?.settings?.deliveryOfStudentScore ?? false;

  // User setting determines who gets to see this option. Can be toggled at assign time.
  useRedo = false;
  useCait = false;

  chosenProblemOrder = ProblemSetType.LINEAR_COMPLETE_ALL;
  importSyncDialog = false;

  dialogStepper = 1;

  alert = {
    show: false,
    type: 'success',
    msg: '',
  };

  popupBlockedDialog = false;
  assignError: ComposedError | null = null;

  //actual release and due date dayjs objects
  displayDateFormat = 'MM/DD/YYYY';
  displayTimeFormat = 'h:mm A';
  pickerDateFormat = 'YYYY-MM-DD';
  pickerTimeFormat = 'HH:mm';
  pickerDateTimeFormat = `${this.pickerDateFormat} ${this.pickerTimeFormat}`;
  todayDateTimeFormat = `${this.pickerDateFormat} ${this.displayTimeFormat}`;

  //objects for pickers
  releaseDate = '';
  releaseTime = '';
  dueDate = '';
  dueTime = '';

  //handles menus
  releaseDateMenu = false;
  dueDateMenu = false;

  dialog = false;

  assignees: Map<string, AssigneeSummary> = new Map();

  showInfoDialog = false;
  settingsGroup: SettingsGroup | null = null;

  formIsValid = false;

  rules: { [rule: string]: (value: string) => boolean | string } = {
    required: (value) => !!value || 'Required.',
    counter: (value) => value.length <= 70 || 'Max 70 characters',
    characters: (value) => {
      const pattern = /[/"*<>+=|,%]/;
      return (
        !pattern.test(value) ||
        'Contains special characters:  / " * < > + = | , %'
      );
    },
  };

  get nameCounter(): number | undefined {
    return this.isEdlinkAssignment ? 70 : undefined;
  }

  get nameRules(): ((value: string) => boolean | string)[] {
    const rs = [this.rules.required];

    if (this.isEdlinkAssignment) {
      rs.push(this.rules.counter, this.rules.characters);
    }

    return rs;
  }

  get isTutorUser(): boolean {
    return this.$store.state.auth.user?.settings?.accessToLDOEReport ?? false;
  }

  get redoEnabled(): boolean {
    return this.$store.state.auth.user?.settings?.redoEnabled ?? false;
  }

  get caitEnabled(): boolean {
    return this.$store.state.auth.user?.settings?.caitEnabled ?? false;
  }

  get teachleyEnabled(): boolean {
    return this.$store.state.auth.user?.settings?.useTeachley ?? false;
  }

  get isWip(): boolean {
    return this.problemSet ? isWip(this.problemSet.xref) : false;
  }

  get showDialog(): boolean {
    return this.value ? this.value : this.dialog;
  }

  set showDialog(val: boolean) {
    this.dialog = val;

    this.$emit('input', val);
  }

  get timeLimitInSeconds(): number {
    return this.timeLimit * 60;
  }

  get importedClasses(): Array<Class> {
    const imported: Class[] = this.$store.state.classList.classes;

    // Filter imported classes for only those of chosen LMS
    return imported.filter((c: Class) => {
      return c.lmsType?.id === this.chosenLms;
    });
  }

  // Disable time pickers until a corresponding date has been selected
  get disableReleaseTime(): boolean {
    return this.releaseDate === '';
  }

  get disableDueTime(): boolean {
    return this.dueDate === '';
  }

  get releaseDatePast(): boolean {
    if (this.releaseDate) {
      const now = dayjs().format(this.pickerDateFormat);
      const releaseDate = dayjs(this.releaseDate);
      return releaseDate.isBefore(now);
    }
    return false;
  }

  get importSyncDialogButtonText(): string {
    return 'IMPORT/SYNC';
  }

  get timeLimitRules() {
    return {
      amount: (value: string) =>
        (parseInt(value) >= 1 && parseInt(value) <= 60) ||
        'Must be between 1 and 60.',
      length: (value: string) => value.length <= 2 || 'Must be whole number.',
    };
  }

  get isTimeLimitValid() {
    return (
      this.timeLimit <= 60 &&
      this.timeLimit >= 1 &&
      this.timeLimit - Math.floor(this.timeLimit) === 0
    );
  }

  // ----------
  // Computed props: Defaults
  // ----------

  get chosenClassesToAssign(): Class[] {
    return this.importedClasses.filter((c: Class) => {
      return this.chosenClasses.includes(c.id);
    });
  }

  get isSkillBuilder(): boolean {
    return this.problemSet ? isSkillBuilder(this.problemSet) : false;
  }

  get isEdlinkAssignment(): boolean {
    const lmsPtype = LmsProviderType.find(this.chosenLms);
    return lmsPtype === LmsProviderType.EDLINK;
  }

  get todayDate(): string {
    return dayjs().format(this.pickerDateFormat);
  }

  @Watch('showDialog')
  onShowDialog(newVal: boolean, oldVal: boolean): void {
    if (this.showDialog) {
      this.timeLimit = 1;
      this.hasTimeLimit = false;
      this.dialogStepper = 1;
      this.useRedo = false;
      this.useCait = false;
      this.initializeDialog();

      if (!this.releaseTime) {
        this.releaseTime = dayjs()
          .hour(8)
          .minute(0)
          .format(this.pickerTimeFormat);
      }
      if (!this.dueTime) {
        this.dueTime = dayjs().hour(15).minute(0).format(this.pickerTimeFormat);
      }

      // Override defaults
      if (this.assignment) {
        // Default to assignment LMS
        this.chosenLms = this.assignment.lmsProviderType?.id ?? 0;

        // Default to assigned Class
        this.chosenClasses.push(this.assignment.groupContextXref);

        // Default to assignment release date
        var assignmentReleaseDate = dayjs(this.assignment.releaseDate);
        this.releaseDate = assignmentReleaseDate.format(this.pickerDateFormat);
        this.releaseTime = assignmentReleaseDate.format(this.pickerTimeFormat);

        if (this.assignment.dueDate) {
          // Default to assignment due date
          let assignmentDueDate = dayjs(this.assignment.dueDate);

          this.dueDate = assignmentDueDate.format(this.pickerDateFormat);
          this.dueTime = assignmentDueDate.format(this.pickerTimeFormat);
        }
      }

      if (this.isSkillBuilder) {
        // Default to random for skill builders
        this.chosenProblemOrder = ProblemSetType.SKILL_BUILDER_RANDOM;
      } else if (this.problemSet) {
        // Default to the order of the Problem Set
        const orderType = this.problemOrder.find(
          (order) => order === this.problemSet?.problemSetType
        );

        if (orderType) {
          // Found
          this.chosenProblemOrder = this.problemSet.problemSetType;
        }
      }

      // Force to show validation errors on default name.
      this.$nextTick(() => {
        (this.$refs.nameField as HTMLFormElement).validate(true);
      });
    }

    if (newVal === true && oldVal === false) {
      const members = this.selectedTree.map((node) => node.xref);
      // Track event with Mixpanel when the dialog is shown
      trackMixpanel(EventType.trackAssignDialogShown, {
        isSkillBuilder: this.isSkillBuilder,
        psCode: members,
        parentProblemSet: this.problemSet ? this.problemSet.xref : 'N/A',
        // N/A for non-problem set assignments
      });
    } else if (newVal === false && oldVal === true) {
      const members = this.selectedTree.map((node) => node.xref);
      // Track event with Mixpanel when the dialog is hidden
      trackMixpanel(EventType.trackAssignDialogClosed, {
        isSkillBuilder: this.isSkillBuilder,
        psCode: members,
        parentProblemSet: this.problemSet ? this.problemSet.xref : 'N/A',
        // N/A for non-problem set assignments
      });
    }
  }

  initializeDialog(): void {
    // This is here to get past the stupid warning from typescript/vetur about
    // assigning the assignmentName before defaultName has been intialized
    this.assignmentName = this.defaultName;

    // Default to LMS of the current user
    this.chosenLms = this.getCurrentUser.lmsProviderType?.id ?? 0;

    // Reset all fields and clear prior selections
    this.chosenClasses = [];
    this.assignees = new Map();
    this.testMode = false;

    this.chosenProblemOrder = ProblemSetType.LINEAR_COMPLETE_ALL;
    this.releaseDate = '';
    this.releaseTime = '';
    this.dueDate = '';
    this.dueTime = '';
    // Clear any alerts from before
    this.alert = {
      show: false,
      type: 'success',
      msg: '',
    };
    this.assignError = null;
    this.popupBlockedDialog = false;
    this.assignmentMade = false;
  }

  showAlert(type: string, msg: string): void {
    this.alert = {
      show: true,
      type: type,
      msg: msg,
    };
  }

  popupBlocked(error: any): void {
    this.assignError = error;
    this.popupBlockedDialog = true;
  }

  get assignButtonText(): string {
    let numClasses = this.chosenClasses.length;
    if (numClasses === 0) {
      // Leave the text alone
      return 'Assign';
    }
    let assignText = `Assign to ${numClasses} Class`;

    if (numClasses > 1) {
      assignText += 'es';
    }

    return assignText;
  }

  get time(): string[] {
    let items = [] as string[];

    for (let hour = 0; hour <= 24; hour++) {
      for (let minute = 0; minute <= 45; minute += 15) {
        items.push(
          dayjs().hour(hour).minute(minute).format(this.displayTimeFormat)
        );
      }
    }

    return items;
  }

  get numProblemsSelected(): number {
    return getProblemCountFromStructure(this.selectedTree);
  }

  get problemSetMap(): Record<string, ProblemSetDefinition> {
    return this.$store.state.content.problemSetMap;
  }

  // FIXME: Figure out if this should be a util method?
  createPublishedProblemSet(
    problemSetType: ProblemSetType,
    members: string[],
    name?: string
  ): Promise<string> {
    return this.$store
      .dispatch('content/saveProblemSet', {
        modifiedFields: {
          problemSetType,
          name,
        },
      })
      .then(({ ceri: xref, failMessages: error }) => {
        if (error) {
          throw new Error(`Failed to create Problem Set: ${error}`);
        } else {
          // Add new members to WIP Problem Set and publish WIP Problem Set.
          return this.$store
            .dispatch('content/addProblemSetMembers', {
              xref,
              members,
              opType: ContentSaveOperationType.PUBLISH,
            })
            .then((status) => {
              const { ceri: xref, failMessages: error } = status;
              if (error || !xref) {
                throw new Error(
                  `Failed to add members to and publish Problem Set ${xref}: ` +
                    error
                );
              }
              // Return new Published CERI.
              return xref;
            });
        }
      });
  }

  async createProblemSetToAssign(
    problemSetType: ProblemSetType,
    selected: ProblemSetNode[],
    name?: string
  ): Promise<string> {
    const members: string[] = [];
    for (const node of selected) {
      // Do NOT need to care about WIP Problems and Problem Sets?
      if (node.xref.startsWith('PR')) {
        members.push(node.xref);
      } else if (node.xref.startsWith('PS')) {
        // Use the content store. Problem Set (tree) MUST have been downloaded.
        const problemSet = this.problemSetMap[node.xref];
        if (problemSet) {
          if (
            node.children?.length == 1 &&
            problemSet.problemSetType == ProblemSetType.MULTI_PART_PROBLEM_SET
          ) {
            // FIXME: Figure out if we do the same for other Problem Set types?
            // FIXME: What if this is a Problem Set?
            // ONLY one Problem.
            members.push(node.children[0].xref);
          } else {
            const selected = node.children?.map((node) => node.xref);
            // MUST NOT be empty for Published Problem Set
            const children = problemSet.children;
            if (!isEqual(children, selected)) {
              // May be a different ceri.
              const newXref = await this.createProblemSetToAssign(
                problemSet.problemSetType,
                node.children ?? []
                // problemSet.name
              );
              members.push(newXref);
            } else {
              // Nothing changed. Entire Problem Set (tree) is selected.
              members.push(node.xref);
            }
          }
        } else {
          throw new Error(
            `Use the content store. Problem Set ${node.xref} MUST have been downloaded.`
          );
        }
      }
    }

    if (members.length) {
      // Create a new WIP Problem Set and published it with selected members.
      return this.createPublishedProblemSet(problemSetType, members, name);
    } else {
      // Containing WIP content?
      throw new Error(
        'MUST provide Published members. CANNOT create an empty Problem Set.'
      );
    }
  }

  addToMyProblemSetsAssigned(xref: string): Promise<void> {
    const mpsaf = this.$store.state.auth.MY_PROBLEM_SETS_ASSIGNED;
    return addMembersToFolder(mpsaf, [xref]);
  }

  async assign(): Promise<void> {
    // Clear assignedMembers before starting a new assignment for same problem set
    // Avoid uncleared assignedMembers where teacher assigns a subset then assigns full problem set
    const lmsPtype = LmsProviderType.find(this.chosenLms);

    if (lmsPtype) {
      this.assigning = true;

      const data: Partial<AssignData> = {
        name: this.assignmentName,
      };

      // Make sure canonical assignments does NOT affect Cignition / Tutor DSDR?
      if (this.problemSet) {
        // Always send up the parent problem set if it is from a known problem set
        data.parentProblemSetCeri = this.problemSet.xref;
        data.problemSetCeri = this.problemSet.xref;
      }
      if (
        !this.problemSet ||
        (!this.isSkillBuilder &&
          ((this.numTotalProblems &&
            this.numProblemsSelected !== this.numTotalProblems) ||
            (this.problemSet &&
              // For E-TRIALS: Choose-condition and if-then-else sections don't create new PS
              ![
                ProblemSetType.RANDOM_COMPLETE_ONE,
                ProblemSetType.IF_THEN_ELSE,
              ].includes(this.problemSet.problemSetType) &&
              this.chosenProblemOrder !== this.problemSet.problemSetType)))
      ) {
        // Create new Problem Set.
        data.problemSetCeri = await this.createProblemSetToAssign(
          this.chosenProblemOrder,
          this.selectedTree,
          this.assignmentName
        );
        // Add to My Problem Sets Assigned User Folder.
        this.addToMyProblemSetsAssigned(data.problemSetCeri);
      }

      // Release and due date
      if (this.releaseDate) {
        // When time exist parse with hh:mm, else just date
        const parseFormat = this.releaseTime
          ? this.pickerDateTimeFormat
          : this.pickerDateFormat;

        const releaseDateObj = dayjs(
          `${this.releaseDate} ${this.releaseTime}`,
          parseFormat
        );
        data.releaseDate = releaseDateObj.valueOf();
      } // If empty, exclude from request to set release date to 'now' in the server

      if (this.dueDate) {
        // When time exist parse with hh:mm, else just date
        const parseFormat = this.dueTime
          ? this.pickerDateTimeFormat
          : this.pickerDateFormat;

        // Not empty
        const dueDateObj = dayjs(
          `${this.dueDate} ${this.dueTime}`,
          parseFormat
        );

        data.dueDate = dueDateObj.valueOf();
      } // If empty, exclude from request to not set any due date

      // TODO/FIXME: Check due date is after release date and alert user otherwise
      if (this.releaseDate && this.dueDate) {
        const releaseDate = dayjs(data.releaseDate);
        const dueDate = dayjs(data.dueDate);

        if (dueDate.isSame(releaseDate) || dueDate.isBefore(releaseDate)) {
          this.$notify('Due date must be a date/time after the release date.');
          this.assigning = false;
          return;
        }
      }
      // before sending request to server?

      // Enabled test mode on assignment
      if (this.testMode) {
        data.settings = {
          correctnessFeedback: false,
          tutoringAccessible: false,
        };
      }

      if (this.hasTimeLimit)
        data.settings = {
          ...data.settings,
          timeLimit: this.timeLimitInSeconds,
        };

      // Set delivery of student score
      data.settings = {
        ...data.settings,
        deliveryOfStudentScore: this.deliveryOfStudentScore,
      };

      if (this.redoEnabled) {
        data.settings = {
          ...data.settings,
          // Overwrite default.
          useRedo: this.useRedo,
        };
      }

      if (this.caitEnabled) {
        data.settings = {
          ...data.settings,
          // Overwrite default.
          useCait: this.useCait,
        };
      }

      if (this.isTutorUser) {
        data.settings = {
          ...data.settings,
          assignedByTutor: true,
        };
      }

      if (this.teachleyEnabled) {
        data.settings = {
          ...data.settings,
          useTeachley: this.teachleyEnabled,
        };
      }

      //reset checkers
      this.assignError = null;
      this.popupBlockedDialog = false;

      const promises: Array<Promise<unknown>> = [];
      let promise = null;

      switch (lmsPtype) {
        case LmsProviderType.GOOGLE_CLASSROOM:
        case LmsProviderType.CANVAS:
        case LmsProviderType.EDLINK:
          for (const courseXref of this.chosenClasses) {
            const assignees = this.assignees.get(courseXref);
            let assignData: AssignData | null = null;

            if (assignees?.class) {
              // Course assignment
              assignData = data as unknown as AssignData;
            } else if (
              assignees?.studentXrefs &&
              assignees?.studentXrefs.length > 0
            ) {
              // Individual/Subgroup assignment
              const assignDataExt: AssignData = {
                ...data,
              } as unknown as AssignData;

              assignDataExt.userXrefs = assignees.studentXrefs;
              assignData = assignDataExt;
            }

            if (assignData) {
              promise = createCourseAssignment(
                courseXref,
                lmsPtype,
                assignData as AssignData
              );

              promises.push(promise);
            }
          }
          break;
        case LmsProviderType.LTI_ENABLED:
          promise = getDeepLinkResponse(data as AssignData).then(
            ({ jwt, redirectUrl }) => {
              this.$router.push({
                name: 'LtiDeepLinkResponsePage',
                query: {
                  jwt,
                  redirectUrl,
                },
              });
            }
          );
          promises.push(promise);

          break;
        default:
          // FIXME: Better way to do this?
          this.$notify('Unrecognized LMS to assign to?');
          break;
      }

      // Mixpanel tracking parameters
      const members = this.selectedTree.map((node) => node.xref);
      const trackingParams = {
        ltsType: lmsPtype,
        assignmentName: this.assignmentName,
        isSkillBuilder: this.isSkillBuilder,
        psCode: members,
        parentProblemSet: this.problemSet ? this.problemSet.xref : 'N/A',
        // N/A for non-problem set assignments
        chosenClasses: this.chosenClasses.join(', '),
        releaseDate: this.releaseDate,
        dueDate: this.dueDate,
        problemOrder: this.chosenProblemOrder,
        testMode: this.testMode,
        hasTimeLimit: this.hasTimeLimit,
        timeLimit: this.hasTimeLimit ? this.timeLimit : null,
        useRedo: this.useRedo,
        useCait: this.useCait,
        deliveryOfStudentScore: this.deliveryOfStudentScore
          ? 'Score and Class Average'
          : 'Success Symbols',
        ...(this.teachleyEnabled ? { useTeachley: this.teachleyEnabled } : {}),
        ...(this.isTutorUser ? { isTutorUser: this.isTutorUser } : {}),
      };

      // FIXME: What if one assignment failed to be created, but the others did?
      Promise.all(promises)
        .then((responses) => {
          // Mixpanel tracking creation of assignments
          trackMixpanel(EventType.trackAssignmentCreated, {
            ...trackingParams,
            assignmentXrefList: responses,
          });
          this.assignmentMade = true;
          this.$emit('created', responses); // Assignment xrefs
          this.assigning = false;
        })
        .catch((error) => {
          this.assigning = false;

          if (error.statusCode) {
            switch (error.statusCode) {
              case 404:
                this.$notify(
                  'Resource(s) not found. Unable to create the assignment(s).'
                );
                break;
              case 403:
                this.$notify(
                  'You do not have the authority to create the assignment(s).'
                );
                break;
              case 417:
                error.handleGlobally &&
                  error
                    .handleGlobally()
                    .then(() => {
                      //if the popup was not blocked we can continue as we have been
                      this.$notify('Authorization updated. Please try again.');
                    })
                    .catch(() => {
                      //our popup was blocked
                      this.assignError = error;
                      this.popupBlockedDialog = true;
                    });
                break;
              default:
                this.$notify('Fail to create the assignment(s).');
            }
          } else {
            this.$notify('Fail to create the assignment(s).');
          }
        });
    } else {
      // FIXME: Better way to do this?
      this.$notify('Unrecognized LMS to assign to?');
    }
  }

  update(): void {
    const modifiedAssignData = this.modifiedFields;

    // TODO/FIXME: Check whether it is empty before sending and alert
    // user otherwise before sending the request to server?
    // TODO/FIXME: Check due date is after release date and alert user otherwise
    const { releaseDate, dueDate } = modifiedAssignData;
    if (releaseDate && dueDate) {
      const formattedReleaseDate = dayjs(releaseDate);
      const formattedDueDate = dayjs(dueDate);

      if (
        formattedDueDate.isSame(formattedReleaseDate) ||
        formattedDueDate.isBefore(formattedReleaseDate)
      ) {
        this.$notify('Due date must be a date/time after the release date.');
        return;
      }
    }

    // before sending request to server?
    if (this.assignment) {
      this.updating = true;

      updateAssignment(
        this.assignment.xref,
        modifiedAssignData,
        this.assignment.lmsProviderType
      )
        .then(() => {
          trackMixpanel(EventType.trackAssignmentUpdated, {
            assignmentId: this.assignment.xref,
            modifiedFields: Object.keys(modifiedAssignData),
          });
          this.$emit('updated', modifiedAssignData);
          this.$notify('Successfully updated assignment.');
          this.showDialog = false;
        })
        .catch((error) => {
          if (error.statusCode === 417) {
            // Authorization needed
            error.handleGlobally &&
              error
                .handleGlobally()
                .then(() => {
                  this.$notify('Authorization updated. Please try again.');
                })
                .catch(() => {
                  //our popup was blocked
                  this.assignError = error;
                  this.popupBlockedDialog = true;
                });
          } else {
            // Going to LP will not fix this issue.
            this.$notify('Failed to update assignment.');
          }
        })
        .finally(() => {
          this.updating = false;
        });
    }
  }

  get hasModifiedFields(): boolean {
    return Object.keys(this.modifiedFields).length > 0;
  }

  get modifiedFields(): EditableAssignmentFields {
    const data: EditableAssignmentFields = {};

    if (this.assignment) {
      // Changed assignment name
      if (this.assignmentName !== this.assignment.name) {
        data.name = this.assignmentName;
      }

      // TODO/FIXME: Under the assumption that the assignment MUST have a set release date
      // FIXME: Do we care about s/ms when editing an assignment as long as they are the same date and time?
      if (this.releaseDate) {
        const selectedReleaseDate = dayjs(
          `${this.releaseDate} ${this.releaseTime}`
        );
        const assignmentReleaseDate = dayjs(this.assignment.releaseDate);

        // Compare to minutes. Ignore s and ms.
        if (!selectedReleaseDate.isSame(assignmentReleaseDate, 'm')) {
          // Different release date
          data.releaseDate = selectedReleaseDate.valueOf();
        } else {
          // No changes made. Do not include as an updated field.
        }
      } else {
        // No release date selected. Set to NOW.
        data.releaseDate = null;
      }

      // FIXME: Should we disable due date (not allow such edit option) if it is already due?
      if (this.dueDate) {
        const selectedDueDate = dayjs(`${this.dueDate} ${this.dueTime}`);
        if (this.assignment.dueDate) {
          const assignmentDueDate = dayjs(this.assignment.dueDate);

          // Compare to minutes. Ignore s and ms.
          if (!selectedDueDate.isSame(assignmentDueDate, 'm')) {
            // Different due date
            data.dueDate = selectedDueDate.valueOf();
          } else {
            // No changes made. Do not include as an updated field.
          }
        } else {
          // No due date assigned originally. Added one.
          data.dueDate = selectedDueDate.valueOf();
        }
      } else {
        if (this.assignment.dueDate) {
          // Has a due date originally. Removed it. Reset to NO due date.
          data.dueDate = null;
        }
      }
    }

    return data;
  }

  mounted(): void {
    this.$store.dispatch('classList/requestClasses');
  }

  // Handle release and due date/time
  get formattedReleaseDate(): string {
    return this.releaseDate
      ? dayjs(this.releaseDate).format(this.displayDateFormat)
      : '';
  }

  set formattedReleaseDate(val: string) {
    if (val) {
      let dayjsDate = dayjs(val, this.displayDateFormat);
      if (dayjsDate.isValid()) {
        this.releaseDate = dayjsDate.format(this.pickerDateFormat);
      } else {
        // Do nothing. Release Date will stay what it was.
      }
    } else {
      this.releaseDate = '';
    }
  }

  get formattedReleaseTime(): string {
    if (this.releaseTime) {
      const timeArr = this.releaseTime.split(':');
      return dayjs()
        .hour(parseInt(timeArr[0]))
        .minute(parseInt(timeArr[1]))
        .format(this.displayTimeFormat);
    } else {
      return '';
    }
  }

  set formattedReleaseTime(val: string) {
    if (val) {
      let newVal = this.parseTime(val);
      if (newVal) {
        this.releaseTime = newVal;
      }
    } else {
      this.releaseTime = val;
    }
  }

  // FIXME the fact that this is copy paste...
  get formattedDueDate(): string {
    return this.dueDate
      ? dayjs(this.dueDate).format(this.displayDateFormat)
      : '';
  }

  set formattedDueDate(val: string) {
    if (val) {
      let dayjsDate = dayjs(val, this.displayDateFormat);
      if (dayjsDate.isValid()) {
        this.dueDate = dayjsDate.format(this.pickerDateFormat);
      } else {
        //do nothing. dueDate will stay what it was.
      }
    } else {
      this.dueDate = '';
    }
  }

  get formattedDueTime(): string {
    if (this.dueTime) {
      const timeArr = this.dueTime.split(':');
      return dayjs()
        .hour(parseInt(timeArr[0]))
        .minute(parseInt(timeArr[1]))
        .format(this.displayTimeFormat);
    } else {
      return '';
    }
  }

  set formattedDueTime(val: string) {
    if (val) {
      let newVal = this.parseTime(val);
      if (newVal) {
        this.dueTime = newVal;
      }
    } else {
      this.dueTime = val;
    }
  }

  // Returns empty string if not correctly parsed
  parseTime(time: string): string {
    // Make sure there is a space before AM/PM
    time = time.replace(/ *([AP]M)/i, ' $1');
    let dayjsTime = dayjs(
      `${this.todayDate} ${time}`,
      this.todayDateTimeFormat
    );
    if (dayjsTime.isValid()) {
      return dayjsTime.format(this.pickerTimeFormat);
    }
    return '';
  }

  get relaseDateIsValid(): boolean {
    return (
      !this.releaseDatePast ||
      (this.mode === Mode.EDIT && this.releaseDateUnchanged)
    );
  }

  get releaseDateUnchanged(): boolean {
    const parseFormat = this.releaseTime
      ? this.pickerDateTimeFormat
      : this.pickerDateFormat;
    // assignment release date comes with seconds
    // assign dialog release date is without seconds
    // assignment release date needs to be adjusted in minutes
    const assignmentReleaseTimestamp = dayjs(this.assignment?.releaseDate);
    const assignmentReleaseDate = assignmentReleaseTimestamp.format(
      this.pickerDateFormat
    );
    const assignmentReleaseTime = assignmentReleaseTimestamp.format(
      this.pickerTimeFormat
    );
    const assignmentReleaseObj = dayjs(
      `${assignmentReleaseDate} ${assignmentReleaseTime}`,
      parseFormat
    );
    const releaseObj = dayjs(
      `${this.releaseDate} ${this.releaseTime}`,
      parseFormat
    );
    return releaseObj.isSame(assignmentReleaseObj);
  }
}
