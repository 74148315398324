
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  AnswerPart,
  AnswerSet,
  AnswerType,
  ProblemDefinition,
  ProblemTypeSDK3,
} from '@/domain/Problem';
import AnswersViewSDK3 from './AnswersViewSDK3.vue';
import { cloneDeep, isEqual, uniq } from 'lodash';
import Attribution from '@/components/base/Attribution.vue';
import { getAnswerParts } from '@/utils/problem.util';
import PrintableDropdown from '@/components/Print/PrintableDropdown.vue';
import { ResponseBoxType } from '@/utils/tinyMCE/problemBuildertinyMCEConfig';

export enum ViewMode {
  NORMAL = 'NORMAL',
  PRINT = 'PRINT',
}

// FIXME: Make this a Problem response generator so we can probably reuse for authoring SRFs.
@Component({ components: { AnswersViewSDK3, Attribution, PrintableDropdown } })
export default class ProblemViewSDK3 extends Vue {
  @Prop({ required: true }) problem: ProblemDefinition;
  @Prop({ required: false, default: () => ({}) }) response: Record<
    number,
    string[]
  >;
  @Prop({ required: false, default: true }) showCorrectnessIcon: boolean;
  @Prop({ required: false, default: true }) showAnswerPool: boolean;
  @Prop({ required: false, default: false }) collapsed: boolean;
  @Prop({ required: false, default: () => ViewMode.NORMAL }) mode: ViewMode;

  ProblemTypeSDK3 = ProblemTypeSDK3;
  AnswerType = AnswerType;
  ViewMode = ViewMode;

  fillInAnswerTypes = [
    AnswerType.EXACT_MATCH,
    AnswerType.PURE_EQUIVALENCE,
    AnswerType.SYMBOLIC_EQUIVALENCE,
    AnswerType.ALGEBRA,
    AnswerType.EXACT_FRACTION,
    AnswerType.NUMERIC,
    AnswerType.NUMERIC_EXPRESSION,
  ];

  get problemBody(): string {
    if (this.collapsed) {
      const textArea = this.getTextArea();
      textArea.innerHTML = this.problem.question;
      return textArea.textContent ?? '';
    } else {
      return this.problem.question;
    }
  }

  get attributions(): string[] {
    return this.problem.attributes?.attribution ?? [];
  }

  get answerParts(): Record<number, AnswerPart> {
    return getAnswerParts(this.problem.answersSDK3);
  }

  get fillInParts(): AnswerPart[] {
    const fips = [];
    for (const marker in this.answerParts) {
      const ap = this.answerParts[marker];
      if (this.fillInAnswerTypes.includes(ap.answerType)) {
        // Decode HTML special characters. For example, <
        const textArea = this.getTextArea();
        const copy = cloneDeep(ap);
        const values = copy.answerValues ?? [];
        for (const value of values) {
          textArea.innerHTML = value.value;
          value.value = textArea.innerText;
        }
        fips.push(copy);
      } else if (ap.answerType == AnswerType.DROP_DOWN) {
        fips.push(ap);
      } else if (ap.answerType == AnswerType.DRAG_DROP) {
        fips.push(ap);
      }
    }
    return fips;
  }

  // FIXME: Answer Pool left available if not in found response taking into account ANSWER_POOL_REUSE.
  // FIXME: Figure out if we need to shuffle?
  // If we remove items from the pool based on their correctness (to put the correct answers in the drop targets),
  // then we need to check the "showAnswerPool" variable. We don't want to remove correct answers from the pool if
  // we are not meant to show them in the dropTargets.
  get answerPoolValues(): string[] {
    const ases: AnswerSet[] = [];
    const ras = this.problem.answersSDK3;
    if (ras) {
      ases.push(ras);
    }
    const values = [];
    // Compute answer pool.
    while (ases.length) {
      const as = ases.shift();
      if (ras && ras.properties?.ANSWER_POOL) {
        // Predefined order.
        values.push(...ras.properties.ANSWER_POOL);
        continue;
      }
      if (as?.answerValues) {
        const asvs = as.answerValues.map((av) => av.value);
        values.push(...asvs);
      }
      const members = as?.members ?? [];
      for (const member of members) {
        switch (member.memberType) {
          case 'ANSWER_PART':
            if (member?.answerValues) {
              const apvs = member.answerValues.map((av) => av.value);
              values.push(...apvs);
            }
            break;
          case 'ANSWER_SET':
            ases.push(member);
            break;
        }
      }
    }
    // Answer Pool contains unique values. Remove duplicates across parts.
    return uniq(values);
  }

  // FIXME: Handle partial correctness?
  // FIXME: Handle correct eventually vs incorrect. To determine this, this component would
  // need to know if the Student requested the answer (their list of actions taken)?
  get responseCorrectness(): Record<number, { icon: string; color: string }> {
    const correctnessMap: Record<number, { icon: string; color: string }> = {};
    if (this.response) {
      for (const marker in this.response) {
        const vs = this.response[marker];
        const ap = this.answerParts[marker];
        const avs = vs.map((v) => ap.answerValues?.find((av) => av.value == v));
        let correct = avs.every((av) => av?.isCorrect);
        const dropCorrectMap = ap.properties?.DROP_CORRECT;
        if (dropCorrectMap) {
          const valueCountMap = vs.reduce(
            (acc: Record<string, number>, v: string) => {
              if (acc[v]) {
                acc[v]++;
              } else {
                acc[v] = 1;
              }
              return acc;
            },
            {}
          );
          correct = correct && isEqual(valueCountMap, dropCorrectMap);
        }
        if (correct) {
          correctnessMap[marker] = {
            icon: 'mdi-check-circle',
            color: 'correct',
          };
        } else {
          correctnessMap[marker] = {
            icon: 'mdi-close-thick',
            // incorrect?
            color: 'correctEventually',
          };
        }
      }
    }
    return correctnessMap;
  }

  getTextArea(): HTMLElement {
    let textArea = document.getElementById('fill-in-value-conversion');
    if (!textArea) {
      textArea = document.createElement('textarea');
      textArea.id = 'fill-in-value-conversion';
    }
    return textArea;
  }

  renderProblemBodyWithResponse(): void {
    const innerIconSize = 24;
    const extraPadding = 34;
    let minWidth: number | undefined = undefined;

    this.$nextTick(() => {
      const qEl = document.getElementById(`${this.problem.xref}-question`);
      const apEls = qEl?.getElementsByTagName('ast-r') ?? [];
      for (const apEl of apEls) {
        const marker = Number(apEl.getAttribute('marker'));
        const type = apEl.getAttribute('type');
        if (type == ResponseBoxType.DROPDOWN) {
          minWidth = 200;
        } else if (type == ResponseBoxType.TEXT) {
          minWidth = 90;
        }
        const fipEls = (this.$refs[`part-${marker}`] ?? []) as HTMLElement[];
        for (const fipEl of fipEls) {
          const pEl = fipEl.firstChild as HTMLElement;
          if (pEl) {
            if (minWidth) {
              const valsEls = (this.$refs[`values-${marker}`] ??
                []) as HTMLElement[];
              for (const valsEl of valsEls) {
                const width =
                  (valsEl.getBoundingClientRect().width ?? 0) +
                  innerIconSize +
                  extraPadding;
                pEl.style.width = `${Math.max(width, minWidth)}px`;
              }
            }
            apEl.replaceChildren(pEl);
          }
          fipEl.remove();
        }
      }
    });
  }

  mounted(): void {
    this.renderProblemBodyWithResponse();
    window.com.wiris.js.JsPluginViewer.parseDocument();
  }

  @Watch('response')
  onResponse(
    newResponse: Record<number, string[]>,
    oldResponse: Record<number, string[]>
  ): void {
    if (!isEqual(newResponse, oldResponse)) {
      this.renderProblemBodyWithResponse();
    }
  }
}
