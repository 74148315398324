
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  getFolder,
  getFolderMembers,
  getFolderPathsTo,
} from '@/api/core/folders.api';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import LessonPageTopBar from '@/components/FindProblems/LessonPage/LessonPageTopBar.vue';
import AssignFab from '@/components/FindProblems/AssignFab.vue';
import {
  EXPLORE_CONTENT,
  FolderDefinition,
  FolderMember,
  IFolderMember,
} from '@/domain/Folder';
import {
  ProblemSetNode,
  getProblemSetStructure,
  isSkillBuilder,
} from '@/utils/problemSet.util';
import ProblemSetViewForFind from '@/components/base/ProblemSetViewForFind.vue';
import { DefinitionInclude, type ObjectList } from '@/api/core/base.api';
import { getTeachleyProblems } from '@/api/core/teachleyProblems.api';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';

enum LessonPageView {
  LESSON_FOLDER = 'lessonFolder',
  PROBLEM_SET = 'problemSet',
}

@Component({
  components: {
    LessonPageTopBar,
    AssignFab,
    ProblemSetViewForFind,
  },
})
// FIXME: Figure out if this page should use the folder/file store?
export default class LessonPage extends Vue {
  lessonFolder: FolderDefinition | null = null;
  members: IFolderMember[] = [];
  filePaths: (FolderDefinition | ProblemSetDefinition)[][] = [];

  // Top-level information
  initializing = false;
  // Problem Set opened
  downloading = false;

  selectedTree: ProblemSetNode[] = [];
  teachleyMap: Record<string, boolean> = {};

  get redoEnabled(): boolean {
    return this.$store.state.auth.user?.settings?.redoEnabled ?? false;
  }

  get teachleyEnabled(): boolean {
    return this.$store.state.auth.user?.settings?.useTeachley ?? false;
  }

  get pageView(): LessonPageView {
    return this.folderXref !== undefined
      ? // Lesson Folder
        LessonPageView.LESSON_FOLDER
      : // Problem Set
        LessonPageView.PROBLEM_SET;
  }

  get folderXref(): string {
    return this.$route.params.folderXref;
  }

  get problemSetXref(): string {
    return this.$route.params.problemSetXref;
  }

  set problemSetXref(ceri: string) {
    this.$router.replace({
      name: 'LessonPageFolders',
      params: {
        ...this.$route.params,
        problemSetXref: ceri,
      },
      query: this.$route.query,
    });
  }

  get filePath(): (FolderDefinition | ProblemSetDefinition)[] {
    return this.filePaths[0];
  }

  get title(): string {
    let header = '';
    let name = '';

    switch (this.pageView) {
      case LessonPageView.LESSON_FOLDER:
        header = this.lessonFolder?.properties.HEADER ?? '';
        name = this.lessonFolder?.name ?? '';
        break;
      case LessonPageView.PROBLEM_SET:
        name = this.problemSet?.name ?? '';
        break;
    }

    if (header && name) {
      return `${header}: ${name}`;
    } else if (name) {
      return name;
    } else if (header) {
      return header;
    }

    return '';
  }

  get isSkillBuilder(): boolean {
    return this.problemSet ? isSkillBuilder(this.problemSet) : false;
  }

  get problemSetMap(): Record<string, ProblemSetDefinition> {
    return this.$store.state.content.problemSetMap;
  }

  get redoMap(): Record<string, string[]> {
    return this.$store.state.content.redoMap;
  }

  get problemSet(): ProblemSetDefinition | undefined {
    return this.problemSetMap[this.problemSetXref];
  }

  getPreviewLink(ceri: string): string {
    return `${process.env.VUE_APP_STUDENT_WORKBENCH_URL}/preview/problemSet/${ceri}`;
  }

  updateTree(tree: ProblemSetNode[]) {
    this.selectedTree = tree;
  }

  downloadProblemSet(): void {
    this.downloading = true;
    this.$store
      .dispatch('content/getProblemSetTree', {
        xref: this.problemSetXref,
      })
      .then((ps) => {
        // Default all selected.
        this.selectedTree = getProblemSetStructure(ps, this.problemSetMap);
        if (this.redoEnabled) {
          this.$store.dispatch('content/getProblemSetRedos', {
            xref: ps.xref,
          });
        }
        if (this.teachleyEnabled) {
          getTeachleyProblems().then((teachleyMap) => {
            this.teachleyMap = teachleyMap;
          });
        }
      })
      .finally(() => {
        this.downloading = false;

        // Mixpanel tracking
        trackMixpanel(EventType.lessonPageLoad, {
          //psId: this.problemSetXref ? this.problemSetXref : undefined,
          psa: this.problemSetXref ? this.problemSetXref : undefined,
          pageType: this.pageView,
          reDoStatus: this.redoEnabled,
          teachleyStatus: this.teachleyEnabled,
        });
      });
  }

  initialize(): void {
    this.initializing = true;
    this.members = [];
    const promises = [];
    switch (this.pageView) {
      case LessonPageView.LESSON_FOLDER:
        getFolder(this.folderXref, [DefinitionInclude.ATTRIBUTES]).then(
          (folder: FolderDefinition) => {
            this.lessonFolder = folder;
            promises.push(
              getFolderMembers(this.lessonFolder.xref, [
                DefinitionInclude.ATTRIBUTES,
              ]).then((response: ObjectList<FolderMember>) => {
                this.members = response.data;
              })
            );
            promises.push(
              getFolderPathsTo([EXPLORE_CONTENT], this.lessonFolder.xref).then(
                (filePaths) => {
                  this.filePaths = filePaths;
                }
              )
            );
          }
        );
        break;
      case LessonPageView.PROBLEM_SET:
        // FIXME: Figure out if we need to set EXPLORE_CONTENT here.
        promises.push(
          getFolderPathsTo([EXPLORE_CONTENT], this.problemSetXref).then(
            (filePaths) => {
              this.filePaths = filePaths;
            }
          )
        );
        break;
    }
    Promise.allSettled(promises).then(() => (this.initializing = false));
    if (this.problemSetXref) {
      this.downloadProblemSet();
    }
  }

  created(): void {
    this.initialize();
  }

  @Watch('problemSetXref')
  onProblemSetChange(newCeri: string, oldCeri: string): void {
    if (oldCeri === newCeri) {
      return;
    } else if (newCeri) {
      this.downloadProblemSet();
    }
  }

  @Watch('folderXref')
  onFolderChange(newXref: string, oldXref: string): void {
    if (oldXref === newXref) {
      return;
    }
    this.initialize();
  }
}
