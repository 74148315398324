
import { DataTableHeader } from 'vuetify';
import { ProblemLog, StudentLog } from '@/domain/ReportData/AssignmentData';
import { Component, Prop, Vue } from 'vue-property-decorator';
import dayjs, { Dayjs } from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {
  ActionType,
  IAction,
  StudentResponseAction,
  StudentSubmissionAction,
  CaitMessageRequestedAction,
  CaitMessageResponseAction,
} from '@/domain/Action';
import { getActionResponses } from '@/utils/actions.util';
import { ProblemForReport, formatPercentage } from '@/utils/report.util';
import AnswersViewSDK3 from '../Builder/ContentView/AnswersViewSDK3.vue';

dayjs.extend(localizedFormat);

enum StaticHeaders {
  TIME = 'TIME',
  ACTION_TYPE = 'ACTION_TYPE',
  RESPONSE = 'RESPONSE',
  TEACHER_FEEDBACK = 'TEACHER_FEEDBACK',
}

interface StudentDetailsTableRow {
  prLog: ProblemLog;
  actionType: ActionType;
  score?: number;
  class?: string[];
  [StaticHeaders.ACTION_TYPE]: string;
  [StaticHeaders.RESPONSE]?: Record<number, string[]>;
  [StaticHeaders.TIME]: string;
  [StaticHeaders.TEACHER_FEEDBACK]?: string;
}

@Component({
  components: {
    AnswersViewSDK3,
  },
})
export default class StudentDetailsTable extends Vue {
  @Prop({ required: true }) studentLog: StudentLog;
  @Prop({ default: () => [] }) problems: ProblemForReport[];

  defaults: Partial<DataTableHeader> = {
    align: 'center',
    class: ['text-no-wrap', 'sticky-row', 'sticky-row-1', 'text-subtitle-2'],
    sortable: false,
  };

  ActionType = ActionType;
  StaticHeaders = StaticHeaders;

  get problemMap(): Record<string, ProblemForReport> {
    const xrefToProblemMap: Record<string, ProblemForReport> = {};
    for (const problem of this.problems) {
      xrefToProblemMap[problem.xref] = problem;
    }
    return xrefToProblemMap;
  }

  get tableRows(): StudentDetailsTableRow[] {
    const rows: StudentDetailsTableRow[] = [];
    let previousTimestamp: Dayjs;
    let currentTimestamp: Dayjs;
    let timestamp = '';
    this.studentLog?.problemLogAndActions
      ?.sort((a, b) => (a.prLog.startTime > b.prLog.startTime ? 1 : -1))
      .forEach(({ prLog, actions }) => {
        actions?.forEach((action) => {
          currentTimestamp = dayjs(action.timestamp);
          const row = this.transformToTableRow(prLog, action);

          if (
            action.actionType == ActionType.PROBLEM_STARTED_ACTION ||
            action.actionType == ActionType.PROBLEM_RESUMED_ACTION
          ) {
            // timestamp = dayjs(action.timestamp).format(
            //   'ddd MMM DD YYYY hh:mm:ss A'
            // );
            timestamp = currentTimestamp.format('llll');
          } else if (action.actionType == ActionType.PROBLEM_FINISHED_ACTION) {
            timestamp = '';
          } else {
            let diff = dayjs.duration(currentTimestamp.diff(previousTimestamp));
            timestamp = `+ ${Math.floor(diff.asMinutes())} mins`;
            timestamp += ` ${diff.format('s')} secs`;
          }
          row[StaticHeaders.TIME] = timestamp;
          rows.push(row as StudentDetailsTableRow);
          previousTimestamp = currentTimestamp;
        });
      });
    return rows;
  }

  //may look weird but I think we should leave the full switch statement here to know that nothing is being added (so far) for those actions
  transformToTableRow(
    prLog: ProblemLog,
    action: IAction
  ): Partial<StudentDetailsTableRow> {
    let row: Partial<StudentDetailsTableRow> = {
      prLog,
      actionType: action.actionType,
    };

    let correct = undefined;
    //Please do not re-order the actions. They are in a fairly specific order: ProblemActions, ResponseActions, TutoringRequestedActions.
    switch (action.actionType) {
      case ActionType.PROBLEM_STARTED_ACTION:
      case ActionType.PROBLEM_RESUMED_ACTION:
        break;

      case ActionType.PROBLEM_FINISHED_ACTION:
        {
          const score = prLog.continuousScore;
          if (typeof score === 'number') {
            row[StaticHeaders.TEACHER_FEEDBACK] = `Score: ${formatPercentage(
              score
            )}`;
          }
        }
        break;

      case ActionType.STUDENT_RESPONSE_ACTION:
        {
          const sra = action as StudentResponseAction;
          const response = getActionResponses([sra])[0];
          row[StaticHeaders.RESPONSE] = response.response;
          correct = response.correct;
        }
        break;

      case ActionType.STUDENT_SUBMISSION_ACTION:
        {
          const ssa = action as StudentSubmissionAction;
          const response = getActionResponses([ssa])[0];
          row[StaticHeaders.RESPONSE] = response.response;
          row.score = prLog.continuousScore;
        }
        break;

      case ActionType.CAIT_MESSAGE_REQUESTED_ACTION:
        {
          const cmra = action as CaitMessageRequestedAction;
          row[StaticHeaders.RESPONSE] = cmra.message;
        }
        break;

      case ActionType.CAIT_MESSAGE_RESPONSE_ACTION:
        {
          const cmra = action as CaitMessageResponseAction;
          row[StaticHeaders.RESPONSE] = cmra.response;
        }
        break;

      case ActionType.HINT_REQUESTED_ACTION:
      case ActionType.SCAFFOLDING_REQUESTED_ACTION:
      case ActionType.URL_LINK_REQUESTED_ACTION:
      case ActionType.EXPLANATION_REQUESTED_ACTION:
      case ActionType.ANSWER_REQUESTED_ACTION:
      case ActionType.IM_STUCK_REQUEST_ACTION:
      case ActionType.LIVE_TUTORING_REQUESTED_ACTION:
        //nothing needed
        break;
    }

    const color = this.getBackgroundColor(action.actionType, correct);
    row.class = [color];

    const isRedo = typeof prLog.redoParentXref === 'string';
    const text = this.convertActionTypeToText(
      action.actionType,
      isRedo,
      correct
    );
    row[StaticHeaders.ACTION_TYPE] = text;

    return row;
  }

  getBackgroundColor(action: ActionType, correct?: boolean): string {
    switch (action) {
      case ActionType.STUDENT_RESPONSE_ACTION:
        if (correct) {
          return 'green-background';
        }
        return 'orange-background';

      case ActionType.EXPLANATION_REQUESTED_ACTION:
      case ActionType.ANSWER_REQUESTED_ACTION:
      case ActionType.IM_STUCK_REQUEST_ACTION:
      case ActionType.SCAFFOLDING_REQUESTED_ACTION:
        return 'red-background';

      case ActionType.STUDENT_SUBMISSION_ACTION:
        return 'blue-background';

      case ActionType.PROBLEM_STARTED_ACTION:
      case ActionType.PROBLEM_RESUMED_ACTION:
      case ActionType.PROBLEM_FINISHED_ACTION:
      case ActionType.TIMER_STARTED_ACTION:
      case ActionType.TIMER_PAUSED_ACTION:
      case ActionType.TIMER_RESUMED_ACTION:
      case ActionType.TIMER_FINISHED_ACTION:
        return 'grey-background';

      case ActionType.HINT_REQUESTED_ACTION:
      case ActionType.URL_LINK_REQUESTED_ACTION:
      case ActionType.LIVE_TUTORING_REQUESTED_ACTION:
      case ActionType.CAIT_MESSAGE_REQUESTED_ACTION:
      case ActionType.CAIT_MESSAGE_RESPONSE_ACTION:
        return 'orange-background';

      default:
        return 'white-background';
    }
  }

  convertActionTypeToText(
    actionType: string,
    isRedo: boolean,
    correct?: boolean
  ): string {
    switch (actionType) {
      case ActionType.PROBLEM_STARTED_ACTION:
        return !isRedo ? 'Started a Problem' : 'Started a Redo Problem';
      case ActionType.PROBLEM_RESUMED_ACTION:
        return 'Resumed Problem';
      case ActionType.PROBLEM_FINISHED_ACTION:
        return 'Finished Problem';

      case ActionType.STUDENT_RESPONSE_ACTION:
        return correct ? 'Answered Correctly' : 'Answered Incorrectly';
      case ActionType.STUDENT_SUBMISSION_ACTION:
        return 'Answered Open Response';

      case ActionType.HINT_REQUESTED_ACTION:
        return 'Requested Hint';
      case ActionType.SCAFFOLDING_REQUESTED_ACTION:
        return 'Requested Scaffolding';
      case ActionType.ANSWER_REQUESTED_ACTION:
        return 'Requested Answer';
      case ActionType.URL_LINK_REQUESTED_ACTION:
        return 'Requested Instruction';
      case ActionType.EXPLANATION_REQUESTED_ACTION:
        return 'Requested Explanation';

      case ActionType.IM_STUCK_REQUEST_ACTION:
        return 'Student Stuck';
      case ActionType.LIVE_TUTORING_REQUESTED_ACTION:
        return 'Requested Live Tutoring';
      case ActionType.CAIT_MESSAGE_REQUESTED_ACTION:
        return 'Engaged with CAIT';
      case ActionType.CAIT_MESSAGE_RESPONSE_ACTION:
        return 'CAIT Responded';
      default:
        return '';
    }
  }

  get staticHeaders(): DataTableHeader[] {
    return [
      {
        ...this.defaults,
        text: 'Time',
        value: StaticHeaders.TIME,
        cellClass: ['text-no-wrap'],
      },
      {
        ...this.defaults,
        text: 'Action Type',
        value: StaticHeaders.ACTION_TYPE,
      },
      {
        ...this.defaults,
        text: 'Response',
        value: StaticHeaders.RESPONSE,
      },
      {
        ...this.defaults,
        text: 'Teacher Feedback',
        value: StaticHeaders.TEACHER_FEEDBACK,
      },
    ];
  }

  mounted(): void {
    window.com.wiris.js.JsPluginViewer.parseDocument();
  }
}
