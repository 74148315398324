
import { Component, Prop, Vue } from 'vue-property-decorator';

export interface Membership {
  parent: string;
  member: string;
  index: number;
}

@Component({})
export default class RemoveFromProblemSetDialog extends Vue {
  @Prop() value: boolean;
  @Prop({ required: true }) data: Membership;

  loading = false;

  get showDialog(): boolean {
    return this.value;
  }

  set showDialog(value: boolean) {
    this.$emit('input', value);
  }

  remove(): void {
    this.loading = true;
    this.$store
      .dispatch('content/removeProblemSetMembers', {
        xref: this.data.parent,
        members: [this.data.member],
      })
      .then(({ ceri: xref, failMessages: error }) => {
        if (error) {
          this.$notify(
            `Failed to remove member ${this.data.member} in ${this.data.parent}: ${error}`
          );
        } else if (xref) {
          this.$notify(`Saved changes to ${xref}.`);
          this.showDialog = false;
          this.$emit('removed', this.data);
        }
      })
      .catch(() => {
        this.$notify(
          `Failed to remove member ${this.data.member} in ${this.data.parent}.`
        );
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
